import React, { useState } from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";

export default function NextExceptionalhrs({ fields }) {
  const [visible, setVisible] = useState(2);
  let count = 0;

  const loadMore = (e) => {
    e.preventDefault();
    setVisible(visible + count);
  };

  if (fields.nextExceptionopenHrs != null) {
    count = fields.nextExceptionopenHrs.exceptional_DetailsList.length;

    return (
      fields.nextExceptionopenHrs.exceptional_DetailsList.length > 0 && (
        <section className="opening-address-goto" id="exceptional-opening">
          <div className="container">
            <div className="row flex-column flex-lg-row">
              <div className="col-12 col-lg-12 exceptional-opening-block">
                <div className="exceptional-opening">
                  {fields.nextExceptionopenHrs.exceptional_Date_Title !== "" &&
                    fields.nextExceptionopenHrs.exceptional_Date_Title !==
                      null && (
                      <h2 className="content-title text-uppercase text-center">
                        <Text
                          field={
                            fields.nextExceptionopenHrs.exceptional_Date_Title
                          }
                        />
                      </h2>
                    )}
                  <div className="list-dates-wrap d-none d-lg-flex flex-column flex-lg-row align-items-lg-center">
                    <ul className="list-dates">
                      {fields?.nextExceptionopenHrs?.exceptional_DetailsList1?.map(
                        (item, index) => {
                          let summary = false;
                          return (
                            <li key={index} className="item-date pe-lg-4">
                              <div className="w-100">
                                {item.closed_Check.filter((item) =>
                                  item.summary !== ""
                                    ? (summary = true)
                                    : (summary = false)
                                ) && summary ? (
                                  <>
                                    <span className="date">
                                      {item.next_Expectional}
                                    </span>
                                    <div
                                      className={
                                        "activity-list" +
                                        (item.closed_Check.length <= 1
                                          ? " mt-0"
                                          : "")
                                      }
                                    >
                                      {item.closed_Check.map(
                                        (closedCheckItem, index) => {
                                          return (
                                            <div key={index}
                                              className={
                                                "w-100 d-flex activity-wrap flex-column flex-lg-row mb-3 mb-lg-0" +
                                                (item.closed_Check.length > 1
                                                  ? ""
                                                  : " border-0")
                                              }
                                            >
                                              <span
                                                className={
                                                  "date font-weight-normal" +
                                                  (item.closed_Check.length > 1
                                                    ? ""
                                                    : " pl-0")
                                                }
                                              >
                                                {closedCheckItem.summary}
                                              </span>
                                              <span
                                                className={
                                                  "schedule" +
                                                  (item.closed_Check.length > 1
                                                    ? ""
                                                    : " pl-0")
                                                }
                                              >
                                                {closedCheckItem.time}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div className="d-flex flex-column flex-lg-row">
                                    <span className="date">
                                      {item.next_Expectional}
                                    </span>
                                    <span className="schedule">
                                      {item.closed_Check.map(
                                        (timeItem, index) => {
                                          return (
                                            <>
                                              {timeItem.time}
                                              <br />
                                            </>
                                          );
                                        }
                                      )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                    <ul className="list-dates right">
                      {fields?.nextExceptionopenHrs?.exceptional_DetailsList2?.map(
                        (item, index) => {
                          let summary = false;
                          return (
                            <li key={index} className="item-date ps-lg-4">
                              <div className="w-100">
                                {item.closed_Check.filter((item) =>
                                  item.summary !== ""
                                    ? (summary = true)
                                    : (summary = false)
                                ) && summary ? (
                                  <>
                                    <span className="date">
                                      {item.next_Expectional}
                                    </span>
                                    <div
                                      className={
                                        "activity-list" +
                                        (item.closed_Check.length <= 1
                                          ? " mt-0"
                                          : "")
                                      }
                                    >
                                      {item.closed_Check.map(
                                        (closedCheckItem, index) => {
                                          return (
                                            <div key={index}
                                              className={
                                                "w-100 d-flex activity-wrap flex-column flex-lg-row mb-3 mb-lg-0" +
                                                (item.closed_Check.length > 1
                                                  ? ""
                                                  : " border-0")
                                              }
                                            >
                                              <span
                                                className={
                                                  "date font-weight-normal" +
                                                  (item.closed_Check.length > 1
                                                    ? ""
                                                    : " pl-0")
                                                }
                                              >
                                                {closedCheckItem.summary}
                                              </span>
                                              <span
                                                className={
                                                  "schedule" +
                                                  (item.closed_Check.length > 1
                                                    ? ""
                                                    : " pl-0")
                                                }
                                              >
                                                {closedCheckItem.time}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div className="d-flex flex-column flex-lg-row">
                                    <span className="date">
                                      {item.next_Expectional}
                                    </span>
                                    <span className="schedule">
                                      {item.closed_Check.map(
                                        (timeItem, index) => {
                                          return (
                                            <>
                                              {timeItem.time}
                                              <br />
                                            </>
                                          );
                                        }
                                      )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  {fields.nextExceptionopenHrs.exceptional_DetailsList.length >
                    0 && (
                    <>
                      <div className="list-dates-wrap flex-column flex-lg-row align-items-lg-center d-lg-none d-sm-none">
                       {" "}
                        <ul className="list-dates">
                          {fields.nextExceptionopenHrs.exceptional_DetailsList
                            .slice(0, visible)
                            .map((item, index) => {
                              let summary = false;
                              return (
                                <li key={index} className="item-date pe-lg-4">
                                  <div className="w-100">
                                    {item.closed_Check.filter((item) =>
                                      item.summary !== ""
                                        ? (summary = true)
                                        : (summary = false)
                                    ) && summary ? (
                                      <>
                                        <span className="date">
                                          {item.next_Expectional}
                                        </span>
                                        <div
                                          className={
                                            "activity-list" +
                                            (item.closed_Check.length <= 1
                                              ? " mt-0"
                                              : "")
                                          }
                                        >
                                          {item.closed_Check.map(
                                            (closedCheckItem, index) => {
                                              return (
                                                <div key={index}
                                                  className={
                                                    "w-100 d-flex activity-wrap flex-column flex-lg-row mb-3 mb-lg-0" +
                                                    (item.closed_Check.length >
                                                    1
                                                      ? ""
                                                      : " border-0")
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      "date font-weight-normal" +
                                                      (item.closed_Check
                                                        .length > 1
                                                        ? ""
                                                        : " pl-0")
                                                    }
                                                  >
                                                    {closedCheckItem.summary}
                                                  </span>
                                                  <span
                                                    className={
                                                      "schedule" +
                                                      (item.closed_Check
                                                        .length > 1
                                                        ? ""
                                                        : " pl-0")
                                                    }
                                                  >
                                                    {closedCheckItem.time}
                                                  </span>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <div className="d-flex flex-column flex-lg-row">
                                        <span className="date">
                                          {item.next_Expectional}
                                        </span>
                                        <span className="schedule">
                                          {item.closed_Check.map(
                                            (timeItem, index) => {
                                              return (
                                                <>
                                                  {timeItem.time}
                                                  <br />
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                        {visible <
                          fields.nextExceptionopenHrs.exceptional_DetailsList
                            .length && (
                          <>
                            {!fields.nextExceptionopenHrs.isNewBranded ? (
                              <div
                                className="more text-uppercase d-lg-none d-sm-none"
                                id="viewmoreopeninghrs"
                              >
                                <a
                                  href="#viewmoreopeninghrs"
                                  onClick={loadMore}
                                >
                                  <Text
                                    field={
                                      fields.nextExceptionopenHrs.view_More
                                    }
                                  />
                                  <br />
                                  <br />
                                  <i className="picto icon-go-down-arrow"></i>
                                </a>
                              </div>
                            ) : (
                              <div
                                className="mt-3 mb-1 text-black d-lg-none text-center view-more"
                                id="viewmoreopeninghrs"
                              >
                                <div onClick={loadMore}>
                                  <Text
                                    field={
                                      fields.nextExceptionopenHrs.view_More
                                    }
                                  />
                                  <br />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {fields.nextExceptionopenHrs.nextExceptitem !== null &&
                    fields.nextExceptionopenHrs.nextExceptitem
                      .exceptional_Closings !== null &&
                    fields.nextExceptionopenHrs.nextExceptitem
                      .exceptional_Closings !== "" && (
                      <div className="except-dates-wrap">
                        <div className="except-dates">
                          <RichText
                            field={
                              fields.nextExceptionopenHrs.nextExceptitem
                                .exceptional_Closings
                            }
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    );
  } else {
    return <div></div>;
  }
}
