import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { baseModel } from '../../RouteHandler';

export default function CommunitySection({ fields }) {
  const [isCommunity, setIsCommunity] = useState(false);
  let desc = { value: fields?.communityItems?.description };

  useEffect(() => {

    if (baseModel.loginStatus) {
      if (document.getElementById("loyalty-banner")) {
        let communityName = document.getElementById("loyalty-banner").getAttribute("data-communityname");
        let communityDescription = document.getElementById("loyalty-banner").getAttribute("data-communitydescription");
        setIsCommunity(true);

        if (communityName !== null && communityName !== undefined && communityName !== "" &&
          communityDescription !== null && communityDescription !== undefined && communityDescription !== "") {
          fields.communityItems = fields.changeCommunityData;
          fields.communityItems.description = communityDescription;
        }
        else {
          fields.communityItems = fields.selectCommunityData;
        }
      }

      if (fields?.communityItems?.communityList?.length > 0) {
        let BannerLogo = document.getElementById("loyalty-program-header-content");
        if (BannerLogo !== null && BannerLogo !== undefined && BannerLogo !== "") {
          BannerLogo.classList.add('no-community-block');
        }
      }
    }

    // if (baseModel.branded_center) {
    //   let loyaltyCommunity = document.getElementById("loyaltyCommunity");
    //   let bgImage = "background-image: url(" + fields?.communityItems?.bgImage?.value?.src + ")"
    //   if (loyaltyCommunity) {
    //     loyaltyCommunity.setAttribute("style", bgImage);
    //   }
    // }

  }, [])

  useEffect(() => {

  }, [isCommunity])

  if (fields !== null && fields !== undefined && fields !== "" &&
    fields.communityItems !== null && fields.communityItems !== undefined && fields.communityItems !== "") {
    return (
      <>
        {baseModel.loginStatus ?
          <section className="loyalty-block-wc p-0 position-relative">
            <div id="loyaltyCommunity" className="loyalty-content-grid-block position-relative">
              {fields.communityItems.communityList?.length > 0 &&
                <div className="d-flex row w-100 loyalty-block-four-block mx-0">
                  {fields.communityItems.communityList?.map((item, index) => {
                    return (<div key={index} className="col-sm-3 p-2 p-lg-2 d-flex loyalty-block-image-mobile">
                      <div className="card card-inverse w-100   rounded-3 text-decoration-none">
                        <div className="card-block rounded-3">
                          <div className="d-flex justify-content-center rounded-3">
                            <img src={item.communityImage?.value?.src} alt="" loading="lazy" className="w-100 loyalty-program-card-image" />
                          </div>
                          <div className="loyalty-program-card-title text-center ">{item.communityTitle}
                          </div>
                        </div>
                      </div>
                    </div>
                    );
                  })}
                </div>}
              <div className={"container loyalty-add-on-container " + (fields.communityItems.communityList?.length > 0 ? "" : "p-lg-5")}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex flex-column">
                      <div className="loyalty-left-content-container">
                        <div id="community-heading" className="loyalty-title-grid-logged-block d-flex justify-content-center text-center" data-communityname={fields?.communityDetails?.communityName}><RichText field={fields.communityItems.heading} /></div>
                        <div className="loyalty-subtitle-grid-logged-block d-flex justify-content-center text-center"><RichText field={desc} /></div>
                      </div>
                      <div className="loyalty-buttom-content-button d-flex justify-content-center">
                        <a className="btn btn-white loyalty-program-member" href={fields.communityItems.button?.value.url}>{fields.communityItems.button?.value.title}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> :
          <section className="loyalty-block-wc p-0">
            <div id="loyaltyCommunity" className="loyalty-content-grid-block-wc p-lg-5 "  >
              <div className="container p-lg-1 p-0">
                <div className="row">
                  <div className="col-md-5">
                    <div className="d-flex flex-column loyalty-left-content">
                      <div className="loyalty-left-content-container">
                        <div className="loyalty-title-grid-block"><RichText field={fields.communityItems.heading} /></div>
                        <div className="loyalty-subtitle-grid-block mb-lg-5"><RichText field={desc} /></div>
                      </div>
                      <div className="loyalty-buttom-content">
                        <a className="btn btn-white loyalty-program-member" href={fields.communityItems.button?.value.url}>{fields.communityItems.button?.value.title}</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 loyalty-right-content-container px-0">
                    <div className="row mx-0">
                      {fields.communityItems.communityList?.map((item, index) => {
                        return (
                          <div key={index} className="col-sm-6 p-3 p-lg-2 d-flex">
                            <div className="card card-inverse w-100   rounded-3 text-decoration-none">
                              <div className="card-block rounded-3">
                                <div className="d-flex justify-content-center rounded-3">
                                  <img src={item.communityImage?.value?.src} alt=""
                                    loading="lazy" className="w-100 loyalty-program-card-image" />
                                </div>
                                <div className="loyalty-program-card-title text-black text-center ">{item.communityTitle}
                                </div>

                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>}
      </>
    )
  }
  else {
    return (<></>)
  }
}
