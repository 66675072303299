import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import Enumerable from "linq";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import {
  gtmFuncAddCenterToFavorite,
  gtmFuncRemoveCenterToFavorite,
  sortCenterList
} from "../Js-CommonFunctions.js";
import VisitorCenterAddPopin from "../VisitorCenterAddPopin";

export default function Unsubscription({ fields }) {
  var accessToken = useSelector((state) => state.user.accessToken)
  var refreshToken = useSelector((state) => state.user.refreshToken)
  const [preferredCenters, setPreferredCenters] = useState([]);
  const [newPreferredCenters, setNewPreferredCenters] = useState([]);
  const [delPreferredCenters, setDelPreferredCenters] = useState([]);
  const [disable, setDisable] = useState(true);
  const [commercialOptin, setCommercialOptin] = useState(false);
  const [loyaltyOptin, setLoyaltyOptin] = useState(false);
  const [centers, setCenters] = useState([]);
  const [legalTextIdInfo, setLegalTextIdInfo] = useState({});

  useEffect(() => {
    if (fields !== undefined && fields !== null && fields !== "" && fields.unsubscribe !== undefined && fields.unsubscribe !== null && fields.unsubscribe !== "") {
      if (!fields.unsubscribe.displayErrorMessage) {
        if (fields.unsubscribe.preferredCenters != undefined && fields.unsubscribe.preferredCenters != null &&
          fields.unsubscribe.preferredCenters != "") {
          setPreferredCenters(sortCenterList(Enumerable.from(fields.unsubscribe.preferredCenters).orderBy(x => x.centerName).toArray()));
        }
        if (fields.unsubscribe.addPreferredCenter != undefined && fields.unsubscribe.addPreferredCenter != null &&
          fields.unsubscribe.addPreferredCenter != "" && fields.unsubscribe.addPreferredCenter.centersList != undefined &&
          fields.unsubscribe.addPreferredCenter.centersList != null && fields.unsubscribe.addPreferredCenter.centersList != "") {
          setCenters(fields.unsubscribe.addPreferredCenter.centersList);
        }
        setCommercialOptin(fields.unsubscribe.commercialOptinEnabled)
        setLoyaltyOptin(fields.unsubscribe.loyaltyOptinEnabled);
        var legalTextInfo = { latestlegalTextId: fields?.unsubscribe?.commercialEmailLegalTextId, visitorOptedLegalTextId: fields?.unsubscribe?.visitorCommercialOptinLegalTextId, hasCommerctialOptIn: fields.unsubscribe.commercialOptinEnabled }
        setLegalTextIdInfo(legalTextInfo);
        //enable or disable optins
        if (document.getElementById('commercialOptinunsubscribe')) {
          document.getElementById('commercialOptinunsubscribe').checked = fields.unsubscribe.commercialOptinEnabled ? true : false;
        }
        if (document.getElementById('loyaltyOptinunsubscribe')) {
          document.getElementById('loyaltyOptinunsubscribe').checked = fields.unsubscribe.commercialOptinEnabled || fields.unsubscribe.loyaltyOptinEnabled ? true : false;
        }
        if (document.getElementById('unsubscribeOptin')) {
          document.getElementById('unsubscribeOptin').checked = fields.unsubscribe.commercialOptinEnabled || fields.unsubscribe.loyaltyOptinEnabled ? false : true;
          if (document.getElementById('unsubscribeOptin').checked) {
            if (document.getElementsByClassName('optin-info')[0])
              document.getElementsByClassName('optin-info')[0].classList.remove('d-none');
            if (document.getElementsByClassName('unsubscribe-error')[0])
              document.getElementsByClassName('unsubscribe-error')[0].classList.remove('d-none');
          }
          else {
            if (document.getElementsByClassName('optin-info')[0])
              document.getElementsByClassName('optin-info')[0].classList.add('d-none');
            if (document.getElementsByClassName('unsubscribe-error')[0])
              document.getElementsByClassName('unsubscribe-error')[0].classList.add('d-none');
          }
        }

      }

    }
  }, []);
  useEffect(() => {
    if (fields !== undefined && fields !== null && fields !== "" && fields.unsubscribe !== undefined && fields.unsubscribe !== null && fields.unsubscribe !== "") {
      handleChange();
    }
  }, [newPreferredCenters, delPreferredCenters, commercialOptin, loyaltyOptin]);


  function handleChange() {
    if (model !== undefined && model.accountType === model.accountTypePartial) {
      if (newPreferredCenters.length > 0 || delPreferredCenters.length > 0 || commercialOptin !== model.commercialOptinEnabled) {
        setDisable(false);
      }
      else {
        setDisable(true);
      }
    }
    else {
      if (newPreferredCenters.length > 0 || delPreferredCenters.length > 0 || commercialOptin !== model.commercialOptinEnabled || loyaltyOptin !== model.loyaltyOptinEnabled) {
        setDisable(false);
      }
      else {
        setDisable(true);
      }
    }
  }
  function fnToggleOptin() {
    if (document.getElementById('unsubscribeOptin')) {
      if (document.getElementById('unsubscribeOptin').checked) {
        document.getElementById('commercialOptinunsubscribe').checked = false;
        if (document.getElementById('loyaltyOptinunsubscribe'))
          document.getElementById('loyaltyOptinunsubscribe').checked = false;
        if (document.getElementsByClassName('unsubscribe-error')[0])
          document.getElementsByClassName('unsubscribe-error')[0].classList.remove('d-none');
        if (document.getElementsByClassName('optin-info')[0])
          document.getElementsByClassName('optin-info')[0].classList.remove('d-none');
        setCommercialOptin(false);
        setLoyaltyOptin(false);
      }
      else {
        if (model.accountType === model.accountTypePartial) {
          if (!document.getElementById('commercialOptinunsubscribe').checked) {
            document.getElementById('unsubscribeOptin').checked = true;
          } else {
            document.getElementById('commercialOptinunsubscribe').checked = model.commercialOptinEnabled ? true : false;
            if (document.getElementsByClassName('unsubscribe-error')[0])
              document.getElementsByClassName('unsubscribe-error')[0].classList.add('d-none');
            if (document.getElementsByClassName('optin-info')[0])
              document.getElementsByClassName('optin-info')[0].classList.add('d-none');
            setCommercialOptin(model.commercialOptinEnabled);
          }
        } else {
          if (!document.getElementById('commercialOptinunsubscribe').checked && !document.getElementById('loyaltyOptinunsubscribe').checked) {
            document.getElementById('unsubscribeOptin').checked = true;
          } else {
            document.getElementById('commercialOptinunsubscribe').checked = model.commercialOptinEnabled ? true : false;
            if (document.getElementById('loyaltyOptinunsubscribe'))
              document.getElementById('loyaltyOptinunsubscribe').checked = model.loyaltyOptinEnabled ? true : false;
            if (document.getElementsByClassName('unsubscribe-error')[0])
              document.getElementsByClassName('unsubscribe-error')[0].classList.add('d-none');
            if (document.getElementsByClassName('optin-info')[0])
              document.getElementsByClassName('optin-info')[0].classList.add('d-none');
            setCommercialOptin(model.commercialOptinEnabled);
            setLoyaltyOptin(model.loyaltyOptinEnabled);
          }
        }

      }
    }
  }
  function fnUpdateCommercialOptin(e) {
    if (document.getElementById('commercialOptinunsubscribe').checked) {
      if (document.getElementById('loyaltyOptinunsubscribe'))
        document.getElementById('loyaltyOptinunsubscribe').checked = true;
      document.getElementById('unsubscribeOptin').checked = false;
      if (document.getElementsByClassName('optin-info')[0])
        document.getElementsByClassName('optin-info')[0].classList.add('d-none');

      if (document.getElementsByClassName('unsubscribe-error')[0])
        document.getElementsByClassName('unsubscribe-error')[0].classList.add('d-none');
      setLoyaltyOptin(true);
      setCommercialOptin(true);
    }
    else {
      setCommercialOptin(false);
      if (model.accountType === model.accountTypePartial) {
        document.getElementById('unsubscribeOptin').checked = true;
        if (document.getElementsByClassName('optin-info')[0])
          document.getElementsByClassName('optin-info')[0].classList.remove('d-none');
        if (document.getElementsByClassName('unsubscribe-error')[0])
          document.getElementsByClassName('unsubscribe-error')[0].classList.remove('d-none');
      }
      else {
        if (document.getElementById('loyaltyOptinunsubscribe') && !document.getElementById('loyaltyOptinunsubscribe').checked) {
          document.getElementById('unsubscribeOptin').checked = true;
          if (document.getElementsByClassName('optin-info')[0])
            document.getElementsByClassName('optin-info')[0].classList.remove('d-none');
          if (document.getElementsByClassName('unsubscribe-error')[0])
            document.getElementsByClassName('unsubscribe-error')[0].classList.remove('d-none');
        }
      }
    }

  }

  function fnUpdateLoyaltyOptin() {
    if (document.getElementById('loyaltyOptinunsubscribe').checked) {
      setLoyaltyOptin(true);
      document.getElementById('unsubscribeOptin').checked = false;
      if (document.getElementsByClassName('optin-info')[0])
        document.getElementsByClassName('optin-info')[0].classList.add('d-none');
      if (document.getElementsByClassName('unsubscribe-error')[0])
        document.getElementsByClassName('unsubscribe-error')[0].classList.add('d-none');

    }
    else {
      setLoyaltyOptin(false);
      document.getElementById('commercialOptinunsubscribe').checked = false;
      document.getElementById('unsubscribeOptin').checked = true;
      if (document.getElementsByClassName('optin-info')[0])
        document.getElementsByClassName('optin-info')[0].classList.remove('d-none');
      if (document.getElementsByClassName('unsubscribe-error')[0])
        document.getElementsByClassName('unsubscribe-error')[0].classList.remove('d-none');
    }
  }
  function removePreferredCenter(pcCode, centerName) {
    setPreferredCenters(preferredCenters.filter(c => c.pcCode !== pcCode));
    var prev = newPreferredCenters.filter(c => c.pcCode === pcCode);
    if (prev.length > 0) {
      setNewPreferredCenters(newPreferredCenters.filter(c => c.pcCode !== pcCode));

    } else {
      setDelPreferredCenters([
        ...delPreferredCenters,
        { pcCode, centerName }
      ]);
    }

  }
  function updateLegalTextId(legalTextId) {
    setLegalTextIdInfo(({ }) => ({
      ...legalTextIdInfo,
      visitorOptedLegalTextId: legalTextId,
    }));
  }

  function addPreferredCenter(centerName, pcCode, countryCode, isWestfield) {
    var newArr = ([...preferredCenters, { centerName: centerName, pcCode: pcCode, countryCode: countryCode, isWestfield: isWestfield }]);
    var sortedArr = sortCenterList(Enumerable.from(newArr).orderBy(x => x.centerName).toArray());
    setPreferredCenters(sortedArr);

    setNewPreferredCenters([
      ...newPreferredCenters,
      { pcCode, centerName }
    ]);

  }
  function addRemovePreferredCenter(centerNameToAdd, pcCodeToAdd, pcCodeToRemove) {
    const newList = ([...preferredCenters, { centerName: centerNameToAdd, pcCode: pcCodeToAdd }]);
    var removedCenter = newList.filter((c) => c.pcCode === pcCodeToRemove);
    setPreferredCenters(newList.filter(c => c.pcCode !== pcCodeToRemove));
    let filteredNewPreferredCenters = newPreferredCenters.filter(c => c.pcCode !== pcCodeToRemove);
    if (newPreferredCenters.length !== filteredNewPreferredCenters.length) {
      var updatedNewPreferedItem = ([...filteredNewPreferredCenters, { pcCode: pcCodeToAdd, centerName: centerNameToAdd }]);
      setNewPreferredCenters(updatedNewPreferedItem);
    }
    else {
      setDelPreferredCenters([
        ...delPreferredCenters,
        { pcCode: pcCodeToRemove, centerName: removedCenter[0].centerName }
      ]);
      setNewPreferredCenters([
        ...newPreferredCenters,
        { pcCode: pcCodeToAdd, centerName: centerNameToAdd }
      ]);
    }

  }

  function addPreferredCenterClick(data) {
    if (data.isMobileView) {
      document.getElementById("unsubscribe").classList.add('d-none');
      ReactDOM.render(<VisitorCenterAddPopin fields={data} consent={model.data} allCenters={centers} preferredCenters={preferredCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={true} />,
        document.getElementById("addPreferredCenterMobile"))
      document.getElementById("addPreferredCenterMobile").classList.remove('d-none');
    }
    else {
      ReactDOM.render(<VisitorCenterAddPopin fields={data} consent={model.data} allCenters={centers} preferredCenters={preferredCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={true} />,
        document.getElementById("popinVisitorCenterAdd"))
      // Triggers the Popin
      // var popinClick = document.getElementById("popinVisitorCenterAddClick");
      // popinClick.click();
    }
  }
  function editPreferredCenterClick(data, pcCode) {
    if (data.isMobileView) {
      document.getElementById("unsubscribe").classList.add('d-none');
      ReactDOM.render(<VisitorCenterAddPopin fields={data} consent={model.data} allCenters={centers} preferredCenters={preferredCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} pcCodeToRemove={pcCode} addRemovePreferredCenter={addRemovePreferredCenter} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={true} />,
        document.getElementById("addPreferredCenterMobile"))
      document.getElementById("addPreferredCenterMobile").classList.remove('d-none');
    }
    else {
      ReactDOM.render(<VisitorCenterAddPopin fields={data} consent={model.data} allCenters={centers} preferredCenters={preferredCenters} addPreferredCenter={addPreferredCenter} updateLegalTextId={updateLegalTextId} pcCodeToRemove={pcCode} addRemovePreferredCenter={addRemovePreferredCenter} legalTextIdInfo={legalTextIdInfo} isFromUnsubscribe={true} />,
        document.getElementById("popinVisitorCenterAdd"))
      // Triggers the Popin
      // var popinClick = document.getElementById("popinVisitorCenterAddClick");
      // popinClick.click();
    }
  }


  function btnConfirmClick(visitorId) {
    if (newPreferredCenters.length > 0 || delPreferredCenters.length > 0) {
      if (document.getElementById('loader'))
        document.getElementById('loader').classList.remove('d-none')
      var createPcCodeList = "";
      var delPcCodeList = "";
      //var finalDeletedCenter = delPreferredCenters.filter((el) => !newPreferredCenters.find((el2) => el.pcCode === el2.pcCode));
      //var finalAddedCenter = newPreferredCenters.filter((el) => !delPreferredCenters.find((el2) => el.pcCode === el2.pcCode));
      if (newPreferredCenters.length > 0) {
        createPcCodeList = newPreferredCenters.map((obj) => {
          return obj.pcCode
        });
      }
      if (delPreferredCenters.length > 0) {
        delPcCodeList = delPreferredCenters.map((obj) => {
          return obj.pcCode
        });
      }
      axios({
        method: "post",
        url: "/api/sitecore/VisitorApi/UnsubscribePreferredSC",
        data: {
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken,
          strCreatePcCodes: createPcCodeList.toString(),
          strDeletePcCodes: delPcCodeList.toString()
        },
        async: false,
      })
        .then(function (response) {
          var preferedcenternames = preferredCenters.map((obj) => obj.centerName);


          if (delPreferredCenters.length > 0) {
            delPcCodeList = delPreferredCenters.map((obj) => {
              if (obj.centerName) {
                gtmFuncRemoveCenterToFavorite(obj.centerName, preferedcenternames);
              }
            });
          }
          if (newPreferredCenters.length > 0) {
            createPcCodeList = newPreferredCenters.map((obj) => {
              if (obj.centerName) {
                gtmFuncAddCenterToFavorite(obj.centerName, preferedcenternames);
              }
            });
          }
          document.getElementById('unsubscribe').classList.add('d-none');
          if (document.getElementById('loader'))
            document.getElementById('loader').classList.add('d-none')
          if (document.getElementById('unsubscribethankyou'))
            document.getElementById('unsubscribethankyou').classList.remove('d-none');
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    //optins call
    var commercialOptinValue = document.getElementById('commercialOptinunsubscribe').checked;
    var loyaltyOptinValue = false;
    var loyaltyLegalTextId = "";
    if (document.getElementById('loyaltyOptinunsubscribe')) {
      loyaltyOptinValue = document.getElementById('loyaltyOptinunsubscribe').checked;
      loyaltyLegalTextId = document.getElementById('loyaltyOptinunsubscribe').getAttribute("data-legaltextid").valueOf();
    }
    var commercialLegalTextId = document.getElementById('commercialOptinunsubscribe').getAttribute("data-legaltextid").valueOf();

    var toggleCommercialOptin = model.commercialOptinEnabled !== commercialOptinValue ? true : false;
    var toggleLoyaltyOptin = model.loyaltyOptinEnabled !== loyaltyOptinValue ? true : false;
    if (toggleCommercialOptin || toggleLoyaltyOptin) {
      if (document.getElementById('loader'))
        document.getElementById('loader').classList.remove('d-none')
      axios({
        method: "post",
        url: "/api/sitecore/VisitorApi/UnsubscribeOptins",
        data: {
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken,
          toggleCommercialOptin: toggleCommercialOptin,
          commercialOptin: commercialOptinValue,
          toggleLoyaltyOptin: toggleLoyaltyOptin,
          loyaltyOptin: loyaltyOptinValue,
          commercialLegalTextId: commercialLegalTextId,
          loyaltyLegalTextId: loyaltyLegalTextId
        },
        async: false,
      })
        .then(function (response) {
          document.getElementById('unsubscribe').classList.add('d-none');
          if (document.getElementById('loader'))
            document.getElementById('loader').classList.add('d-none')
          if (document.getElementById('unsubscribethankyou'))
            document.getElementById('unsubscribethankyou').classList.remove('d-none');
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  if (fields !== undefined && fields !== null && fields !== "" && fields.unsubscribe !== undefined && fields.unsubscribe !== null && fields.unsubscribe !== "") {
    var model = fields.unsubscribe;
    if (!model.displayErrorMessage) {
      return (
        <>
          <div className="container account-unsubscribe" id="unsubscribe">
            <div className="unsubscribe-logo">
              <img src={model.logo?.value?.src} alt="" title="" className="unsubscribe-image-logo" />
            </div>
            <div className="unsubscribe-manage-preferance">
              <div className="manage-preferance-title manage-preferance-title-branded">{model.data.preferencesTitle}</div>
              <div className="manage-preferance-subheading"><Text field={model.data.preferencesDesc} /></div>

            </div>
            <div className="unsubscribe-manage-communication">
              <div className="manage-sc-communication-title text-uppercase">{model.data.communicationTitle}</div>
              <div className="mx-lg-0 w-100  mb-4 unsubscribe-container px-0">
                <div className="unsubscribe-border-bottom">
                  <div className="unsubscribe-items  d-flex align-items-center justify-content-between">
                    <div>
                      <div className="unsubscribe-title-head ">{model.data.commercialOptinHeading}
                      </div>
                      <div className="unsubscribe-profile-title-content">
                        <RichText field={model.commercialEmailLegalText} /> </div>
                      {model.accountType === model.accountTypeFull &&
                        <div className="d-flex mb-2 justify-content-center align-items-center optin-info">
                          <a aria-label={model?.data?.commercialOptinInfoText} className="picto icon-srv-information text-black text-decoration-none fs-5 me-3"
                            href="#"></a>
                          <div className="flex-grow-1 communications-profile-title-content pe-3">
                            {model.data.commercialOptinInfoText}
                          </div>
                        </div>
                      }
                    </div>

                    <div className="switch-control d-flex align-items-end">
                      <input type="checkbox" id="commercialOptinunsubscribe" data-legaltextid={model.commercialEmailLegalTextId} data-testid='testOnChange1' onChange={(e) => { fnUpdateCommercialOptin(e) }} />
                      <label htmlFor="commercialOptinunsubscribe" ></label>
                    </div>
                  </div>
                </div>
                {model.accountType === model.accountTypeFull &&
                  <div className="unsubscribe-border-bottom">
                    <div className=" unsubscribe-items d-flex align-items-center justify-content-between">
                      <div >
                        <div className="unsubscribe-title-head ">
                          <RichText field={model.data.loyaltyOptinHeading} />
                        </div>
                        <div className="unsubscribe-profile-title-content">
                          <RichText field={model.loyaltyLegalText} />
                        </div>
                      </div>

                      <div className="switch-control d-flex align-items-end">
                        <input type="checkbox" id="loyaltyOptinunsubscribe" onChange={() => { fnUpdateLoyaltyOptin() }} data-legaltextid={model.loyaltyLegalTextId} />
                        <label htmlFor="loyaltyOptinunsubscribe"  ></label>

                      </div>

                    </div>

                  </div>
                }
                <div className="unsubscribe-border-bottom">
                  <div className=" unsubscribe-items d-flex align-items-center justify-content-between">
                    <div >
                      <div className="unsubscribe-title-head ">
                        {model.data.unsubscribeOptinHeading}
                      </div>
                      <div className="unsubscribe-profile-title-content">{model.data.unsubscribeOptinDisplayText}</div>
                    </div>
                    <div className="switch-control d-flex align-items-end">
                      <input type="checkbox" id="unsubscribeOptin" onChange={() => { fnToggleOptin() }} />
                      <label htmlFor="unsubscribeOptin" ></label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="unsubscribe-manage-shopping-center">
              <div className="manage-sc-communication-title text-uppercase ">{model.data.preferredSCTitle}</div>
              <div className="w-100  px-0">

                {preferredCenters.map((item, index) => (
                  <div key={index} className="d-flex mx-0 communication-item  px-0">
                    <div className="communications-item-center col-md-8 col-12">
                      <div className=" communications-center">{item.centerName}</div>
                      {preferredCenters.length > 1 ?
                        (
                          <div className="d-lg-none d-flex align-items-center justify-content-center">
                            <a aria-label={model?.data?.deleteCenter}
                              className="picto icon-close-circle-rw fs-5 me-1  close-icon-color text-decoration-none" href="javascript:void(0);"></a>
                            <a href="javascript:void(0);" aria-label={model?.data?.deleteCenter} className="account-delete text-decoration-underline " onClick={(e) => { removePreferredCenter(item.pcCode, item.centerName) }}>{model.data.deleteCenter}
                            </a>
                          </div>
                        ) :
                        (
                          <div className="d-lg-none d-flex align-items-center justify-content-center">
                            <a aria-label={model?.data?.editCenter}
                              className="picto icon-chevron-pen fs-5 me-1  close-icon-color text-decoration-none" href="javascript:void(0);"></a>
                            <a href="javascript:void(0);" aria-label={model?.data?.editCenter} className="account-delete text-decoration-underline " onClick={(e) => { editPreferredCenterClick(model.addPreferredCenter, item.pcCode) }}>{model.data.editCenter}
                            </a>
                          </div>
                        )
                      }
                    </div>

                    {preferredCenters.length > 1 ?
                      (<div className="d-none d-md-block d-lg-flex  align-items-center justify-content-end col-md-4">
                        <div className="d-flex communications-profile-align-items" onClick={(e) => { removePreferredCenter(item.pcCode, item.centerName) }}><a
                          className="picto icon-close-circle-rw fs-5 me-1  close-icon-color text-decoration-none" href="javascript:void(0);"></a>
                          <a href="javascript:void(0);" aria-label={model?.data?.deleteCenter} className="account-delete text-decoration-underline ">{model.data.deleteCenter}
                          </a>
                        </div>
                      </div>
                      )
                      : (
                        <div className="d-none d-md-block d-lg-flex  align-items-center justify-content-end col-md-4" data-testid="testclick1" onClick={(e) => { editPreferredCenterClick(model.addPreferredCenter, item.pcCode) }}>
                          <div className="d-flex communications-profile-align-items"><a aria-label={model?.data?.editCenter}
                            className="picto icon-chevron-pen fs-5 me-1  close-icon-color text-decoration-none" href="javascript:void(0);"></a>
                            <a href="javascript:void(0);" aria-label={model?.data?.editCenter} className="account-delete text-decoration-underline " >{model.data.editCenter}
                            </a>
                          </div>
                        </div>
                      )
                    }
                  </div>
                ))}
              </div>

              <a href="javascript:void(0);" aria-label={model?.data?.addShoppingCenter} className="add-another-sc" onClick={(e) => { addPreferredCenterClick(model.addPreferredCenter) }}>{model.data.addShoppingCenter}</a>
            </div>

            <div className="unsubscribe-confirm-cta">
              <div className="align-items-center d-flex justify-content-center ">
                <button className="btn btn-dark  unsubscribe-btn-confirm align-items-center" id="load-button" disabled={disable} onClick={(e) => { btnConfirmClick(model.visitorId) }}>{model.data.confirmCTA}</button>
              </div>
              <div data-bs-title="dot-spin" className="d-flex align-items-center justify-content-center account-unsubscribe-loader d-none bg-black opacity-50" id="loader">
                <div className="stage">
                  <div className="dot-spin"></div>
                </div>
              </div>

            </div>
            <div className="unsubscribe-footer-content pb-5">
              {model.accountType === model.accountTypeFull ?
                (<div className="unsubscribe-footer-content-text pb-2"><RichText field={model.data.fullAccountMentionText} /></div>
                ) :
                (<div className="unsubscribe-footer-content-text pb-2"><RichText field={model.data.partialAccountMentionText} /></div>
                )
              }
            </div>
          </div>
          {
            model.addPreferredCenter.isMobileView &&
            < div className="d-none" id="addPreferredCenterMobile">
            </div>
          }

          <div className="container account-unsubscribe d-none" id="unsubscribethankyou">
            <div className="unsubscribe-logo">
              <img src={model.logo?.value?.src} alt="" title="" className="unsubscribe-image-logo" />
            </div>
            <div className="unsubscribe-manage-preferance">
              <div className="manage-preferance-title manage-preferance-title-branded">{model.data.preferencesTitle}</div>
              <div className="manage-preferance-subheading-thanku"><Text field={model.data.successText} /></div>

            </div>
          </div>
        </>
      )
    }
    else {
      return (
        <div className="container account-unsubscribe" id="unsubscribeErrorView">
          <div className="unsubscribe-logo">
            <img src={model.logo?.value?.src} alt="" title="" className="unsubscribe-image-logo" />
          </div>
          <div className="unsubscribe-manage-preferance">
            <div className="manage-preferance-subheading-thanku text-center"><Text field={model.data.errorMessageText} /></div>

          </div>
        </div>
      )

    }
  }
  else {
    return <></>;
  }
}



