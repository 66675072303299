import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React from "react";
import { contextualizeLoginPopin } from '../Js-CommonFunctions.js';
export default function Service({ fields }) {

    let serviceIndex = 0;
    let highlightcount = 1;
    return (
        (fields.servicesInfo != null && fields.servicesInfo.servicePagePro != null && fields.servicesInfo.servicePagePro.length > 0) &&
        (
            fields.isNewBrandedSite ?
                (
                    <div className="vip-services container px-0 w-100" id="vip-services">
                        {fields.servicesInfo.servicePagePro.map((child, index) => {
                            let itemtemplateid = child.sITemplateID;

                            return (
                                <>
                                    {fields.servicesInfo.targetComponent != "1" &&
                                        (child != null && child.introduction != null && child.introduction != "" &&
                                            <h2 className="content-title text-uppercase text-center underline">
                                                <RichText field={child.introduction} />
                                            </h2>
                                        )
                                    }
                                    <div key={index} className="wrapper">
                                        <div className="whats-hot">
                                            <div className="row pb-5">
                                                {fields.servicesInfo.isPicImage ?
                                                    (
                                                        fields.servicesInfo.servicehighlightmodel?.serviceHighlightList != null &&
                                                        (
                                                            fields.servicesInfo.servicehighlightmodel?.serviceHighlightList.map((highlight, index) => {
                                                                serviceIndex++;
                                                                let divStyle = highlightcount % 2 == 1 ? "no-margin-left" : "no-margin-right";
                                                                let textstyle = highlightcount % 2 == 1 ? "text-dark bg-light link-dark" : "text-light bg-main";
                                                                var MallPictoText = { value: fields.servicesInfo.mall_Picto_Style_Start + highlight.highlight_Pictogram.value + fields.servicesInfo.mall_Picto_Style_End }
                                                                highlightcount = highlightcount + 1;
                                                                return (
                                                                    <div key={index} className={"col-sm-6  " + divStyle + "  item analytics_vipservicespushdisplay"}>
                                                                        <div className={"whats-hot-item whats-hot-text  " + textstyle}>
                                                                            <div className="whats-hot-content text-center">
                                                                                <div className="vertical-align">
                                                                                    <div className="picto-wrap">
                                                                                        {fields.isExperienceEditor ?
                                                                                            (<Text field={highlight.highlight_Pictogram} />) :
                                                                                            (
                                                                                                <RichText field={MallPictoText} />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    {(highlight != null && highlight.highlight_Title != null && highlight.highlight_Title != "") &&
                                                                                        <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={highlight.highlight_Title} /></h3>
                                                                                    }
                                                                                    <div className="description"> <RichText field={highlight.highlight_Description} /> </div>
                                                                                    {!fields.servicesInfo.isLogin && !fields.isAppWebView &&
                                                                                        <a href="javascript:void(0);" aria-label={fields?.servicesInfo?.getMyService} data-testid="contextualizeLoginPopinClick1" onClick={(e) => contextualizeLoginPopin(e.currentTarget, 'service')} className="link-button btn-transparent analytics_vipservicespushclk gtmCommonVariablesEvent gtmServiceNavigation">{fields.servicesInfo.getMyService}</a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )

                                                            })
                                                        )
                                                    ) :
                                                    (
                                                        fields.servicesInfo.servicehighlightmodel?.serviceHighlightList != null &&
                                                        (
                                                            fields.servicesInfo.servicehighlightmodel?.serviceHighlightList.map((highlight, index) => {
                                                                var MallPictoText = { value: fields.servicesInfo.mall_Picto_Style_Start + highlight.highlight_Pictogram.value + fields.servicesInfo.mall_Picto_Style_End }
                                                                if (highlight.highlight_Title != "" || highlight.highlight_Title != " ") {
                                                                    if (highlightcount == 1) {
                                                                        index++;
                                                                        highlightcount = highlightcount + 1;
                                                                        return (
                                                                            <div className="col-sm-12  item  picture-and-text analytics_vipservicespushdisplay" key={index}>
                                                                                <div className="clearfix">
                                                                                    {!fields.servicesInfo.isPicImage &&
                                                                                        <div className="whats-hot-item whats-hot-picture  left  bg-light ">
                                                                                            <Image field={highlight.highlight_Picture} imageParams={{ mh: 224 }} />
                                                                                        </div>
                                                                                    }
                                                                                    <div className="whats-hot-item whats-hot-text right  bg-main  text-light  arrow-left">
                                                                                        <div className="whats-hot-content">
                                                                                            <div className="vertical-align">
                                                                                                <div className="picto-wrap">
                                                                                                    {fields.isExperienceEditor ?
                                                                                                        (<Text field={highlight.highlight_Pictogram} />) :
                                                                                                        (
                                                                                                            <RichText field={MallPictoText} />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                {(highlight != null && highlight.highlight_Title != null && highlight.highlight_Title != "") &&
                                                                                                    <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={highlight.highlight_Title} /></h3>
                                                                                                }
                                                                                                <div className="description"> <RichText field={highlight.highlight_Description} /> </div>
                                                                                                {!fields.servicesInfo.isLogin &&
                                                                                                    <a href="javascript:void(0);" aria-label={fields?.servicesInfo?.getMyService} data-testid="contextualizeLoginPopinClick2" onClick={(e) => contextualizeLoginPopin(e.currentTarget, 'service')} className="link-button btn-transparent analytics_vipservicespushclk gtmCommonVariablesEvent gtmServiceNavigation">{fields.servicesInfo.getMyService}</a>
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    if (highlightcount == 2) {
                                                                        index++;
                                                                        highlightcount = highlightcount + 1;
                                                                        return (
                                                                            <div className="col-sm-12  item  picture-and-text analytics_vipservicespushdisplay" key={index}>
                                                                                <div className="clearfix">
                                                                                    {!fields.servicesInfo.isPicImage &&
                                                                                        <div className="whats-hot-item whats-hot-picture  right  bg-light  small-height ">
                                                                                            <Image field={highlight.highlight_Picture} imageParams={{ mh: 224 }} />
                                                                                        </div>
                                                                                    }
                                                                                    <div className={"whats-hot-item whats-hot-text   left  bg-dark  text-light " + (!fields.servicesInfo.isPicImage ? "arrow-right" : "") + " small-height "}>
                                                                                        <div className="whats-hot-content">
                                                                                            <div className="vertical-align">
                                                                                                <div className="picto-wrap">
                                                                                                    {fields.isExperienceEditor ?
                                                                                                        (<Text field={highlight.highlight_Pictogram} />) :
                                                                                                        (
                                                                                                            <RichText field={MallPictoText} />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                {(highlight != null && highlight.highlight_Title != null && highlight.highlight_Title != "") &&
                                                                                                    <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={highlight.highlight_Title} /></h3>
                                                                                                }
                                                                                                <div className="description"> <RichText field={highlight.highlight_Description} /> </div>
                                                                                                {!fields.servicesInfo.isLogin &&
                                                                                                    <a href="javascript:void(0);" aria-label={fields?.servicesInfo?.getMyService} data-testid="contextualizeLoginPopinClick3" onClick={(e) => contextualizeLoginPopin(e.currentTarget, 'service')} className="link-button btn-transparent analytics_vipservicespushclk gtmCommonVariablesEvent gtmServiceNavigation">{fields.servicesInfo.getMyService}</a>
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }


                                                                }
                                                            })
                                                        )
                                                    )
                                                }
                                                {fields.servicesInfo.serviceOtherModel?.serviceOtherList != null &&
                                                    fields.servicesInfo.serviceOtherModel?.serviceOtherList.map((other, index) => {
                                                        var MallPictoText = { value: fields.servicesInfo.mall_Picto_Style_Start + other.other_Pictogram?.value + fields.servicesInfo.mall_Picto_Style_End }
                                                        if (other.other_Title != "" || other.other_Title != " ") {
                                                            serviceIndex++;
                                                            return (
                                                                <div key={index} className="col-md-4 col-12 no-margin-bottom analytics_vipservicespushdisplay">
                                                                    <div className="vip-more-infos  text-lighter bg-white link-dark">
                                                                        <div className="vertical-align">
                                                                            <div className="picto-wrap">
                                                                                {fields.isExperienceEditor ?
                                                                                    (<Text field={other.other_Pictogram} />) :
                                                                                    (
                                                                                        <RichText field={MallPictoText} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            {(other != null && other.other_Title != null && other.other_Title != "") &&
                                                                                <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={other.other_Title} /></h3>
                                                                            }
                                                                            <div className="description"> <RichText field={other.other_Description} /> </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )

                        })}
                    </div>
                ) :
                (
                    <section className="vip-services px-0 w-100" id="vip-services">

                        {fields.servicesInfo.servicePagePro.map((child, index) => {
                            let itemtemplateid = child.sITemplateID;
                            return (
                                <>
                                    {fields.servicesInfo.targetComponent != "1" &&
                                        (child != null && child.introduction != null && child.introduction != "" &&
                                            <h2 className="content-title text-uppercase text-center">
                                                <RichText field={child.introduction} />
                                            </h2>
                                        )
                                    }
                                    <div key={index} className="wrapper">
                                        <div className="whats-hot">
                                            <div className="row pb-4 pb-lg-0">
                                                {fields.servicesInfo.isPicImage ?
                                                    (
                                                        fields.servicesInfo.servicehighlightmodel?.serviceHighlightList != null &&
                                                        (
                                                            fields.servicesInfo.servicehighlightmodel?.serviceHighlightList.map((highlight, index) => {
                                                                serviceIndex++;
                                                                let divStyle = highlightcount % 2 == 1 ? "no-margin-left" : "no-margin-right";
                                                                let textstyle = highlightcount % 2 == 1 ? "text-dark bg-light link-dark" : "text-light bg-main";
                                                                var MallPictoText = { value: fields.servicesInfo.mall_Picto_Style_Start + highlight.highlight_Pictogram.value + fields.servicesInfo.mall_Picto_Style_End }
                                                                highlightcount = highlightcount + 1;
                                                                return (
                                                                    <div className={"col-sm-6  " + divStyle + "  item analytics_vipservicespushdisplay"} key={index}>
                                                                        <div className={"whats-hot-item whats-hot-text  " + textstyle}>
                                                                            <div className="whats-hot-content">
                                                                                <div className="vertical-align">
                                                                                    <div className="picto-wrap">
                                                                                        {fields.isExperienceEditor ?
                                                                                            (<Text field={highlight.highlight_Pictogram} />) :
                                                                                            (
                                                                                                <RichText field={MallPictoText} />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    {(highlight != null && highlight.highlight_Title != null && highlight.highlight_Title != "") &&
                                                                                        <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={highlight.highlight_Title} /></h3>
                                                                                    }
                                                                                    <div className="description"> <RichText field={highlight.highlight_Description} /> </div>
                                                                                    {!fields.servicesInfo.isLogin &&
                                                                                        <a href="javascript:void(0);" aria-label={fields?.servicesInfo?.getMyService} data-testid="contextualizeLoginPopinClick4" onClick={(e) => contextualizeLoginPopin(e.currentTarget, 'service')} className="link-button btn-transparent analytics_vipservicespushclk gtmCommonVariablesEvent gtmServiceNavigation">{fields.servicesInfo.getMyService}</a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    ) :
                                                    (
                                                        fields.servicesInfo.servicehighlightmodel?.serviceHighlightList != null &&
                                                        (
                                                            fields.servicesInfo.servicehighlightmodel?.serviceHighlightList.map((highlight, index) => {
                                                                var MallPictoText = { value: fields.servicesInfo.mall_Picto_Style_Start + highlight.highlight_Pictogram.value + fields.servicesInfo.mall_Picto_Style_End }
                                                                if (highlight.highlight_Title != "" || highlight.highlight_Title != " ") {
                                                                    if (highlightcount == 1) {
                                                                        index++;
                                                                        highlightcount = highlightcount + 1;
                                                                        return (
                                                                            <div className="col-sm-12  item  picture-and-text analytics_vipservicespushdisplay testclick" key={index}>
                                                                                <div className="clearfix">
                                                                                    {!fields.servicesInfo.isPicImage &&
                                                                                        <div className="whats-hot-item whats-hot-picture  left  bg-light ">
                                                                                            <Image field={highlight.highlight_Picture} imageParams={{ mh: 224 }} />
                                                                                        </div>
                                                                                    }
                                                                                    <div className="whats-hot-item whats-hot-text right  bg-main  text-light  arrow-left">
                                                                                        <div className="whats-hot-content">
                                                                                            <div className="vertical-align">
                                                                                                <div className="picto-wrap">
                                                                                                    {fields.isExperienceEditor ?
                                                                                                        (<Text field={highlight.highlight_Pictogram} />) :
                                                                                                        (
                                                                                                            <RichText field={MallPictoText} />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                {(highlight != null && highlight.highlight_Title != null && highlight.highlight_Title != "") &&
                                                                                                    <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={highlight.highlight_Title} /></h3>
                                                                                                }
                                                                                                <div className="description"> <RichText field={highlight.highlight_Description} /> </div>
                                                                                                {!fields.servicesInfo.isLogin &&
                                                                                                    <a href="javascript:void(0);" aria-label={fields?.servicesInfo?.getMyService} data-testid="contextualizeLoginPopinClick5" onClick={(e) => contextualizeLoginPopin(e.currentTarget, 'service')} className="link-button btn-transparent analytics_vipservicespushclk gtmCommonVariablesEvent gtmServiceNavigation">{fields.servicesInfo.getMyService}</a>
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    if (highlightcount == 2) {
                                                                        index++;
                                                                        highlightcount = highlightcount + 1;
                                                                        return (
                                                                            <div className="col-sm-12  item  picture-and-text analytics_vipservicespushdisplay" key={index}>
                                                                                <div className="clearfix">
                                                                                    {!fields.servicesInfo.isPicImage &&
                                                                                        <div className="whats-hot-item whats-hot-picture  right  bg-light  small-height ">
                                                                                            <Image field={highlight.highlight_Picture} imageParams={{ mh: 224 }} />
                                                                                        </div>
                                                                                    }
                                                                                    <div className={"whats-hot-item whats-hot-text   left  bg-dark  text-light " + (!fields.servicesInfo.isPicImage ? "arrow-right" : "") + " small-height "}>
                                                                                        <div className="whats-hot-content">
                                                                                            <div className="vertical-align">
                                                                                                <div className="picto-wrap">
                                                                                                    {fields.isExperienceEditor ?
                                                                                                        (<Text field={highlight.highlight_Pictogram} />) :
                                                                                                        (
                                                                                                            <RichText field={MallPictoText} />
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                                {(highlight != null && highlight.highlight_Title != null && highlight.highlight_Title != "") &&
                                                                                                    <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={highlight.highlight_Title} /></h3>
                                                                                                }
                                                                                                <div className="description"> <RichText field={highlight.highlight_Description} /> </div>
                                                                                                {!fields.servicesInfo.isLogin &&
                                                                                                    <a href="javascript:void(0);" aria-label={fields?.servicesInfo?.getMyService} data-testid="contextualizeLoginPopinClick6" onClick={(e) => contextualizeLoginPopin(e.currentTarget, 'service')} className="link-button btn-transparent analytics_vipservicespushclk gtmCommonVariablesEvent gtmServiceNavigation">{fields.servicesInfo.getMyService}</a>
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            })
                                                        )
                                                    )
                                                }
                                                {fields.servicesInfo.serviceOtherModel?.serviceOtherList != null &&
                                                    fields.servicesInfo.serviceOtherModel?.serviceOtherList.map((other, index) => {
                                                        var MallPictoText = { value: fields.servicesInfo.mall_Picto_Style_Start + other.other_Pictogram?.value + fields.servicesInfo.mall_Picto_Style_End }
                                                        if (other.other_Title != "" || other.other_Title != " ") {
                                                            serviceIndex++;
                                                            return (
                                                                <div key={index} className="col-md-4 col-12 no-margin-bottom analytics_vipservicespushdisplay">
                                                                    <div className="vip-more-infos  text-lighter bg-white link-dark">
                                                                        <div className="vertical-align">
                                                                            <div className="picto-wrap">
                                                                                {fields.isExperienceEditor ?
                                                                                    (<Text field={other.other_Pictogram} />) :
                                                                                    (
                                                                                        <RichText field={MallPictoText} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            {(other != null && other.other_Title != null && other.other_Title != "") &&
                                                                                <h3 className="whats-hot-title text-uppercase analytics_vipservicespushclk"><RichText field={other.other_Title} /></h3>
                                                                            }
                                                                            <div className="description"> <RichText field={other.other_Description} /> </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )

                        })}
                    </section>
                )
        )
    )
}
