import React from 'react';

export default function QuickLinks({ fields }) {
  if (fields.quickLinks != null && fields.quickLinks !== undefined && fields.quickLinks !== "") {
    return (
      <section className="quickLinks">
        <nav className="nav">
          <ul className="nav__list">
            {fields.quickLinks.map((item, index) => {
              return (
                <li key={index} className="nav__list-item ">
                  <a href={item.redirection.value.url} aria-label={item?.redirection?.value} target={item.redirection.value?.target}>
                    <div className="picto-wrap"> <span className={"picto " + (item.picto)}></span></div>
                    <div className="nav__label">{item.redirection.value.text}</div>
                  </a>
                </li>
              )
            })}
          </ul>
        </nav>
      </section>
    );
  } else {
    return <></>;
  }
}
