import loadable from "@loadable/component";
import React, { useEffect, useRef, useState } from 'react';
import { fnGtmVideo, getVideoTypeFromvideoUrl } from '../Js-CommonFunctions';

const ReactPlayer = loadable(() => import('react-player'), { ssr: false });

export default function HeroNewsAndOffer({ fields }) {
  const ref = useRef(null);
  const [windowinnerWidth, setwindowinnerWidth] = useState(1000); //by default set to be greater value to target desktop device
  const [counter, setCounter] = useState(0);
  const [showvideo, setshowvideo] = useState(false);

  let durationSec = "";
  var pauseSlick = () => {
    setCounter(counter + 1)

  };

  var VideoDuration = (x) => {
    durationSec = x;
  }

  var playSlick = () => {
  };
  useEffect(() => {

    if (typeof window !== "undefined") {
      setwindowinnerWidth(window.innerWidth);
    }

  }, []);

  useEffect(() => {
    if (counter === 1 || showvideo) {
      setshowvideo(false)
    }
  }, [counter]);

  if (fields.heroNewsModel !== null && fields.heroNewsModel !== undefined && fields.heroNewsModel !== "" && fields.heroNewsModel.imgurl !== null && fields.heroNewsModel.imgurl !== "") {
    return (
      <section className="today event-head">
        <div className="container">
          <div className="carousel carousel-cinema news-video-click" data-loop="true" data-auto-play="" data-auto-play-speed="" data-slide-to-show="1" data-slide-to-scroll="1" data-dots="false" data-arrows="true" aria-label={fields.heroNewsModel.videoUrl}>
            <>
              {fields.heroNewsModel.hasVideo ?
                <ReactPlayer
                  muted={false}
                  ref={ref}
                  config={{
                    youtube: {
                      playerVars: {
                        playsinline: 1,
                        rel: 0,
                        loop: 0,
                      },
                    },

                    vimeo: { playerOptions: { playsinline: 1 } },
                  }}
                  playsinline={true}
                  className="react-player-news-detail"
                  url={fields.heroNewsModel.videoUrl}
                  width="100%"
                  height="560px"
                  controls={true}
                  playing={true}
                  loop={false}
                  light={
                    fields.heroNewsModel.imgurl
                  }
                  pip={false}
                  playIcon={<div className="play-icon"></div>}
                  onPlay={pauseSlick}
                  onPause={playSlick}
                  onDuration={() => [
                    (fnGtmVideo('video_start', 'News Detail', fields.heroNewsModel.videoUrl, fields.heroNewsModel.displayName, ref.current.getDuration(), ref.current.getCurrentTime())),
                  ]}
                  onProgress={(x) => [
                    (gtmVideoOnProgress(fields, x.played, ref.current.getDuration(), ref.current.getCurrentTime()))
                  ]}
                  onEnded={() => [
                    (setshowvideo(true), fnGtmVideo('video_completion', 'News Detail', fields.heroNewsModel.videoUrl, fields.heroNewsModel.displayName, ref.current.getDuration(), ref.current.getCurrentTime()), ref.current.showPreview()),
                  ]}
                /> :
                <div>
                  <img className="border-8" src={fields.heroNewsModel.imgurl} alt={fields.heroNewsModel.imgalt} title={fields.heroNewsModel.imgalt} />
                </div>
              }
            </>
          </div>
        </div>
      </section>
    )
  } else {
    return <></>;
  }
}
var pushedValue = 0;

function gtmVideoOnProgress(fields, played, videoDuration, videoCurrentTime) {
  var currentValue = "";
  var duration = Math.round((played + Number.EPSILON) * 100) / 100;

  if (duration == "0.5" || duration == "0.25" || duration == "0.75") {
    var videoProvider = getVideoTypeFromvideoUrl("", fields.heroNewsModel.videoUrl);
    if (videoCurrentTime === null)
      videoCurrentTime = 0;
    if (pushedValue !== duration) {
      pushedValue = duration;
      if (window !== undefined && window !== null && window.dataLayer !== undefined && window !== null) {
        window.dataLayer.push({
          event: "video_progress",
          video_current_time: Math.round(videoCurrentTime),
          video_duration: Math.round(videoDuration),
          video_percent: duration * 100,
          video_provider: (videoProvider !== null && videoProvider !== "") ? videoProvider : undefined,
          video_title: (fields.heroNewsModel.displayName !== null && fields.heroNewsModel.displayName !== "") ? fields.heroNewsModel.displayName : undefined,
          video_url: (fields.heroNewsModel.videoUrl !== null && fields.heroNewsModel.videoUrl !== "") ? fields.heroNewsModel.videoUrl : undefined,
          visible: true
        });
      }
    }
  }
}
