import React, { useEffect } from 'react';
import { LoadHomePageServices } from '../Js-CommonFunctions';
import { baseModel } from '../../RouteHandler';

export default function HomePageServices({ fields }) {
  let serviceCounter = 0;

  useEffect(() => {
    if (fields.homePageService !== null) {
      LoadHomePageServices();
    }
  }, []);


  if (fields.homePageService != null) {
    return (
      <section className="back-gray services" id="homePageServices" data-loadcontentasync={(fields.homePageService.isExperienceEditor ? false : true)} >
        <div className="container" id="homePageServiceContainer">
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between">
            {(fields.homePageService.isServicePageExists) ?
              (
                <>
                  <a aria-label={fields?.homePageService?.homeServiceTitle} className="title gtmClickNavigation" href={fields.homePageService.servicePage}>
                    {/* {(!fields.homePageService.isNewBrandedSite) ?
                      (
                        <h2>{fields.homePageService.homeServiceTitle}</h2>
                      ) :
                      (
                        <h2><span className="title-border">{fields.homePageService.homeServiceTitle}</span></h2>
                      )
                    } */}
                    <h2><span className="news-title">{fields.homePageService.homeServiceTitle}</span></h2>
                  </a>
                  {/* <a className={"action" + (fields.homePageService.isNewBrandedSite ? " picto icon-plus-big" : " ") + " gtmClickNavigation"} aria-label="services-link" href={fields.homePageService.servicePage}>
                    {(!fields.homePageService.isNewBrandedSite) &&
                      <>
                        <strong>{fields.homePageService.homeServicesSeeAll} </strong><span id="spnServicesCount" className="counterLabel">({fields.centerServiceData.totalServicesCount})</span><span className="picto icon-ico-chevron-right"></span>
                      </>
                    }
                  </a> */}
                </>
              ) :
              (
                //need to check with Aditya
                <div className="title mb-1 mb-lg-0" style={{ cursor: "default" }}>
                  {/* {(!fields.homePageService.isNewBrandedSite) ?
                    (<h2>{fields.homePageService.homeServiceTitle}</h2>
                    ) :
                    <h2><span className="title-border">{fields.homePageService.homeServiceTitle}</span></h2>
                  } */
                  }
                  <h2><span className="news-title">{fields.homePageService.homeServiceTitle}</span></h2>
                </div>
              )
            }
          </div>
          {(fields.homePageService.isExperienceEditor) ?
            (
              (fields.centerServiceData != null && fields.centerServiceData.servicesList != null && fields.centerServiceData.servicesList.length > 0) &&
              <div className={fields.centerServiceData.display_Picture_For_Services ? "d-flex all-services home-services" : "search-wrapper"} id="services">
                {fields.centerServiceData.servicesList.map((item, index) => {
                  serviceCounter++;
                  return (
                    (fields.centerServiceData.display_Picture_For_Services) ?
                      (
                        (item.image != "") ?
                          (
                            <div key={index} className="card rounded-0 border-0 img-block  service-block">
                              <div className="img-wrap bg-transparent position-relative">
                                <img src={item.image} className="card-img-top rounded-0" alt={item.serviceData.shortTitle} />
                                <div className="service-picto-container">
                                  {fields.homePageService.isNewBrandedSite ?
                                    <img className="westfield-club-logo object-fit-contain w-0 d-flex m-auto" loading="lazy" src={baseModel.westfieldClubLogo?.value?.src} alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                                    :
                                    <img className="westfield-club-logo object-fit-contain w-0 d-flex m-auto" loading="lazy" src={baseModel.clubLogo?.value?.src} alt={baseModel.clubLogo?.value?.alt} title="club" />
                                  }
                                  {/* <span className={"picto " + (item.serviceData.pictoOff != "" ? item.serviceData.pictoOff : "icon-servicesaspictosservices")}></span> */}
                                </div>
                              </div>
                              <div className="card-body d-flex flew-row align-items-center px-0">
                                <span className="picto icon-tag-rw align-middle px-0 fs-5"></span>
                                <a href={item.serviceUrl} aria-label={item.serviceUrl} className="d-flex align-items-center justify-content-center stretched-link gtmClickNavigation" >
                                  <div className="title">{item.serviceData.shortTitle}</div>
                                </a>
                              </div>
                            </div>
                          ) :
                          (
                            <div key={index} className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1 pe-1 pe-lg-2">
                              <div className="block store-wrap with-logo">
                                <div className="store-img">
                                  <span className={"picto " + (item.serviceData.pictoOff != "" ? item.serviceData.pictoOff : "icon-servicesaspictosservices") + " border-8"}></span>
                                </div>
                              </div>
                              {/* <div className="card-body"> */}
                              <a href={item.serviceUrl} aria-label={item.serviceUrl} className="d-flex store-name text-start stretched-link gtmClickNavigation" >
                                <div className="title">{item.serviceData.shortTitle}</div>
                              </a>
                              {/* </div> */}
                            </div>
                          )
                      ) :
                      (
                        <div key={index} className="categories d-flex flex-row ">
                          <div className="search-col mb-1 pe-1 pe-lg-2">
                            <div className='block store-wrap with-logo'>
                              <div className='store-img'>
                                <span className={"picto " + (item.serviceData.pictoOff != "" ? item.serviceData.pictoOff : "icon-servicesaspictosservices") + " border-8"}></span>
                                <a href={item.serviceUrl} aria-label={item.serviceUrl} className="d-flex store-name text-start stretched-link gtmClickNavigation" >
                                  {item.serviceData.shortTitle}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )
                })}
                <input type="hidden" id="hdnServiceCount" value={fields.centerServiceData.totalServicesCount} />
              </div>
            ) :
            (
              <>
                <div className="loadersmall asyncloader" id="loaderDivServices" data-urlforasyncload={fields.homePageService.urlForAsyncLoad} data-counterid="hdnServiceCount" data-parent="homePageServices"></div>

                <div className="service-container" id="services">
                </div>
              </>
            )
          }
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}
