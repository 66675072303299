import React from 'react';

export default function TopServices({ fields }) {
    if (fields.topServices !== null && fields.topServices.serviceDetailModelList !== null
        && fields.topServices.serviceDetailModelList.length > 0) {
        return (
            <div className={"all-services " +(fields.topServices.isNewBrandedSite ?"all-services-new ":"")+" top-services py-4 loyalty-bg"} id="top-services">
                <div className="container">
                    {!fields.topServices.isNewBrandedSite && fields.topServices.topServices !== "" &&
                        < h2 className="content-title text-uppercase text-start">{fields.topServices.topServices}</h2>
                    }
                    <div className="row">
                        {fields.topServices.serviceDetailModelList.map((topserviceitem, index) => {
                            if (topserviceitem == null || topserviceitem.serviceData == null

                            ) {
                                return <></>;
                            }
                            return (
                                <div key={index} className="col-12 col-lg-4 mb-3 mb-lg-0">
                                    {fields.topServices.display_Picture_ForService && topserviceitem.image !== "" ?
                                        (
                                            <div className="card border-8">
                                                <img src={topserviceitem.image} className="card-img-top border-8" alt={topserviceitem.serviceData.shortTitle}></img>
                                                <div className="card-body text-start px-0">
                                                    <a href={topserviceitem.serviceUrl} aria-label={topserviceitem?.serviceData?.shortTitle} className="d-flex align-items-center text-start stretched-link">
                                                        <span className={"picto " + (topserviceitem.serviceData.pictoOff !== "" ? topserviceitem.serviceData.pictoOff : " icon-servicesaspictosservices")} ></span >
                                                        <div className=" title">{topserviceitem.serviceData.shortTitle}</div>
                                                    </a>
                                                    {(topserviceitem.isServiceLoyalty) &&

                                                        < div className="label loyalty-text mt-1">
                                                            <span className="align-middle picto icon-vip-card"></span>
                                                            <div className="text d-inline-block text-uppercase fw-bold">{topserviceitem.elligibility_Text}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            <div className="card border-8">
                                                <div className="service-picto picto-block">
                                                    <span className={"picto " + (topserviceitem.serviceData.pictoOff !== "" ? topserviceitem.serviceData.pictoOff : " icon-servicesaspictosservices")} ></span >
                                                </div>
                                                <div className="card-body text-start px-0">
                                                    <a href={topserviceitem.serviceUrl} aria-label={topserviceitem?.serviceData?.shortTitle} className="d-flex align-items-center stretched-link">
                                                        <div className="title">{topserviceitem.serviceData.shortTitle}</div>
                                                    </a>
                                                    {(topserviceitem.isServiceLoyalty) &&

                                                        <div className="label loyalty-text mt-1">
                                                            <span className="align-middle picto icon-vip-card"></span>
                                                            <div className="text d-inline-block text-uppercase fw-bold">{topserviceitem.elligibility_Text}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<></>)
    }
}
