import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import RoutableSitecoreLink from "../RoutableSitecoreLink";


export default function MobileHighlightedInfo({ fields }) {
    if (fields !== null && fields !== "" && fields.hero1Items !== null && fields.jsonContent !== null) {
        var jsonContent = fields.jsonContent;
        var model = fields.hero1Items;
        let isLeftSideDetailExists = (model.iconLeft !== null && model.iconLeft !== "") || (model.item.heading1left !== null && model.item.heading1left !== "") || (model.item.heading2left !== null && model.item.heading2left !== "") ||
            (model.item.linkLeft !== null && model.item.linkLeft.value !== null && model.item.linkLeft.value.url !== null && model.item.linkLeft.value.url !== "");
        let isRightSideDetailExists = (model.iconRight !== null && model.iconRight !== "") || (model.item.heading1right !== null && model.item.heading1right !== "") || (model.item.heading2right !== null && model.item.heading2right !== "") ||
            (model.item.linkRight !== null && model.item.linkRight.value !== null && model.item.linkRight.value.url !== null && model.item.linkRight.value.url !== "");
        return (
            <>
                {(isLeftSideDetailExists || isRightSideDetailExists) &&
                    <section className="hero mobile-only d-sm-none">
                        <div className="container">
                            <div className="whats-hot whats-hot-front">
                                <div className="row">
                                    {isLeftSideDetailExists &&
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="big-text  bg-dark  item">
                                                        <div className="whats-hot-item whats-hot-text text-light">
                                                            <div className="whats-hot-content">
                                                                <div className="vertical-align">
                                                                    <RichText field={jsonContent["IconLeft"]} />
                                                                    {((model.item.heading1left !== null && model.item.heading1left !== "") || (model.item.heading2left !== null && model.item.heading2left !== "")) &&
                                                                        <h3 className="whats-hot-title text-uppercase">
                                                                            <span className=" title-light"><Text field={jsonContent["Heading1Left"]} /></span>
                                                                            <span className=" title-light"><Text field={jsonContent["Heading2Left"]} /></span>
                                                                        </h3>
                                                                    }
                                                                    <br />

                                                                    {(model.item.linkLeft !== null && model.item.linkLeft.value !== null) &&
                                                                        <RoutableSitecoreLink field={jsonContent["LinkLeft"]} className="link-button btn-transparent">
                                                                            <Text field={jsonContent["Link labelLeft"]} />
                                                                        </RoutableSitecoreLink>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {isRightSideDetailExists &&
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="big-text  bg-lighter  item">
                                                        <div className="whats-hot-item whats-hot-text text-dark">
                                                            <div className="whats-hot-content">
                                                                <div className="vertical-align">
                                                                    <RichText field={jsonContent["IconRight"]} />
                                                                    {((model.item.heading1right !== null && model.item.heading1right !== "") || (model.item.heading2right !== null && model.item.heading2right !== "")) &&
                                                                        <h3 className="whats-hot-title text-uppercase">
                                                                            <span><Text field={jsonContent["Heading1Right"]} /></span>
                                                                            <span className=" title-light"><Text field={jsonContent["Heading2Right"]} /></span>
                                                                        </h3>
                                                                    }
                                                                    <br />

                                                                    {(model.item.linkRight !== null && model.item.linkRight.value !== null) &&
                                                                        <RoutableSitecoreLink field={jsonContent["LinkRight"]} className="link-button btn-transparent">
                                                                            <Text field={jsonContent["Link labeRight"]} />
                                                                        </RoutableSitecoreLink>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        )
    }
    else {
        return (<></>)
    }
}