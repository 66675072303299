import React from 'react';

export default function AllServices({ fields }) {

  if (fields.allService != null && fields.allService.length > 0) {
    return (
      <>
        {fields.allService.map((groups, index1) => {
          return (
            <>
              {groups != null && groups.key != '' && (
                <>
                  <section key={index1} className={"all-services "+(fields.allService.isNewBranded? " all-services-new" :"")}  id={groups.id}>
                    <div className="container">
                      <h2 className={fields.allService.isNewBranded? "page-title" :"content-title text-uppercase"}>{groups.key}</h2>
                      {groups.topService.length > 0 && (
                        <div className="row top-services">
                          {groups.topService.map((service, index) => {
                            return (
                              service.image !== '' && groups.displayPictureForServices ? (
                                <div key={index} className="col-12 col-lg-4 mb-3">
                                  <div className="card border-8 bg-transparent shadow-none">
                                    <img
                                      src={service.image}
                                      className="card-img-top border-8"
                                      alt={service.serviceData.shortTitle}
                                    />
                                    <div className="card-body text-start px-0">
                                      <a
                                        href={service.serviceUrl}
                                        aria-label={service.serviceData?.shortTitle}
                                        className="d-flex align-items-center text-start stretched-link"
                                      >
                                        <span
                                          className={
                                            'picto ' +
                                            (service.serviceData.pictoOn !== ''
                                              ? service.serviceData.pictoOn
                                              : 'icon-servicesaspictosservices') 
                                          }
                                        ></span>
                                        <div className="title">{service.serviceData.shortTitle}</div>
                                      </a>
                                      {service.isServiceLoyalty === true && (
                                        <div className="label loyalty-text mt-1">
                                          <span className="align-middle picto icon-vip-card"></span>
                                          <div className="text d-inline-block text-uppercase fw-bold">
                                            {service.elligibility_Text}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="col-12 col-lg-4 mb-3">
                                  <div className="card border-8 bg-transparent shadow-none">
                                    <div className="service-picto picto-block">
                                      <span
                                        className={
                                          'picto ' +
                                          (service.serviceData.pictoOn !== ''
                                            ? service.serviceData.pictoOn
                                            : 'icon-servicesaspictosservices')
                                        }
                                      ></span>
                                    </div>
                                    <div className="card-body text-start px-0">
                                      <a
                                        href={service.serviceUrl}
                                        aria-label={service.serviceData?.shortTitle}
                                        className="d-flex align-items-center text-start stretched-link"
                                      >
                                        <div className="title">{service.serviceData.shortTitle}</div>
                                      </a>
                                      {service.isServiceLoyalty === true && (
                                        <div className="label loyalty-text mt-1">
                                          <span className="align-middle picto icon-vip-card"></span>
                                          <div className="text d-inline-block text-uppercase fw-bold">
                                            {service.elligibility_Text}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      )}
                      {groups.nontopService.length > 0 && (
                        <div className="row">
                          {groups.nontopService.map((service, nontopServiceindex) => {
                            return (
                              <div key={nontopServiceindex} className="col-12 col-lg-3">
                                <a
                                  href={service.serviceUrl}
                                  aria-label={service.serviceData?.shortTitle}
                                  className="d-flex align-items-center service-block mb-3"
                                >
                                  <span
                                    className={
                                      'picto ' +
                                      (service.serviceData.pictoOn != ''
                                        ? service.serviceData.pictoOn
                                        : 'icon-servicesaspictosservices')
                                    }
                                  ></span>
                                  <div className="title">{service.serviceData.shortTitle}</div>
                                </a>
                                {service.isServiceLoyalty === true && (
                                  <div className="label loyalty-text mt-1">
                                    <span className="align-middle picto icon-vip-card"></span>
                                    <div className="text d-inline-block text-uppercase fw-bold">
                                      {service.elligibility_Text}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </section>
                </>
              )}
            </>
          );
        }
        )}
      </>
    );
  } else {
    return <div></div>;
  }
}
