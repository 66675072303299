import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import { baseModel } from '../../RouteHandler';
import { closeVehicleErrorPopin, createParkingCookie, fnConnectRedirectTodetailPage, fnGTMClickServiceOffer, fnRedirectToConnectLogin, fnRedirectToMyAccount } from "../Js-CommonFunctions";
import { addVehicleClick, updatePartnerDetail } from '../MyAccount/MyAccountScripts';

export default function EasyParking({ fields, visitorId }) {



  function parkingCTAClick(fields) {
    if (visitorId === null || visitorId === "" || visitorId === undefined) {
      // createParkingCookie();
      fnGTMClickServiceOffer(fields.serviceDetail.lightSmartParkingData.parkingCTAText);
      fnConnectRedirectTodetailPage();
      fnRedirectToConnectLogin(baseModel.connectRedirectUrl);
    }
    else {
      if (typeof window !== "undefined")
        window.scrollTo(0, 0);
      if (document.getElementById("servicedetailImgblock")) {
        document.getElementById("servicedetailImgblock").classList.add('d-none')
      }
      if (document.getElementById("servicedetailPictoblock")) {
        document.getElementById("servicedetailPictoblock").classList.add('d-none')
      }
      if (document.getElementById('vehicleinfo')) {
        document.getElementById('vehicleinfo').classList.remove('d-none')
      }
    }
  }

  if ((fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel !== null && fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel !== undefined && fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel !== "")
    || (fields.serviceDetail.serviceInfo.loyaltyBenefitsDesc  !== null && fields.serviceDetail.serviceInfo.loyaltyBenefitsDesc  !== undefined && fields.serviceDetail.serviceInfo.loyaltyBenefitsDesc  !== "")){
      let loyaltyBenefitsDescription = { value: fields.serviceDetail.serviceInfo.loyaltyBenefitsDesc };
      return (
      <>
        <div className="enjoy-cta-block loyalty-block-wc text-start bg-gray border-8">
          <div className="loyalty-block-wc-img service-detail-radius position-relative border-top">
            <picture>
              <div className="justify-content-between align-items-center service-detail-desktop-header">
                <div>
                  {fields.serviceDetail.isNewBranded ?
                    (fields?.serviceDetail?.westfieldClubLogo?.value !== null && fields?.serviceDetail?.westfieldClubLogo?.value !== "" && fields?.serviceDetail?.westfieldClubLogo?.value.src !== null && fields?.serviceDetail?.westfieldClubLogo?.value?.src !== "" &&
                      <img className="loyalty-program-responsive-content d-flex text-start me-3" src={fields?.serviceDetail?.westfieldClubLogo?.value?.src} loading="lazy"
                        alt={fields?.serviceDetail?.westfieldClubLogo?.value?.alt} title="club" />
                    ) : (fields?.serviceDetail?.clubLogo?.value !== null && fields?.serviceDetail?.clubLogo?.value !== "" && fields?.serviceDetail?.clubLogo?.value.src !== null && fields?.serviceDetail?.clubLogo?.value?.src !== "" &&
                      <img className="loyalty-program-responsive-content d-flex text-start me-3" src={fields?.serviceDetail?.clubLogo?.value?.src} loading="lazy"
                        alt={fields?.serviceDetail?.clubLogo?.value?.alt} title="club" />
                    )
                  }
                </div>
                <span className="service-detail-text d-flex justify-content-end text-white text-end">{fields?.serviceDetail?.serviceInfo?.loyaltyBenefitsLabel}</span>
              </div>
            </picture>
          </div>        
          <div className="pre-description text-start p-3">
          <RichText field={loyaltyBenefitsDescription} />
          </div>
          {fields.serviceDetail.isParkingService && fields.serviceDetail.lightSmartParkingData !== null && fields.serviceDetail.lightSmartParkingData !== "" && fields.serviceDetail.lightSmartParkingData !== undefined &&
            <div className="easy-parking-content-button justify-content-lg-start justify-content-center ps-lg-3 mb-4 d-lg-flex d-md-flex d-none">
              <div className="link-button btn-branded-parking mb-3" onClick={() => parkingCTAClick(fields)}>
                {fields.serviceDetail.lightSmartParkingData.parkingCTAText}
              </div>
            </div>
          }
        </div>
        {fields.serviceDetail.isParkingService && fields.serviceDetail.lightSmartParkingData !== null && fields.serviceDetail.lightSmartParkingData !== "" && fields.serviceDetail.lightSmartParkingData !== undefined &&
          <div className="easy-parking-content-button justify-content-lg-start justify-content-center ps-lg-3 mb-4 d-lg-none d-md-none d-sm-flex">
            <div className="link-button btn-branded-parking mb-3" onClick={() => parkingCTAClick(fields)}>
              {fields.serviceDetail.lightSmartParkingData.parkingCTAText}
            </div>
          </div>
        }
        {/* {!fields.isAuthenticated ? (
              <>  
                <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                <div className="title text-uppercase easyparking-header text-start mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                  onClick={() => { createParkingCookie(); fnGTMClickServiceOffer(fields.secondaryParkingBlock.loginCTA);fnRedirectToConnectLogin(baseModel.connectLoginUrlFromParking) }}>{fields.secondaryParkingBlock.loginCTA}</button>
              </>
            ) : (
              fields.isSmartParker ? (
                <>
                  {(!fields.visitorVehicleAvailable) &&
                    <div id="vehicleErrorText" className="profile-warning d-flex align-items-center my-3 mobile-container">
                      <div className="text-black text-decoration-none d-flex align-items-center">
                        <span className="icon-alert-info warning-icon fs-3 me-2"></span>
                        <Text field={fields.secondaryParkingBlock.parkingErrorText} />
                      </div>
                      <div>
                        <a href="#" className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none" onClick={() => { closeVehicleErrorPopin() }}></a>
                      </div>
                    </div>
                  }
                  <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                  <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                  <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                  <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                  <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                    data-target="#licenseplate-popin" onClick={() => {fnGTMClickServiceOffer(fields.secondaryParkingBlock.manageCTA); fnRedirectToMyAccount(fields.accountUrl?.link?.value?.url + "?ShowSuccessPopin=manage-vehicles") }}>{fields.secondaryParkingBlock.manageCTA}</button>
                </>
              ) : (
                <>
                  {(fields.visitorVehicleAvailable) ? (
                    (fields.isPartnerActivated) ? (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.secondaryActivationTitle}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => {fnGTMClickServiceOffer(fields.secondaryParkingBlock.secondaryActivationCTA);updatePartnerDetail(fields.pcCode, fields.parkingPartner,
                            (fields.accountUrl?.link?.value?.url + "?ShowSuccessPopin=easy-parking&pcCode=" + fields.pcCode))}}>{fields.secondaryParkingBlock.secondaryActivationCTA}</button>
                      </>
                    ) : (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => {fnGTMClickServiceOffer(fields.secondaryParkingBlock.firstActivationCTA);updatePartnerDetail(fields.pcCode, fields.parkingPartner,
                            (fields.accountUrl?.link?.value?.url + "?ShowSuccessPopin=easy-parking&pcCode=" + fields.pcCode))}}>{fields.secondaryParkingBlock.firstActivationCTA}</button>
                      </>
                    )
                  ) : (
                    (fields.isPartnerActivated) ? (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.secondaryActivationTitle}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.secondaryActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => { fnGTMClickServiceOffer(fields.secondaryParkingBlock.secondaryActivationCTA); addVehicleClick(fields, null, true, false, null, null, true) }}>
                          {fields.secondaryParkingBlock.secondaryActivationCTA}</button>
                      </>
                    ) : (
                      <>
                        <div className="picto icon-srv-cars d-inline-block d-flex align-items-center text-center justify-content-center fs-2 mb-1"></div>
                        <div className="title text-uppercase easyparking-header mb-3 mt-3 mb-lg-3">{fields.secondaryParkingBlock.title}</div>
                        <div className="pre-description text-start mb-4"><Text field={fields.secondaryParkingBlock.firstActivationDescription} /></div>
                        <div className="description text-start-midgray mb-4"><Text field={fields.secondaryParkingBlock.firstActivationMention} /></div>
                        <button className="easy-parking-content-button btn btn-dark input-submit open-popin" type="submit" data-toggle="modal"
                          onClick={() => {fnGTMClickServiceOffer(fields.secondaryParkingBlock.firstActivationCTA); addVehicleClick(fields, null, true, false, null, null, true) }}>
                          {fields.secondaryParkingBlock.firstActivationCTA}</button>
                      </>
                    )
                  )
                  }
                </>
              )
            )
            } */}

      </>
    )
  }
  else {
    return <>
    </>
  }
}
