import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { switchTabOrScroll } from '../Js-CommonFunctions.js';

export default function LoyaltyProgramBlocks({ fields }) {

    const [lcAdvantageLoaded, setlcAdvantageLoaded] = useState(false);

    useEffect(() => {
        setlcAdvantageLoaded(true);
    }, []);
    useEffect(() => {
        if (lcAdvantageLoaded && document.getElementById('lcAdvantage')) {
            if (document.getElementsByClassName("pillars-infos") && document.getElementsByClassName("pillars-infos").length > 0) {
                document.querySelectorAll(".pillars-infos").forEach((x, index) => {
                    x.classList.remove("cursor-default");
                })
            }
            if (document.getElementsByClassName("pillar-title") && document.getElementsByClassName("pillar-title").length > 0) {
                document.querySelectorAll(".pillar-title").forEach((x, index) => {
                    x.classList.remove("text-decoration-none");
                })
            }
        }
    }, [lcAdvantageLoaded]);
    var settings = {
        customPaging: function (i) {
            return (
                <button type="button" className="bg-main" data-role="none" role="button" tabIndex="0" />
            );
        },
        arrows: false,
        dots: false,
        autoplay: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
                settings: 'unslick'
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: true,
                    dots: true,
                    autoplay: true,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }


        ]
    };

    if (fields.loyaltypillar != null) {
        var constant = fields.loyaltypillar.constant;
        return (
            <div className={"loyalty-pillars" + (fields.loyaltypillar.isNewBrandedsite ? " pb-4" : "")} id="lcpillars">
                <div className="container" id="carouselPillars">
                    {fields.loyaltypillar.isMobile &&
                        <Slider {...settings} className="row carousel-pillars d-lg-flex">
                            <div className="col-12 col-md-3">
                                <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-stores gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'shopsTab')}>
                                    <div className="vertical-align">
                                        <div className="picto-wrap"><i className="picto icon-servicesaspictosoffers"></i></div>
                                        <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? "mt-4" : "")}>
                                            <div className="pillar-title text-uppercase text-decoration-none">
                                                {fields.loyaltypillar.loyaltypillaroffer}
                                            </div>
                                            <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? "text-center" : "")}>
                                                <Text field={fields.content[constant.offerPillarLongDescription]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-3">
                                <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-service1 gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'servicesTab')}>
                                    <div className="vertical-align">
                                        <div className="picto-wrap">
                                            {fields.loyaltypillar.pillarservice1pictoImg != null && fields.loyaltypillar.pillarservice1pictoImg.value.src != "" && fields.loyaltypillar.pillarservice1pictoImg.value.src != null ?
                                                (<img src={fields.loyaltypillar.pillarservice1pictoImg.value.src} alt={fields.loyaltypillar.pillarservice1pictoImg.value.alt} title={fields.loyaltypillar.pillarservice1pictoImg.value.alt} />) :
                                                (<i className="picto icon-servicesaspictosservices"></i>)
                                            }
                                        </div>
                                        <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? "mt-4" : "")}>
                                            <div className="pillar-title text-uppercase text-decoration-none">
                                                {fields.loyaltypillar.pillarservice1?.value != null && fields.loyaltypillar.pillarservice1?.value != "" ?
                                                    (<Text field={fields.content[constant.service1PillarTitle]} />) :
                                                    (fields.loyaltypillar.loyaltypillarservice)
                                                }
                                            </div>
                                            <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? "text-center" : "")}><Text field={fields.content[constant.service1PillarLongDescription]} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-3">
                                <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-service2 gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'servicesTab')}>
                                    <div className="vertical-align">
                                        <div className="picto-wrap">
                                            {fields.loyaltypillar.pillarservice2pictoImg != null && fields.loyaltypillar.pillarservice2pictoImg.value.src != "" && fields.loyaltypillar.pillarservice2pictoImg.value.src != null ?
                                                (<img src={fields.loyaltypillar.pillarservice2pictoImg.value.src} alt={fields.loyaltypillar.pillarservice2pictoImg.value.alt} title={fields.loyaltypillar.pillarservice2pictoImg.value.alt} />) :
                                                (<i className="picto icon-servicesaspictosservices"></i>)
                                            }
                                        </div>
                                        <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? "mt-4" : "")}>
                                            <div className="pillar-title text-uppercase text-decoration-none">
                                                {fields.loyaltypillar.pillarservice2?.value != null && fields.loyaltypillar.pillarservice2?.value != "" ?
                                                    (<Text field={fields.content[constant.service2PillarTitle]} />) :
                                                    (fields.loyaltypillar.loyaltypillarservice)
                                                }
                                            </div>
                                            <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? "text-center" : "")}><Text field={fields.content[constant.service2PillarLongDescription]} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-3">
                                <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-event gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'eventTab')}>
                                    <div className="vertical-align">
                                        <div className="picto-wrap"><i className="picto icon-servicesaspictosevent"></i></div>
                                        <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? " mt-4" : "")}>
                                            <div className="pillar-title text-uppercase text-decoration-none">
                                                {fields.loyaltypillar.loyaltypillarevent}
                                            </div>
                                            <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? " text-center" : "")}>
                                                <Text field={fields.content[constant.eventPillarLongDescription]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    }
                    {!fields.loyaltypillar.isMobile &&
                        <>
                            <div className="row carousel-pillars d-lg-flex">
                                <div className="col-12 col-md-3">
                                    <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-stores gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'shopsTab')}>
                                        <div className="vertical-align">
                                            <div className="picto-wrap"><i className="picto icon-servicesaspictosoffers"></i></div>
                                            <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? "mt-4" : "")}>
                                                <div className="pillar-title text-uppercase text-decoration-none">
                                                    {fields.loyaltypillar.loyaltypillaroffer}
                                                </div>
                                                <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? "text-center" : "")}>
                                                    <Text field={fields.content[constant.offerPillarLongDescription]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3">
                                    <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-service1 gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'servicesTab')}>
                                        <div className="vertical-align">
                                            <div className="picto-wrap">
                                                {fields.loyaltypillar.pillarservice1pictoImg != null && fields.loyaltypillar.pillarservice1pictoImg.value.src != "" && fields.loyaltypillar.pillarservice1pictoImg.value.src != null ?
                                                    (<img src={fields.loyaltypillar.pillarservice1pictoImg.value.src} alt={fields.loyaltypillar.pillarservice1pictoImg.value.alt} title={fields.loyaltypillar.pillarservice1pictoImg.value.alt} />) :
                                                    (<i className="picto icon-servicesaspictosservices"></i>)
                                                }
                                            </div>
                                            <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? "mt-4" : "")}>
                                                <div className="pillar-title text-uppercase text-decoration-none">
                                                    {fields.loyaltypillar.pillarservice1?.value != null && fields.loyaltypillar.pillarservice1?.value != "" ?
                                                        (<Text field={fields.content[constant.service1PillarTitle]} />) :
                                                        (fields.loyaltypillar.loyaltypillarservice)
                                                    }
                                                </div>
                                                <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? "text-center" : "")}><Text field={fields.content[constant.service1PillarLongDescription]} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3">
                                    <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-service2 gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'servicesTab')}>
                                        <div className="vertical-align">
                                            <div className="picto-wrap">
                                                {fields.loyaltypillar.pillarservice2pictoImg != null && fields.loyaltypillar.pillarservice2pictoImg.value.src != "" && fields.loyaltypillar.pillarservice2pictoImg.value.src != null ?
                                                    (<img src={fields.loyaltypillar.pillarservice2pictoImg.value.src} alt={fields.loyaltypillar.pillarservice2pictoImg.value.alt} title={fields.loyaltypillar.pillarservice2pictoImg.value.alt} />) :
                                                    (<i className="picto icon-servicesaspictosservices"></i>)
                                                }
                                            </div>
                                            <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? "mt-4" : "")}>
                                                <div className="pillar-title text-uppercase text-decoration-none">
                                                    {fields.loyaltypillar.pillarservice2?.value != null && fields.loyaltypillar.pillarservice2?.value != "" ?
                                                        (<Text field={fields.content[constant.service2PillarTitle]} />) :
                                                        (fields.loyaltypillar.loyaltypillarservice)
                                                    }
                                                </div>
                                                <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? "text-center" : "")}><Text field={fields.content[constant.service2PillarLongDescription]} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3">
                                    <div className={"pillars-infos cursor-default " + (fields.loyaltypillar.isNewBrandedsite ? "" : "bg-white") + " loyalty-event gtmLoyaltyPillarBlock h-100"} onClick={(e) => fnLoyaltyPillarBlocksClick(e, 'eventTab')}>
                                        <div className="vertical-align">
                                            <div className="picto-wrap"><i className="picto icon-servicesaspictosevent"></i></div>
                                            <div className={"pillar-details " + (fields.loyaltypillar.isNewBrandedsite ? " mt-4" : "")}>
                                                <div className="pillar-title text-uppercase text-decoration-none">
                                                    {fields.loyaltypillar.loyaltypillarevent}
                                                </div>
                                                <div className={"pillar-description " + (fields.loyaltypillar.isNewBrandedsite ? " text-center" : "")}>
                                                    <Text field={fields.content[constant.eventPillarLongDescription]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
    else {
        return <></>
    }
}
//Click Function for Loyalty Pillar blocks
function fnLoyaltyPillarBlocksClick(e, element) {
    if (document.getElementById('lcAdvantage')) {
        var loyaltyElement = document.getElementById(element);
        var hrefElement = document.getElementById(element).getAttribute('href');
        setmeLoyaltyPillarBlocks(hrefElement, loyaltyElement);
        switchTabOrScroll();
        e.target.blur();
    }
}

function setmeLoyaltyPillarBlocks(thisli, thisnode) {
    var elementClass = thisnode.className;
    if (document.getElementsByClassName('gtmLoyaltyAdvantageTabs').length > 0) {
        document.querySelectorAll('.gtmLoyaltyAdvantageTabs').forEach(x => {
            x.classList.remove("active");
        });
    }
    var elementId = thisnode.id;
    document.getElementById(elementId).classList.add("active");
    var currentHref = thisli.split("#")[1];
    if (!thisli.includes("webview=1")) {
        window.history.pushState('', document.title, window.location.pathname + '#Tab=' + currentHref);
    }
    else {
        window.history.pushState('', document.title, window.location.pathname + "?webview=1" + '#Tab=' + currentHref);
    }
}