import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { baseModel } from '../../RouteHandler';
import { closeVehicleErrorPopin, createParkingCookie, fnRedirectToConnectLogin, fnRedirectToMyAccount, fnRedirectTosmartparking, openVehicleErrorPopin, isTokenExpired, GetRenewedAccessToken } from "../Js-CommonFunctions";
import { updatePartnerDetail } from '../MyAccount/MyAccountScripts';

export default function SecondaryParking({ fields }) {

  function FnupdatePartnerDetail(pcCode, parkingPartner, accountUrl) {
    if (isTokenExpired()) {
      var args = [pcCode, parkingPartner, accountUrl];
      GetRenewedAccessToken(updatePartnerDetail, args, false);
    } else {
      updatePartnerDetail(pcCode, parkingPartner, accountUrl);
    }
  }

  if (fields !== null && fields !== undefined && fields.secondaryActivation !== null && fields.secondaryActivation !== undefined &&
    fields.secondaryActivation.smartParkingEligible) {
    if (fields.secondaryActivation.isAuthenticated && fields.secondaryActivation.isPartnerActivated) {
      return (
        <section className="even d-md-block d-lg-block d-xl-block parking-activation-page">
          <div className="container  secondary-activation-container parking-activation-homepage">
            <div id="vehicleErrorText" className="profile-warning d-flex align-items-center my-3 mobile-container d-none">
              <div className="text-black text-decoration-none d-flex align-items-center">
                <span className="icon-alert-info warning-icon fs-3 me-2"></span>
                <Text field={fields.secondaryActivation.secondaryParkingBlock.parkingErrorText} />
              </div>
              <div>
                <a href="javascript:void(0);" className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none" data-testid="testclick1" onClick={() => { closeVehicleErrorPopin() }}></a>
              </div>
            </div>

            <div className="row mx-0">
              <div className=" secondary-activation-title">
                {fields.secondaryActivation.secondaryParkingBlock?.secondaryActivationTitle}
              </div>
            </div>

            <div className="row mx-0 d-flex align-items-center justify-content-center">
              <div className="col-md-12 secondary-activation-content">
                <Text field={fields.secondaryActivation.secondaryParkingBlock?.secondaryActivationDescription} />
              </div>
            </div>

            <div className="row mx-0">
              <div className="button-profile-container  ">
                {
                  ((fields.secondaryActivation.visitorVehicleAvailable && fields.secondaryActivation.creditCardAvailable) ? (
                    <a className="profile-submit secondary-activation-button  cursor-pointer" href="javascript:void(0);"
                      onClick={() => FnupdatePartnerDetail(fields.secondaryActivation.pcCode, fields.secondaryActivation.parkingPartner, fields.secondaryActivation.accountUrl?.link?.value?.url + "?ShowSuccessPopin=smart-parking&pcCode=" + fields.secondaryActivation.pcCode)}>
                      {fields.secondaryActivation.secondaryParkingBlock.secondaryActivationCTA}</a>
                  ) :
                    (
                      <a className="profile-submit secondary-activation-button  cursor-pointer" href="javascript:void(0);"
                        onClick={() => openVehicleErrorPopin()}>{fields.secondaryActivation.secondaryParkingBlock.secondaryActivationCTA}</a>
                    )
                  )
                }
              </div>
            </div>
          </div>
        </section >
      )
    }
    else {
      return (
        <>
          <section className="promotional-banner d-lg-block">
            <div className="wrapper container">
              <div className="carousel-card dark-overlay-active">
                <div className="promo-img position-relative">
                  <img src={fields.secondaryActivation.secondaryParkingBlock.image?.value?.src} className="img-fluid" />
                </div>
                <div className="carousel-wrap justify-content-center position-absolute">
                  <div className="carousel-block align-items-center text-center">
                    <div className="carousel-title">{fields.secondaryActivation.secondaryParkingBlock.title}</div>
                    <div className="carousel-description"><Text field={fields.secondaryActivation.secondaryParkingBlock.firstActivationDescription} /></div>
                    <>
                      {(fields.secondaryActivation.isAuthenticated) ? (
                        (fields.secondaryActivation.isSmartParker) ? (
                          <>
                            <a href="javascript:void(0);" aria-label={fields?.secondaryActivation?.secondaryParkingBlock?.manageCTA} className="btn btn-white-transparent"
                              onClick={() => fnRedirectToMyAccount((fields.secondaryActivation.accountUrl?.link?.value?.url + "?pcCode=" + fields.secondaryActivation.pcCode))}>
                              {fields.secondaryActivation.secondaryParkingBlock.manageCTA}</a>
                          </>
                        ) : (
                          <>
                            <a href="javascript:void(0);" aria-label={fields?.secondaryActivation?.secondaryParkingBlock?.firstActivationCTA} className="btn btn-white-transparent" onClick={() => { fnRedirectTosmartparking(fields.secondaryActivation.smartParkingUrl?.link?.value?.url) }}
                            >{fields.secondaryActivation.secondaryParkingBlock.firstActivationCTA}</a>
                          </>
                        )
                      ) : (
                        <>
                          <a href="javascript:void(0);" aria-label={fields?.secondaryActivation?.secondaryParkingBlock?.loginCTA} className="btn btn-white-transparent" onClick={() => { createParkingCookie(); fnRedirectToConnectLogin(baseModel.connectLoginUrlFromParking) }}>
                            {fields.secondaryActivation.secondaryParkingBlock.loginCTA}</a>
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )
    }
  }
  else {
    return <>
    </>
  }

}



