import loadable from "@loadable/component";
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from "react";
import { CommonBookmarkallpage, offersTooltipOnHover } from '../Js-CommonFunctions';
import Service from '../Service/index.js';
const ShopLandingOfferInnerPage = loadable(() => import("../ShopLandingOfferInnerPage"));

export default function ClubServices({ fields }) {

  const [visibleShops, setVisibleShops] = useState(4);
  const [visibleRestaurants, setVisibleRestaurants] = useState(4);
  const [isCommunity, setIsCommunity] = useState(false);
  let offerItemPerPage = 4;

  useEffect(() => {

    if (document.getElementById("loyalty-banner")) {
      let Community = document.getElementById("loyalty-banner").getAttribute("data-communityname");
      if (Community !== null && Community !== undefined && Community !== "") {
        setIsCommunity(true);
      }
    }

    if (fields?.loyaltyAdvModel?.noCommunityData?.image?.value?.src !== null && fields?.loyaltyAdvModel?.noCommunityData?.image?.value?.src !== undefined
      && fields?.loyaltyAdvModel?.noCommunityData?.image?.value?.src !== "" && fields?.loyaltyAdvModel?.isNewBrandedSite) {
      let loyaltyServices = document.getElementById("loyalty-services");
      let bgImage = "background-image: url(" + fields?.loyaltyAdvModel?.noCommunityData?.image?.value?.src + ")"
      if (loyaltyServices) {
        loyaltyServices.setAttribute("style", bgImage);
      }
    }
    offersTooltipOnHover();

  }, []);

  useEffect(() => {
    CommonBookmarkallpage();
    offersTooltipOnHover();
  }, [visibleShops, visibleRestaurants, isCommunity]);

  const loyaltyOffersShopsVM = (element) => {
    setVisibleShops(visibleShops + offerItemPerPage);
    if (document.getElementById('lcAdvShopsViewMore'))
      document.getElementById('lcAdvShopsViewMore').setAttribute('data-gtmpagenumber', '');
    let pagenumber = Math.ceil(visibleShops / offerItemPerPage) + 1;
    if (document.getElementById('lcAdvShopsViewMore'))
      document.getElementById('lcAdvShopsViewMore').setAttribute('data-gtmpagenumber', pagenumber);

    var totalcount = fields.loyaltyAdvModel.shopOffersInfo.storeTotalOffersCount;
    if (visibleShops >= totalcount) {
      if (document.getElementById("lcAdvShopsViewMoreDiv"))
        document.getElementById("lcAdvShopsViewMoreDiv").style.display = "none";
    }

    CommonBookmarkallpage();   //needs to be implemented as part of Bookmark functionality

  }

  const loyaltyOffersRestaurantVM = (element) => {
    setVisibleRestaurants(visibleRestaurants + offerItemPerPage);
    if (document.getElementById('lcAdvRestaurantsViewMore'))
      document.getElementById('lcAdvRestaurantsViewMore').setAttribute('data-gtmpagenumber', '');
    let pagenumber = Math.ceil(visibleRestaurants / offerItemPerPage) + 1;
    if (document.getElementById('lcAdvRestaurantsViewMore'))
      document.getElementById('lcAdvRestaurantsViewMore').setAttribute('data-gtmpagenumber', pagenumber);

    var totalcount = fields.loyaltyAdvModel.restaurantOffersInfo.restaurantTotalOffersCount; // document.querySelector('#lcAdvRestaurantsOffers #totalviewmorecount')[0].value;
    if (visibleRestaurants >= totalcount) {
      if (document.getElementById('lcAdvRestaurantsViewMoreDiv'))
        document.getElementById("lcAdvRestaurantsViewMoreDiv").style.display = "none";
    }
    CommonBookmarkallpage();   //needs to be implemented as part of Bookmark functionality
    // lazyLoadingImg();

  }

  if (fields !== null && fields !== undefined && fields !== "" && fields.loyaltyAdvModel !== null && fields.loyaltyAdvModel !== undefined && fields.loyaltyAdvModel !== "") {
    if (fields.loyaltyAdvModel.restaurantOffersInfo !== undefined && fields.loyaltyAdvModel.restaurantOffersInfo !== null && fields.loyaltyAdvModel.restaurantOffersInfo !== "") {
      fields.loyaltyAdvModel.restaurantOffersInfo.offerItems = fields.loyaltyAdvModel.restaurantOffersInfo.items;
    }

    if (fields.loyaltyAdvModel.shopOffersInfo !== null && fields.loyaltyAdvModel.shopOffersInfo !== undefined && fields.loyaltyAdvModel.shopOffersInfo !== "") {
      fields.loyaltyAdvModel.shopOffersInfo.offerItems = fields.loyaltyAdvModel.shopOffersInfo.items;
      fields.loyaltyAdvModel.shopOffersInfo.eligibilityLoyaltyText = fields.loyaltyAdvModel.eligibilityLoyaltyText;
      fields.loyaltyAdvModel.shopOffersInfo.eligibilityWorkerText = fields.loyaltyAdvModel.eligibilityWorkerText;
    }

    return (
      <section className={"offers loyalty-block-wc p-0 " + (fields.loyaltyAdvModel.isNewBrandedSite ? "back-gray" : "") + " loyalty navme"} id="lcAdvantage">
        <div className="loyalty-content-grid-offer-block p-0" id="loyalty-services">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-column py-lg-5">
                  <div className="loyalty-left-content-container ">
                    {isCommunity && fields.loyaltyAdvModel.communityData?.title?.value !== null && fields.loyaltyAdvModel.communityData?.title?.value !== undefined &&
                      fields.loyaltyAdvModel.communityData?.title?.value &&
                      <div className="loyalty-title-grid-block d-flex justify-content-center text-center">
                        {fields.loyaltyAdvModel.communityData?.title?.value}
                      </div>
                    }
                    {!isCommunity && fields.loyaltyAdvModel.noCommunityData?.title?.value !== null && fields.loyaltyAdvModel.noCommunityData?.title?.value !== undefined &&
                      fields.loyaltyAdvModel.noCommunityData?.title?.value &&
                      <div className="loyalty-title-grid-block d-flex justify-content-center text-center">
                        {fields.loyaltyAdvModel.noCommunityData?.title?.value}
                      </div>
                    }
                    {isCommunity && fields.loyaltyAdvModel.communityData?.description?.value !== null && fields.loyaltyAdvModel.communityData?.description?.value !== undefined &&
                      fields.loyaltyAdvModel.communityData?.description?.value !== "" &&
                      <div className="loyalty-subtitle-grid-block d-flex justify-content-center text-center">
                        {<RichText field={fields.loyaltyAdvModel.communityData?.description} />}
                      </div>
                    }
                    {!isCommunity && fields.loyaltyAdvModel.noCommunityData?.description?.value !== null && fields.loyaltyAdvModel.noCommunityData?.description?.value !== undefined &&
                      fields.loyaltyAdvModel.noCommunityData?.description?.value !== "" &&
                      <div className="loyalty-subtitle-grid-block d-flex justify-content-center text-center">
                        {<RichText field={fields.loyaltyAdvModel.noCommunityData?.description} />}
                      </div>
                    }
                  </div>
                  <div className={(fields.loyaltyAdvModel.isNewBrandedSite ? "header-title d-flex align-items-baseline flex-wrap justify-content-lg-between" : "content-title text-uppercase text-center underline")}>
                    {fields.loyaltyAdvModel.isNewBrandedSite ?
                      (
                        <div className="title">
                          <a aria-label={fields?.loyaltyAdvModel?.offers} href={fields.loyaltyAdvModel.urlPrefix + fields.loyaltyAdvModel.slash + fields.loyaltyAdvModel.loyaltyOfferPage}>
                            <h2><span className="title-border">{fields.loyaltyAdvModel.offers}</span></h2>
                          </a>
                        </div>
                      ) :
                      (
                        fields.loyaltyAdvModel.offers
                      )
                    }
                  </div>
                  <ul id="Tabbing" className="nav nav-tabs" role="tablist">
                    <li role="presentation" className="col m-0 p-1 makethisactive active">
                      <span href="#lcAdvServicesTab" aria-label={fields?.loyaltyAdvModel?.services} id="clubServicesTab" className="m-0 text-capitalize cursor-pointer mozaic-card-title-box border-8 w-100 gtmLoyaltyAdvantageTabs genericTabs active" aria-controls="lcAdvServicesTab" role="tab" data-bs-toggle="tab" data-testid='testclick1' onClick={(e) => setme(e)}>
                        {(fields.loyaltyAdvModel.services != null && fields.loyaltyAdvModel.services != "") &&
                          <span>{fields.loyaltyAdvModel.services}</span>
                        }
                      </span>
                    </li>
                    <li role="presentation" className="col m-0 p-1">
                      <span href="#lcAdvShopsTab" aria-label={fields?.loyaltyAdvModel?.shops} id="clubShopsTab" className="m-0 text-capitalize cursor-pointer mozaic-card-title-box border-8 w-100 gtmLoyaltyAdvantageTabs genericTabs" aria-controls="lcAdvShopsTab" role="tab" data-bs-toggle="tab" data-testid='testclick2' onClick={(e) => setme(e)}>
                        {(fields.loyaltyAdvModel.shops != null && fields.loyaltyAdvModel.shops != "") &&
                          <span>{fields.loyaltyAdvModel.shops}</span>
                        }
                      </span>
                    </li>
                    <li role="presentation" className="col m-0 p-1">
                      <span href="#lcAdvRestaurantsTab" aria-label={fields?.loyaltyAdvModel?.restaurants} id="clubRestaurantsTab" className="m-0 text-capitalize cursor-pointer mozaic-card-title-box border-8 w-100 gtmLoyaltyAdvantageTabs genericTabs" aria-controls="lcAdvRestaurantsTab" role="tab" data-bs-toggle="tab" data-testid='testclick3' onClick={(e) => setme(e)}>
                        {(fields.loyaltyAdvModel.restaurants != null && fields.loyaltyAdvModel.restaurants != "") &&
                          <span>{fields.loyaltyAdvModel.restaurants}</span>
                        }
                      </span>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div role="tabpanel" className="tab-pane active" id="lcAdvServicesTab">
                      {(fields.loyaltyAdvModel.servicesInfo == null || ((fields.loyaltyAdvModel.servicesInfo.servicePagePro == null || fields.loyaltyAdvModel.servicesInfo.servicePagePro.length == 0)
                        && (fields.loyaltyAdvModel.servicesInfo.servicehighlightmodel == null || fields.loyaltyAdvModel.servicesInfo.servicehighlightmodel?.serviceHighlightList == null || fields.loyaltyAdvModel.servicesInfo.servicehighlightmodel?.serviceHighlightList.length == 0))) ?
                        (
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="picto-wrap emptystate"><i
                                className={"picto " + (fields.loyaltyAdvModel.servicesEmptyPicto)}></i>
                                <p className="text-uppercase text-white">{fields.loyaltyAdvModel.servicesEmptyText}</p>
                              </div>

                            </div>
                          </div>
                        ) :
                        (
                          <Service fields={fields.loyaltyAdvModel} />
                        )
                      }
                    </div>
                    <div role="tabpanel" className="tab-pane" id="lcAdvShopsTab">
                      {fields.loyaltyAdvModel.shopOffersInfo == null || fields.loyaltyAdvModel.shopOffersInfo?.storeOffers == null || fields.loyaltyAdvModel.shopOffersInfo?.items?.length == 0 ?
                        (
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="picto-wrap emptystate">
                                <i className={"picto " + (fields.loyaltyAdvModel.offersEmptyPicto)}></i>
                                <p className="text-uppercase text-white">{fields.loyaltyAdvModel.offerEmptyText}</p>
                              </div>
                            </div>
                          </div>
                        ) :
                        (
                          <>
                            <div className="row" id="lcAdvShopsOffers">
                              <ShopLandingOfferInnerPage model={fields.loyaltyAdvModel.shopOffersInfo} landingOfferModel={fields.model} values={visibleShops} isClubPage={fields.loyaltyAdvModel.isClubPage} isMobileView={fields.loyaltyAdvModel.isMobileView} />
                            </div>

                            {fields.loyaltyAdvModel.shopOffersInfo.storeTotalOffersCount > visibleShops &&
                              <div className="loyalty-buttom-content-button d-flex justify-content-center" data-testid='testclick4' onClick={(e) => loyaltyOffersShopsVM(e.currentTarget)}>
                                <a className="btn btn-white loyalty-program-member">{fields.loyaltyAdvModel?.viewMore}</a>
                              </div>
                            }
                            <div className="more-info">
                              {fields.loyaltyAdvModel.cezOffer} 
                              {/* <a aria-label={fields?.loyaltyAdvModel?.termsOfUsePage} href={fields.loyaltyAdvModel.urlPrefix + fields.loyaltyAdvModel.slash + fields.loyaltyAdvModel.termsOfUsePage}>{fields.loyaltyAdvModel.conditionsParticulieres}</a> */}
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div role="tabpanel" className="tab-pane" id="lcAdvRestaurantsTab">
                      {fields.loyaltyAdvModel.restaurantOffersInfo == null || fields.loyaltyAdvModel.restaurantOffersInfo.items == null || fields.loyaltyAdvModel.restaurantOffersInfo.items.length == 0 ?
                        (
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="picto-wrap emptystate">
                                <i className={"picto " + (fields.loyaltyAdvModel.offersEmptyPicto)}></i>
                                <p className="text-uppercase text-white">{fields.loyaltyAdvModel.offerEmptyText}</p>
                              </div>
                            </div>
                          </div>
                        ) :
                        (
                          <>
                            <div className="row" id="lcAdvRestaurantOffers">
                              <ShopLandingOfferInnerPage model={fields.loyaltyAdvModel.restaurantOffersInfo} landingOfferModel={fields.model} values={visibleRestaurants} isClubPage={fields.loyaltyAdvModel.isClubPage} isMobileView={fields.loyaltyAdvModel.isMobileView} />
                            </div>
                            {fields.loyaltyAdvModel.restaurantOffersInfo.restaurantTotalOffersCount > visibleRestaurants &&
                              <div className="loyalty-buttom-content-button d-flex justify-content-center" data-testid='testclick5' onClick={(e) => loyaltyOffersRestaurantVM(e.currentTarget)}>
                                <a className="btn btn-white loyalty-program-member">{fields.loyaltyAdvModel?.viewMore}</a>
                              </div>
                            }
                            <div className="more-info">
                              {fields.loyaltyAdvModel.cezOffer} 
                              {/* <a aria-label={fields?.loyaltyAdvModel?.termsOfUsePage} href={fields.loyaltyAdvModel.urlPrefix + fields.loyaltyAdvModel.slash + fields.loyaltyAdvModel.termsOfUsePage}>{fields.loyaltyAdvModel.conditionsParticulieres}</a> */}
                            </div>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  else {
    return <></>
  }
}

function setme(e) {
  var thisnode = e.currentTarget;
  var thisli = e.currentTarget.getAttribute('href');
  if (document.getElementsByClassName('makethisactive').length > 0) {
    document.querySelectorAll(".makethisactive").forEach(x => { x.classList.remove("active") })

  }
  thisnode.parentElement.classList.remove("active");
  var currentHref = thisli.split("#")[1];
  if (!thisli.includes("webview=1")) {
    window.history.pushState('', document.title, window.location.pathname + '#Tab=' + currentHref);
  }
  else {
    window.history.pushState('', document.title, window.location.pathname + "?webview=1" + '#Tab=' + currentHref);
  }
}
