import React from "react";
export default function RebrandedLoyaltyLogo({ fields }) {
    if (fields !== undefined && fields !== "" && fields !== null && fields.model?.isNewBrandedsite && fields.model?.loyaltyLogo != null && fields.model?.loyaltyLogo?.value?.src != null && fields.model?.loyaltyLogo?.value?.src != "") {
        return (
            <div className={"anchor-navigation lpv2-v1 " + (fields.isLoyaltyPage ? "anchor-navigation-loyalty-bg" : "")}>
                <nav className="container">
                    <img className="img-fluid" src={fields.model.loyaltyLogo.value?.src} alt={fields.model.loyaltyLogo.value?.alt} />
                </nav>
            </div>
        )

    }
    else {
        return (<></>)
    }
}