import { Text } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import { baseModel } from '../../RouteHandler';
import { fnRedirectToConnectLogin } from "../Js-CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setVisitorCommunityName } from "../../redux/user/actions";

export default function LoyaltyBanner({ fields }) {
  const communityGenericName = useSelector((state) => state.user?.communityName);
  const dispatch = useDispatch();

  if (fields !== undefined && fields !== "" && fields !== null && fields.model !== undefined && fields.model !== "" && fields.model !== null &&
    fields.model.loyaltyBannerContent !== null && fields.model.loyaltyBannerContent !== undefined && fields.model.loyaltyBannerContent !== "") {
    if (communityGenericName !== fields.model.communityGenericName)
      dispatch(setVisitorCommunityName(fields.model.communityGenericName));
    return (
      <div className="loyalty-wrapper-block">
        <div className="loyalty-wrapper-wrap-wc d-block position-relative">
          <div className="position-relative" id="loyalty-banner" data-communityname={fields.model.communityName}
            data-communitydescription={fields.model.communityDescription}>
            {(fields.model.communityName !== null && fields.model.communityName !== undefined &&
              fields.model.communityName !== "") ?
              <picture>
                {fields.model.mobileCommunityImage !== null && fields.model.mobileCommunityImage !== ""
                  && fields.model.mobileCommunityImage !== undefined &&
                  <source media="(max-width: 799px)" srcSet={fields.model.mobileCommunityImage} />
                }
                {fields.model.enableLazyLoad && fields.model.desktopCommunityImage !== null && fields.model.desktopCommunityImage !== ""
                  && fields.model.desktopCommunityImage !== undefined &&
                  <img src={fields.model.desktopCommunityImage} className="loyalty-program-bgimage"
                    loading="lazy" alt="header-banner" height="580" width="1440">
                  </img>
                }
                {!fields.model.enableLazyLoad && fields.model.desktopCommunityImage !== null && fields.model.desktopCommunityImage !== ""
                  && fields.model.desktopCommunityImage !== undefined &&
                  <img src={fields.model.desktopCommunityImage} className="loyalty-program-bgimage"
                    alt="header-banner" height="580" width="1440">
                  </img>
                }
              </picture>
              :
              <picture>
                {fields.model.loyaltyBannerContent?.mobileImage?.value?.src !== null && fields.model.loyaltyBannerContent?.mobileImage?.value?.src !== ""
                  && fields.model.loyaltyBannerContent?.mobileImage?.value?.src !== undefined &&
                  <source media="(max-width: 799px)" srcSet={fields.model.loyaltyBannerContent?.mobileImage?.value?.src} />
                }
                {fields.model.enableLazyLoad && fields.model.loyaltyBannerContent?.image?.value?.src !== null && fields.model.loyaltyBannerContent?.image?.value?.src !== ""
                  && fields.model.loyaltyBannerContent?.image?.value?.src !== undefined &&
                  <img src={fields.model.loyaltyBannerContent.image?.value?.src} className="loyalty-program-bgimage"
                    loading="lazy" alt="header-banner">
                  </img>
                }
                {!fields.model.enableLazyLoad && fields.model.loyaltyBannerContent?.image?.value?.src !== null && fields.model.loyaltyBannerContent?.image?.value?.src !== ""
                  && fields.model.loyaltyBannerContent?.image?.value?.src !== undefined &&
                  <img src={fields.model.loyaltyBannerContent.image?.value?.src} className="loyalty-program-bgimage"
                    alt="header-banner">
                  </img>
                }
              </picture>
            }
          </div>
          <div className="content d-flex flex-column">
            <div className="d-flex loyalty-program-logo align-items-start">
              <div className={"d-flex flex-column align-items-center" + (fields.model.isAuthenticated ? " loyalty-program-header-content" : "")} id="loyalty-program-header-content">
                <img src={fields.model.loyaltyBannerContent?.logo?.value?.src}
                  loading="lazy" className="img-fluid loyalty-program-logo-width " alt="westfield-club">
                </img>
                <div className="loyalty-program-logo-content"><Text field={fields.model.loyaltyBannerContent?.title} /></div>
              </div>
            </div>
            <div className="d-flex container loyalty-program-buttons">
              <div className="col-md-2 d-flex justify-content-center">
              </div>
              {fields.model.isAuthenticated ? (
                <>
                  {!fields.model.isRegisterButton &&
                    <div className="col-md-4 col-12 d-flex justify-content-center loyalty-program-width-button mb-4 mx-lg-2">
                      <a className="btn btn-white loyalty-program-member" href={fields.model.loyaltyBannerContent?.register_Button?.value?.url}>
                        {fields.model.loyaltyBannerContent?.register_Button?.value?.text}</a>
                    </div>
                  }
                  {!fields.model.isLoginButton &&
                    <div className="col-md-4 col-12 d-flex justify-content-center loyalty-program-width-button mb-4 mx-lg-2">
                      <a className="btn btn-white loyalty-program-member" href={fields.model.loyaltyBannerContent?.login_Button?.value?.url}>
                        {fields.model.loyaltyBannerContent.login_Button?.value?.text}</a>
                    </div>
                  }
                </>
              ) : (
                <>
                  {(!fields.model.isRegisterButton) ?
                    (<div className="col-md-4 col-12 d-flex justify-content-center loyalty-program-width-button mb-4 mx-lg-2">
                      <a className="btn btn-white loyalty-program-member" href={fields.model.loyaltyBannerContent?.register_Button?.value?.url}>
                        {fields.model.loyaltyBannerContent?.register_Button?.value?.text}</a>
                    </div>)
                    : (
                      <div className="col-md-4 col-12 d-flex justify-content-center loyalty-program-width-button mb-4 mx-lg-2">
                        <a className="btn btn-white loyalty-program-member" data-testid="registerbutton" onClick={() => fnRedirectToConnectLogin(baseModel.connectRegisterUrl)}>
                          {fields.model.loyaltyBannerContent?.register_Button?.value?.text}</a>
                      </div>
                    )
                  }
                  {(!fields.model.isLoginButton) ?
                    (<div className="col-md-4 col-12 d-flex justify-content-center loyalty-program-width-button mb-4 mx-lg-2">
                      <a className="btn btn-white loyalty-program-member" href={fields.model.loyaltyBannerContent?.login_Button?.value?.url}
                      >{fields.model.loyaltyBannerContent?.login_Button?.value?.text}</a>
                    </div>)
                    : (
                      <div className="col-md-4 col-12 d-flex justify-content-center loyalty-program-width-button mb-4 mx-lg-2">
                        <a className="btn btn-white loyalty-program-member" data-testid="loginbutton" onClick={() => fnRedirectToConnectLogin(baseModel.connectRedirectUrl)}
                        >{fields.model.loyaltyBannerContent?.login_Button?.value?.text}</a>
                      </div>
                    )
                  }
                </>
              )}
              <div className="col-md-2 d-flex justify-content-center">
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (<></>)
  }
}
