import React, { useEffect } from 'react';
import { baseModel } from '../../RouteHandler';
import { fnRedirectToConnectLogin, readCookie } from '../Js-CommonFunctions';


export default function SmartParking({ fields }) {
  useEffect(() => {
    var id = readCookie("UUID");
    if (id === null || id === undefined || id === "") {
      fnRedirectToConnectLogin(baseModel.connectRedirectUrl);
    }
  }, []);
  if (fields?.iframeUrl !== null) {

    return (
      <>
        <div className="container">
          <iframe id="parkingIframe" src={fields.iframeUrl} className="col-12" frameBorder="0" title="cardIframe" style={{ height: "1200px" }}></iframe>
        </div>
      </>
    )
  }
  else {
    return <></>;
  }

}
