import React, { useEffect } from 'react';
import { LoadQueueManagement } from '../Js-CommonFunctions';



export default function QueueManagementRetailers({ fields }) {
  useEffect(() => {
    if (fields.queue !== null && fields.queue !== undefined && fields.queue !== "") {
      LoadQueueManagement();
    }
  }, []);

  if (fields.queue !== null && fields.queue !== undefined && fields.queue !== "") {

    return (
      <section id="queuemanagementretailers" data-loadcontentasync="true" className="filter-area">
        <div className="loadersmall" id="loaderQueueDiv"></div>
   </section>
    )
  }
  else {
    return <></>;
  }
}
