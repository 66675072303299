import React from "react";
import Slider from "react-slick";
import { baseModel } from "../../RouteHandler";
import { lazyLoadingImg, URWindowLocation } from '../Js-CommonFunctions.js';



// export default RelatedMovies;

export default function RelatedMovies({ fields }) {
  var totalSlidesCount = fields.relatedItems.items.length;
  var settings = {
    customPaging: function (i) {
      return (
        <button type="button" className="bg-main" data-role="none" role="button" tabIndex="0" />
      );
    },
    arrows: true,
    dots: true,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    loop: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    slidesToShowTablet: 3,
    slidesToShowMobile: 2,
    afterChange: () => { lazyLoadingImg(); },
    responsive: [
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 768,
        //tab
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },

      {
        breakpoint: 480,
        //mobile
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  {
    var indexcount = 0;
    var Model = fields.relatedItems;
    let temp = { value: '' };
    if (Model.items.length > 1) {
      return (
        <section className="related-movie" id="related">
          <div className="container">
            <h2 className="content-title text-uppercase text-center underline">{Model.contentTitle}</h2>
            <Slider {...settings} className="row carousel-related-movie  " id="FilmResult">
              {Model.items.map((items, index) => {
                indexcount++;
                return (
                  <div key={index} id={(" relatedMoviesCarousel" + indexcount)} className="col-md-3 col-sm-4 col-6 col brand-block analytics_relatedmoviedisplay gtmCarouselView">
                    <div className="wrapper clearfix">
                      <div className="content-wrap">
                        <div className="img-wrap">
                          <div className="vertical-align ">
                            {(items.imageposter !== "") ?
                              (
                                <img src={Model.defaultImage} data-alt={items.imageposter} alt={items.filmTitle} title={items.filmTitle} />
                              ) : (

                                <img src="#" alt={items.filmTitle} title={items.filmTitle} />
                              )
                            }
                          </div>
                        </div>
                        <div className="action">
                          <div className="btn-wrap">
                            <a href="#" aria-label={Model?.discover} data-testid="fnSelectPreferredSCclick" onClick={(e) => Discoverlink(Model.movieItemLink + Model.slash + items.onClickEvent)} id={items.httpId} className="link-button text-uppercase analytics_relatedmovievoirclk gtmClickNavigation" >{Model.discover}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              }
            </Slider>

          </div>
        </section >
      )
    }
    else {
      return <></>;
    }
  }
}
export function Discoverlink(ele) {
  URWindowLocation(baseModel.siteUrlPrefix + ele);
  return false;

}

