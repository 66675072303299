import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from "react";
import { baseModel } from '../../RouteHandler.js';
import { checkWebViewEqualsOne, loyaltyProgramSubscriptionDataPush, readCookie } from '../Js-CommonFunctions';

export default function LoyaltyBlock({ fields }) {
    var hasWebviewEqualsOne = checkWebViewEqualsOne();

    useEffect(() => {

        if (!hasWebviewEqualsOne && fields !== null && fields !== undefined && fields !== "" && fields.model !== undefined && fields.model !== null && fields.model !== "") {
            fields.model.signUpLink.value.href = baseModel?.connectRegisterUrl;
            fields.model.signUpLink.value.url = baseModel?.connectRegisterUrl;
        }
    }, []);
    var id = readCookie("UUID");

    if (!hasWebviewEqualsOne && fields !== null && fields !== undefined && fields !== "" && fields.model !== undefined && fields.model !== null && fields.model !== "" && (id === null || id === undefined || id === "")) {
        return (
            <section id="loyaltyblock">
                {fields.model.isNewBrandedsite ?
                    (
                        <div className="container cta-block">
                            <div className="link-block">
                                <p>
                                    <Text field={fields.model.content[fields.model.alreadyHaveAccount]} />
                                    <a className="signin_popup gtmCommonVariablesEvent" href={baseModel.connectRedirectUrl}>
                                        {fields.model.connection}
                                    </a>
                                </p>

                            </div>
                            <div className="text-btn">
                                <Link field={fields.model.signUpLink} onClick={(e) => { loyaltyProgramSubscriptionDataPush("1") }} className="link-button btn-dark text-uppercase gtmServiceNavigation" />
                            </div>
                        </div>
                    ) :
                    (
                        <div className="container">
                            <div className="cta-block">
                                <div className="link-block">
                                    <p>
                                        <Text field={fields.model.content[fields.model.alreadyHaveAccount]} />
                                        <a className="signin_popup gtmCommonVariablesEvent" href={baseModel.connectRedirectUrl} >
                                            {fields.model.connection}
                                        </a>
                                    </p>

                                </div>
                                <div className="text-btn">
                                    <Link field={fields.model.signUpLink} onClick={(e) => { loyaltyProgramSubscriptionDataPush("1") }} className="link-button btn-dark text-uppercase gtmServiceNavigation" />
                                </div>
                            </div>
                        </div>
                    )
                }

            </section>
        );
    }
    else {
        return <></>;
    }
}