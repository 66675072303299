import React from "react";
import { baseModel } from "../../RouteHandler";
import store from "../../redux/store";
import { URWindowLocation, fnConnectRedirectTodetailPage, fnGTMClickServiceOffer, fnRedirectToConnectLogin, openLoyaltyCardBarCodePopin } from "../Js-CommonFunctions";

export default function HowToEnjoyIt({ model ,fields}) {
  const state = store.getState();
  const visitorId = state?.user?.visitorId;
  const barCode = state?.user?.barCode;

  if (model != null) {
   var buttonLink = fields.serviceDetail.serviceInfo.serviceLink !== null && fields.serviceDetail.serviceInfo.serviceLink !== "" ?  fields.serviceDetail.serviceInfo.serviceLink : model.buttonLink;
    var buttonText = fields.serviceDetail.serviceInfo.serviceUrlTitle !== null && fields.serviceDetail.serviceInfo.serviceUrlTitle !== "" ? fields.serviceDetail.serviceInfo.serviceUrlTitle : model.buttonText;

    return (
        <div className="enjoy-cta-block px-4 py-2 text-start bg-gray border-8">
          <div className="title text-uppercase easyparking-header text-start mb-3 mt-3 mb-lg-3">{model.title}</div>
          <div className="description text-start text-midgray mb-4">
            {model.description}
          </div>
          {(visitorId !== null && visitorId !== undefined && visitorId !== "") ?
            (
              <> <button aria-label={model?.title} className="d-lg-none easy-parking-content-button btn btn-dark mb-3" data-testid="checkServiceBookingBugUrl" onClick={(e) =>{fnGTMClickServiceOffer(buttonText); checkServiceBookingBugUrl(buttonLink, barCode)} }>{buttonText}</button>
                <a href={buttonLink} aria-label={model?.title} className="d-none d-lg-inline-block easy-parking-content-button btn btn-dark mb-3" onClick={(e) =>fnGTMClickServiceOffer(buttonText)} >{buttonText}</a>
              </>
            ) :
            (
              <> <button  className="d-lg-none easy-parking-content-button btn btn-dark mb-3" id="service_bookingbug_cta_mobile" data-testid='bookingbugcta_mobile' onClick={(e) => {fnGTMClickServiceOffer(buttonText); fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }}>{buttonText}</button>
                <button  className="d-none d-lg-inline-block easy-parking-content-button btn btn-dark mb-3" id="service_bookingbug_cta" data-testid="bookingbugcta" onClick={(e) => { fnGTMClickServiceOffer(buttonText);fnConnectRedirectTodetailPage(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }}>{buttonText}</button>
              </>
            )
          }
        </div>
    )
  } else {
    return <></>;
  }
}


function checkServiceBookingBugUrl(url, barcode) {

  if (url != null && url !== "") {
    var url_account = url.substring(url.lastIndexOf('/'));
    if (url_account === baseModel.profilePage) {
      openLoyaltyCardBarCodePopin(barcode);
    } else {
      URWindowLocation(url);
    }
  }
}
