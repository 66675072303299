import React from "react";
import OffersAndnewsInnerPage from '../OffersAndNewsInnerPage';

export default function OffersAndNews({ fields }) {

  if (fields && fields.offerNewMV !== null) {
    return (
      <>
        <OffersAndnewsInnerPage newsData={fields.offerNewMV} />
      </>
    );
  }
  else {
    return (
      <></>
    )
  }
}
