import { Text } from '@sitecore-jss/sitecore-jss-react';
import axios from "axios";
import React, { useEffect } from 'react';
import store from '../../redux/store.js';
import { GetRenewedAccessToken, isTokenExpired, RemoveFavCenterDatalayer } from "../Js-CommonFunctions.js";

export default function VisitorCenterDeletePopin({ model, pcCode, removePreferredCenter, isFromUnsubscribe }) {

  useEffect(() => {
    var ele = document.getElementById("popinVisitorCenterDelete");
    if (ele !== null && ele !== undefined) {
      var popinClick = document.getElementById("popinVisitorCenterDeleteClick");
      popinClick.click();
    }
  }, []);

  function DeleteVisitorCenter(pcCode) {
    if (isTokenExpired()) {
      var args = [pcCode];
      GetRenewedAccessToken(RemoveVisitorCenter, args, false);
    } else {
      RemoveVisitorCenter(pcCode);
    }
  }

  function RemoveVisitorCenter(pcCode) {

    const state = store.getState();
    const visitorId = state.user.visitorId;
    const accessToken = state.user.accessToken;
    const refreshToken = state.user.refreshToken;

    if (isFromUnsubscribe !== null && isFromUnsubscribe !== undefined && isFromUnsubscribe !== "" && isFromUnsubscribe === true) {
      removePreferredCenter(pcCode);
    } else {
      axios({
        method: "post",
        url: "/api/sitecore/VisitorApi/DeleteVisitorCenter",
        data: {
          pcCode: pcCode,
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken
        },
        async: false,
      })
        .then(function (response) {
          if (response.data[0] == true) {
            removePreferredCenter(pcCode);
            if (response.data[2] !== null && response.data[2] !== undefined && response.data[2] !== "") {
              RemoveFavCenterDatalayer(response.data[2]);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  if (model != undefined && model != null && model != "") {
    return (
      <div className="modal-dialog  modal-popin-trends disassociate-my-bank-account" id="modalVisitorCenterDelete">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="picto icon-close-circle-rw border-0 fs-4 bg-white text-black" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body text-center">
            <div className="popin-wrap">
              {(model.title !== "" && model.title !== null) &&
                <Text className="msg-title text-uppercase" field={model.title} tag="h3" />
              }
              <div className="msg-text">{model.content}</div>
              <a className="link-button btn-dark center-block" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);">{model.no}</a>
              <a className="link-button btn-white center-block" data-bs-dismiss="modal" aria-label={model?.yes} data-testid="DeleteVisitorCenter" onClick={(e) => DeleteVisitorCenter(pcCode)} href="javascript:void(0);">{model.yes}</a>
              <a className="center-block link-text account-profile-delete text-underline" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);">{model.cancel}</a>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
