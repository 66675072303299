import axios from "axios";
import React, { useEffect, useState } from "react";
import store from "../../redux/store";
import { GetRenewedAccessToken, closeVehicleErrorPopin, isTokenExpired } from "../Js-CommonFunctions";
import { updatePartnerDetail } from "../MyAccount/MyAccountScripts";

export default function AddVisitorVehiclePopin({ fields, model, addvehicle, editvehicle, addVehicleData, removeVehicleData, isParkingCall }) {

  const [btnCompanySubmit, setBtnCompanySubmit] = useState(true);

  useEffect(() => {
    var ele = document.getElementById("popinAddVehicle");
    if (ele !== null && ele !== undefined) {
      var popinClick = document.getElementById("popinAddVehicleClick");
      popinClick.click();
    }
  }, []);

  function SaveVehiclenfo(isUpdate) {
    if (isTokenExpired()) {
      var args = [isUpdate];
      GetRenewedAccessToken(SaveVehicle, args, false);
    } else {
      SaveVehicle(isUpdate);
    }
  }

  function SaveVehicle(isUpdate) {
    var numberplate = (isUpdate ? model.licensePlate : document.getElementById("numberplate").value.toString());
    var nickname = document.getElementById("vehiclenickname").value.toString();
    if (numberplate != null && numberplate != "") {
      var url = "/api/sitecore/VisitorApi/CreateVehicle";
      const state = store.getState();
      const visitorId = state.user.visitorId;
      const accessToken = state.user.accessToken;
      const refreshToken = state.user.refreshToken;

      axios({
        method: "post",
        url: url,
        data: {
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken,
          licensePlate: numberplate,
          vehicleNickName: nickname
        },
        async: false,
      })
        .then(function (response) {
          if (response.data[0]) {
            if (isParkingCall) {
              updatePartnerDetail(fields.pcCode, fields.parkingPartner,
                (fields.accountUrl?.link?.value?.url + "?ShowSuccessPopin=easy-parking&pcCode=" + fields.pcCode))

              setTimeout(() => {
                setBtnCompanySubmit(true);
                //to close popin
                var popinClick = document.getElementById("popinAddVehicleClick");
                popinClick.click();
                SetDefaultValues();
              }, 15000)
            }
            else {
              addVehicleData(numberplate, nickname, editvehicle);
              setBtnCompanySubmit(true);
              //to close popin
              var popinClick = document.getElementById("popinAddVehicleClick");
              popinClick.click();
              SetDefaultValues();
            }
            closeVehicleErrorPopin();
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      return true;
    }
  }

  function SetDefaultValues() {
    if (document.getElementById("numberplate-error")) { document.getElementById("numberplate-error").innerHTML = ""; }
    if (document.getElementById("numberplate") && document.getElementById("numberplate").classList.contains("error")) { document.getElementById("numberplate").classList.remove("error"); }
    if (document.getElementById("confirmnumberplate-error")) { document.getElementById("confirmnumberplate-error").innerHTML = ""; }
    if (document.getElementById("confirmnumberplate") && document.getElementById("confirmnumberplate").classList.contains("error")) { document.getElementById("confirmnumberplate").classList.remove("error"); }
    if (document.getElementById("numberplate")) { document.getElementById("numberplate").value = ""; }
    if (document.getElementById("confirmnumberplate")) { document.getElementById("confirmnumberplate").value = ""; }
    if (document.getElementById("vehiclenickname")) { document.getElementById("vehiclenickname").value = ""; }
  }

  function RemoveVehicleInfo() {
    if (isTokenExpired()) {
      var args = [];
      GetRenewedAccessToken(RemoveVehicle, args, false);
    } else {
      RemoveVehicle();
    }
  }

  function RemoveVehicle() {
    var numberplate = model.licensePlate;
    if (numberplate != null && numberplate != "") {
      var url = "/api/sitecore/VisitorApi/DeleteVehicle";

      const state = store.getState();
      const visitorId = state.user.visitorId;
      const accessToken = state.user.accessToken;
      const refreshToken = state.user.refreshToken;

      axios({
        method: "post",
        url: url,
        data: {
          licensePlate: numberplate,
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken
        },
        async: false,
      })
        .then(function (response) {
          if (response.data[0]) {
            removeVehicleData(numberplate);
            setBtnCompanySubmit(true);
            //to close popin
            var popinClick = document.getElementById("popinAddVehicleClick");
            popinClick.click();
            SetDefaultValues();
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      return true;
    }
  }

  function NumberplateValidation() {
    var numberplate = document.getElementById("numberplate").value.trim();
    if (numberplate === "") {
      document.getElementById("numberplate-error").innerHTML = document.getElementById("numberplate-error").getAttribute("data-validatormsg1").valueOf();
      document.getElementById("numberplate-error").style.display = "block";
      document.getElementById("numberplate").classList.add("error");
      return false;
    }
    else {
      document.getElementById("numberplate-error").innerHTML = "";
      document.getElementById("numberplate").classList.remove("error");
      return true;
    }
  }

  function ConfirmNumberplateValidation() {
    var numberplate = document.getElementById("confirmnumberplate").value.trim();
    if (numberplate === "") {
      document.getElementById("confirmnumberplate-error").innerHTML = document.getElementById("confirmnumberplate-error").getAttribute("data-validatormsg1").valueOf();
      document.getElementById("confirmnumberplate-error").style.display = "block";
      document.getElementById("confirmnumberplate").classList.add("error");
      return false;
    }
    else {
      document.getElementById("confirmnumberplate-error").innerHTML = "";
      document.getElementById("confirmnumberplate").classList.remove("error");
      return true;
    }
  }

  function licenseplateCheck() {
    var licensePlate = document.getElementById("numberplate").value.toString();
    var confirmLicensePlate = document.getElementById("confirmnumberplate").value.toString();
    if (confirmLicensePlate != null && confirmLicensePlate != "") {
      if (licensePlate != null && licensePlate != "" && confirmLicensePlate != null && confirmLicensePlate != "" && licensePlate === confirmLicensePlate) {
        document.getElementById("confirmnumberplate-error").innerHTML = "";
        document.getElementById("confirmnumberplate").classList.remove("error");
        return true;
      }
      else {
        document.getElementById("confirmnumberplate-error").innerHTML = document.getElementById("confirmnumberplate-error").getAttribute("data-validatormsg2").valueOf();
        document.getElementById("confirmnumberplate-error").style.display = "block";
        document.getElementById("confirmnumberplate").classList.add("error");
        return false;
      }
    }
    return false;
  }

  function numberplateValidationCheck() {
    var numberPlate = NumberplateValidation();
    var confirmPlate = ConfirmNumberplateValidation();
    var licenseplate = licenseplateCheck();
    if (numberPlate && confirmPlate && licenseplate) {
      SaveVehiclenfo(false);
    }
  }

  if (fields != undefined && fields != null && fields != "" && fields.vehicleInfo != undefined && fields.vehicleInfo != null && fields.vehicleInfo != "") {
    return (
      <div className="modal-dialog  modal-popin-trends licenseplate-popin" id="licenseplate-popin">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="picto icon-close-circle-rw border-0 fs-3 bg-white text-black" data-bs-dismiss="modal" aria-label="Close" data-testid="setdefaultvalues1" onClick={(e) => { SetDefaultValues() }}></button>
          </div>
          <div className="modal-body text-center">
            <div className="popin-wrap">
              <form method="post" encType="multipart/form-data" id="vehicleInformation" name="vehicleInformation" autoComplete="off">
                {addvehicle === true && <>
                  {isParkingCall &&
                    <>
                      <div className={"picto d-inline-block d-flex align-items-center text-center justify-content-center fs-1 mb-1  " + (fields.vehicleInfo.parkingPicto)}></div>
                      <div className="title text-uppercase mb-2 mb-lg-3 numberplate-title">{fields.vehicleInfo.activateInAndOutTitle}</div>
                      <div className="numberplate-subtitle">{fields.vehicleInfo.activateInAndOutDescription}</div>
                    </>}
                  {!isParkingCall &&
                    <div className="numberplate-title">{fields.vehicleInfo.addVehicleTitle}</div>}
                  <div>
                    <div className="col-md-12 col-sm-12 col-12 p-0">
                      <div className="form-group">
                        <label htmlFor="numberplate" className="numberplate-label">{fields.vehicleInfo.numberPlate}</label>
                        <input className="form-control rounded-1 numberplate-input rounded-1 mt-1" type="text"
                          name="numberplate" id="numberplate" defaultValue="" data-testid="validation"
                          onBlur={(e) => { NumberplateValidation(); }}
                          onFocus={(e) => { setBtnCompanySubmit(false); }} />

                        <span id="numberplate-error" className="error" data-validatormsg1={fields.numberplate_Validation_Error1}></span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12 col-sm-12 col-12 p-0">
                      <div className="form-group">
                        <label htmlFor="confirmnumberplate" className="numberplate-label">{fields.vehicleInfo.confirmNumberPlate}</label>
                        <input className="numberplate-input form-control rounded-1 mt-1" type="text"
                          name="confirmnumberplate" id="confirmnumberplate" defaultValue=""
                          data-testid="ConfirmNumberplateValidation"
                          onBlur={(e) => { ConfirmNumberplateValidation(); licenseplateCheck(); }}
                          onFocus={(e) => { setBtnCompanySubmit(false); }} />

                        <span id="confirmnumberplate-error" className="error" data-validatormsg1={fields.numberplate_Validation_Error1}
                          data-validatormsg2={fields.numberplate_Validation_Error2}></span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12 col-sm-12 col-12 p-0">
                      <div className="form-group">
                        <label htmlFor="vehiclenickname" className="numberplate-label">{fields.vehicleInfo.vehicleNickName}</label>
                        <input className="numberplate-input form-control rounded-1 mt-1" type="text"
                          name="vehiclenickname" id="vehiclenickname" defaultValue=""
                          data-testid="setBtnCompanySubmit1"
                          onFocus={(e) => { setBtnCompanySubmit(false); }} />
                      </div>
                    </div>
                  </div>
                  <a className="btn-numberplate-add link-button btn-dark center-block" aria-label="Close" href="javascript:void(0);" disabled={btnCompanySubmit} data-testid="numberplatevalidation" onClick={(e) => { numberplateValidationCheck(); }}>{fields.vehicleInfo.saveChangesCTA}</a>
                  <a className="btn-numberplate-cancel center-block link-text account-profile-delete text-underline" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);" data-testid="setdefaultvalues2" onClick={(e) => { SetDefaultValues() }}>{fields.cancel_CTA}</a></>
                }
                {editvehicle === true &&
                  <>
                    <div className="numberplate-title">{fields.vehicleInfo.yourVehicleTitle}</div>
                    <div >
                      <div className="col-md-12 col-sm-12 col-12 p-0">
                        <div className="form-group">
                          <div className="numberplate-text">{model.licensePlate}</div>
                        </div>
                      </div>
                    </div>
                    <div >
                      <div className="col-md-12 col-sm-12 col-12 p-0">
                        <div className="form-group">
                          <label htmlFor="vehiclenickname" className="numberplate-label">{fields.vehicleInfo.vehicleNickName}</label>
                          {model.name != null && model.name != "" ?
                            <input className="numberplate-input form-control rounded-1 mt-1" type="text"
                              name="vehiclenickname" id="vehiclenickname" defaultValue={model.name}
                              data-testid="setBtnCompanySubmit2"
                              onFocus={(e) => { setBtnCompanySubmit(false); }} /> :
                            <input className="numberplate-input form-control rounded-1 mt-1" type="text"
                              name="vehiclenickname" id="vehiclenickname" defaultValue=""
                              data-testid="setBtnCompanySubmit3"
                              onFocus={(e) => { setBtnCompanySubmit(false); }} />
                          }
                          <span id="vehiclenickname-error" className="error" ></span>
                        </div>
                      </div>
                    </div>
                    <a className="btn-numberplate-add link-button btn-dark center-block" aria-label="Close" href="javascript:void(0);" disabled={btnCompanySubmit} data-testid="SaveVehiclenfo" onClick={(e) => { if (typeof SaveVehiclenfo != "undefined") { return SaveVehiclenfo(true); } }}>{fields.vehicleInfo.saveChangesCTA}</a>
                    <a className="btn-numberplate-remove link-button btn-white center-block" aria-label={fields.vehicleInfo?.removeVehicleCTA} href="javascript:void(0);" data-testid="RemoveVehicleInfo" onClick={(e) => { RemoveVehicleInfo() }}>{fields.vehicleInfo.removeVehicleCTA}</a>
                    <a className="btn-numberplate-cancel center-block link-text text-underline" data-bs-dismiss="modal" aria-label="Close" href="javascript:void(0);" data-testid="setdefaultvalues3" onClick={(e) => { SetDefaultValues() }}>{fields.cancel_CTA}</a></>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return <></>;
  }
}
