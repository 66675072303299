import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseModel } from '../../RouteHandler';
import GlobalBurgerMenu from '../GlobalBurgerMenu';
import {
    HeaderScripts,
    bannerToggleGlobal,
    checkWebViewEqualsOne,
    fnRedirectToConnectLogin, fnRedirectToMyAccount,
    gtmFuncClickAccountHeader,
    logoutFromGS,
} from '../Js-CommonFunctions';


export default function GlobalHeader({ fields }) {
    let hasWebviewEqualsOne = checkWebViewEqualsOne();
    let visitorInitials = useSelector((state) => state.user.initials);

    useEffect(() => {
        if (!hasWebviewEqualsOne) {
            var timeoutId;
            var menulist = document.querySelectorAll(".MenuMegaDrop");
            if (menulist !== null && menulist !== undefined && menulist.length > 0) {
                menulist.forEach(function (divEl, ind) {

                    divEl.addEventListener('mouseover', function () {
                        document.querySelectorAll('.MenuMegaDrop.hover')
                            .forEach(element => {
                                element.classList.remove('hover');
                            });
                        divEl.classList.add('hover');
                        if (divEl.childNodes[1])
                            divEl.childNodes[1].classList.add("d-lg-block")
                        if (timeoutId) {
                            clearTimeout(timeoutId);
                        }
                    });
                    divEl.addEventListener('mouseout', function () {
                        timeoutId = setTimeout(function () {
                            divEl.classList.remove('hover');
                            if (divEl.childNodes[1])
                                divEl.childNodes[1].classList.remove("d-lg-block")
                        }, 1000);
                    });
                })

            }
            HeaderScripts();

            var headerLogoClass = document.getElementById("headerlogo");
            if (fields?.globalHeaderItem?.isMobileView) {
                if (visitorInitials === null || visitorInitials === undefined || visitorInitials === "") {
                    if (headerLogoClass !== null && !headerLogoClass?.classList.contains('pe-3'))
                        headerLogoClass.classList.add("pe-3");
                }
                else {
                    if (headerLogoClass !== null && headerLogoClass?.classList.contains('pe-3'))
                        headerLogoClass.classList.remove("pe-3");
                }
            }
            else {
                if (headerLogoClass !== null && !headerLogoClass?.classList.contains('pe-3'))
                    headerLogoClass.classList.add("pe-3");
            }
        }
    }, []);

    if (fields !== null && fields !== undefined && fields.globalHeaderItem !== null && fields.globalHeaderItem !== undefined && fields.globalHeaderItem !== "" && !hasWebviewEqualsOne) {
        var url = fields.globalHeaderItem.headerItem?.logoLink?.length > 1 ?
            fields.globalHeaderItem.headerItem?.logoLink?.replace(/\/$/, '') : fields.globalHeaderItem.headerItem?.logoLink;
        return (
            <header className="header" >
                <div className="preheader bg-white" id="headerBlock">
                    <div className="p-0 ">
                        <nav className="navbar navbar-expand-lg bg-white justify-content-between justify-content-sm-between nav-height collapsed"
                            id="navbarHeader">
                            <a className="picto icon-essentional-rw navbar-toggler d-sm-block d-lg-none text-black fs-4 text-decoration-none  bg-white"
                                data-bs-toggle="collapse" data-bs-target="#navbar" data-bs-auto-close="outside" aria-expanded="true" aria-controls="navbar"
                                href="#navbarHeader" aria-label="Toggle navigation" data-testid="test1" onClick={() => bannerToggleGlobal()}></a>

                            <div id="headerlogo" className={"headerlogo logo d-block d-lg-none ml-auto mr-auto justify-content-center d-flex align-items-center pe-3"}>
                                <a href={url} aria-label={fields.globalHeaderItem.headerItem?.logoLink} className="navbar-brand p-0 m-0 align-items-center justify-content-center">
                                    <img className="d-flex align-items-center" src={fields.globalHeaderItem.headerItem?.logo?.value?.src} alt={fields.globalHeaderItem.headerItem?.logo?.value?.alt} />
                                </a>
                            </div>

                            <ul className="navbar-nav nav-picto flex-row align-items-center d-lg-none pe-3">
                                {(visitorInitials !== null && visitorInitials !== undefined && visitorInitials !== "") ?
                                    (
                                        <>
                                            <li className="collapsed user-mobile-header" data-bs-toggle="collapse" data-bs-target="#accountmenu"
                                                aria-controls="accountmenu" aria-expanded="false" aria-label="Toggle navigation" data-testid="test2" onClick={() => { gtmFuncClickAccountHeader(); fnRedirectToMyAccount(fields.globalHeaderItem.myAccountLink?.link?.value?.url) }}>
                                                <a href={fields.globalHeaderItem.myAccountLink?.link?.value?.url} aria-label={fields.globalHeaderItem.myAccountLink?.link}
                                                    className="text-center fs-3 picto icon-users-rw  text-decoration-none d-inline-block"></a>
                                                <a href={fields.globalHeaderItem.myAccountLink?.link?.value?.url} aria-label={fields.globalHeaderItem.myAccountLink?.link}
                                                    className="text-center user-mobile-header-branded text-decoration-none p-0">{visitorInitials}</a>
                                            </li></>
                                    ) : (
                                        <> <li className="d-flex">
                                            <a href="javascript:void(0);" aria-label={fields?.globalHeaderItem.myAccountLink?.link} data-testid="test3" onClick={() => { gtmFuncClickAccountHeader(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }}
                                                className="text-center fs-5 picto icon-users-rw text-decoration-none d-inline-block"></a>
                                        </li>
                                            <li className="collapsed user-mobile-header" data-bs-toggle="collapse" data-bs-target="#accountmenu"
                                                aria-controls="accountmenu" aria-expanded="false" aria-label="Toggle navigation">
                                                <a href="#" aria-label={fields?.globalHeaderItem.myAccountLink?.link}
                                                    className="text-center  user-mobile-header-branded text-decoration-none p-0"></a>
                                            </li></>
                                    )}
                            </ul>

                            <div className="container-fluid  p-0 d-none d-sm-none d-lg-flex">
                                <div className="col-md-1 col-lg-1 d-block">
                                    <a className="picto icon-essentional-rw navbar-toggler d-block d-sm-block text-black fs-4 text-decoration-none cursor-pointer"
                                        data-bs-toggle="collapse" data-bs-target="#navbar" data-bs-auto-close="outside" aria-expanded="true"
                                        aria-controls="navbar" href="#navbarHeader" aria-label="Toggle navigation" data-testid="test4" onClick={() => bannerToggleGlobal()}></a>
                                </div>
                                <div className="col-0">

                                    <div className="d-flex">

                                        <div className="navbar-header  w-100 w-lg-auto mr-lg-5">
                                            <div className="logo d-none text-center d-lg-block m-0">
                                                <a href={url} aria-label={fields?.globalHeaderItem.headerItem?.logoLink} className="d-flex justify-content-center cursor-default">
                                                    <img src={fields.globalHeaderItem.headerItem?.logo?.value?.src} alt={fields.globalHeaderItem.headerItem?.logo?.value?.alt} className="cursor-pointer" />
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-1 col-lg-1 px-1 bg-white d-none d-sm-block d-flex justify-content-between">
                                    <div className={"bg-national-header text-center d-flex justify-content-end  " + (fields.globalHeaderItem.isMobileView ? "" : "")}>
                                        <div className="navbar-nav nav-pictoheader flex-row float-end px-lg-2 fs-4 d-inline-flex dropdown-login">
                                            {(visitorInitials !== null && visitorInitials !== undefined && visitorInitials !== "") ?
                                                (
                                                    <>
                                                        <a data-cs-mask="true" href="#" aria-label={visitorInitials}
                                                            className="text-center profile-account-item text-decoration-none d-flex align-items-end justify-content-center position-relative"
                                                            data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                                                            aria-expanded="true" data-testid="test7" onClick={(e) => { gtmFuncClickAccountHeader() }}>
                                                            <span className="fs-4 picto icon-users-rw pe-1 text-black text-decoration-none"></span>
                                                            <span className="circle menu-user text-uppercase text-black">{visitorInitials}</span>
                                                        </a>
                                                        <div className="dropdown-menu icon-profile-dropdown mt-0 py-0">
                                                            <a href={fields.globalHeaderItem.myAccountLink?.link?.value?.url} aria-label={fields?.globalHeaderItem.logoutLink?.link} target=""
                                                                className=" text-capitalize dropdown-item">{fields.globalHeaderItem.myAccountLink?.link?.value?.text}</a>
                                                            <a href="javascript:void(0);" aria-label={fields?.globalHeaderItem.logoutLink?.link} data-testid="test5" onClick={(e) => { return logoutFromGS() }}
                                                                className=" text-capitalize dropdown-item">{fields.globalHeaderItem.logoutLink?.link?.value?.text}</a>
                                                        </div>
                                                    </>
                                                ) :
                                                (<li className="px-0 list-unstyled">
                                                    <a href="javascript:void(0);" aria-label={fields?.globalHeaderItem?.myAccountLink?.link} data-testid="test6" onClick={() => { gtmFuncClickAccountHeader(); fnRedirectToConnectLogin(baseModel.connectRedirectUrl) }}
                                                        className="fs-4 picto icon-users-rw pe-1 text-black text-decoration-none"></a>
                                                </li>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <GlobalBurgerMenu fields={fields} visitorInitials={visitorInitials} />
                        </nav>
                        <div className='header-alternatecolor'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
    else {
        return <></>;
    }
}
