import React from 'react';

export default function CaptainWallet({ fields }) {
  if (fields.authenticationModel !== null && fields.authenticationModel !== undefined && fields.authenticationModel !== "") {
    return (
      (fields.authenticationModel.hasCaptainWallet) &&
      <a href={fields.authenticationModel.captainWalletUrl} aria-label={fields.authenticationModel} target="_blank" rel="noreferrer" className={"btn " + (!fields.authenticationModel.isNewBrandedSite && fields.authenticationModel.isLPV2Site ? "btn-loyalty" : "btn-dark") + " my-2 py-1 wallet-cta"}>
        <span className="picto icon-divers-wallet me-1"></span>
        <span>{fields.authenticationModel.captainWalletCTA}</span>
      </a>
    )
  } else {
    return <></>;
  }
}
