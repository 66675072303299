import { RichText } from '@sitecore-jss/sitecore-jss-react';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setBirthday, setEmail, setEncryptedEmail, setFirstName, setInitials, setLastName, setParkingClubActiveUser, setWorkerCenters } from "../../redux/user/actions";
import { GetRenewedAccessToken, fnInitializeVendorGTM, fnRedirectToConnectLogin, isMobile, isTokenExpired, profileUpdateSuccessDataPush } from "../Js-CommonFunctions";
import {
    BirthDateEventsValidation,
    CloseMessage,
    CompanyNameValidation,
    DeleteVisitorConfirmation,
    FirstNameValidation,
    FormAutocompleteLoad,
    FormValidation,
    GoBackFromInterestsInfo,
    GoBackFromProfessionalInfo,
    GoBackFromProfileInfo,
    GoBackFromVehicleInfo,
    GoBackToMenu,
    LastNameValidation,
    TitleMrClick,
    TitleMrsClick,
    TitleNotSpecifiedClick,
    ZipCodeValidation,
    addVehicleClick,
    scrollToError
} from "../MyAccount/MyAccountScripts";

export default function MyPersonalInfo({ fields }) {
    var professionalInfo = [];
    var arrProfessional = [];
    var arrInterests = [];

    const [profileValues, setProfileValues] = useState({});
    const [professionalCenters, setProfessionalCenters] = useState([]);
    const [professionalValues, setProfessionalValues] = useState([]);
    const [btnCompanySubmit, setBtnCompanySubmit] = useState(true);
    const [btnProfileSubmit, setBtnProfileSubmit] = useState(true);
    const [vehicledata, setVehicleData] = useState([]);
    const [interestsdata, setInterestsData] = useState([]);
    const [btnInterestSubmit, setBtnInterestSubmit] = useState(true);
    const [manageVehicle, setManageVehicle] = useState(false);

    const visitorId = useSelector((state) => state.user.visitorId);
    const accessToken = useSelector((state) => state.user.accessToken);
    const refreshToken = useSelector((state) => state.user.refreshToken);
    const email = useSelector((state) => state.user.email);
    var parkingClubActiveUser = useSelector((state) => state.user.parkingClubActiveUser);
    var encryptedEmail = useSelector((state) => state.user.encryptedEmail);

    var visitorInfo = {
        visitorId: visitorId,
        accessToken: accessToken,
        refreshToken: refreshToken
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if (fields !== undefined && fields !== null && fields !== "") {
            if (email !== fields.email) {
                //Google Tag Manager
                fnInitializeVendorGTM(fields.email);
                dispatch(setEmail(fields.email));
                dispatch(setEncryptedEmail(fields.encryptedEmail))
            }
            // Assign Initial Profile Values
            var objProfile = {
                title: fields.title,
                firstName: fields.firstName,
                lastName: fields.lastName,
                birthDate: fields.birthDate,
                zipCode: fields.zipCode
                //phoneNumber: fields.phoneNumber
            };

            setProfileValues(objProfile);

            // Assign Initial Professional Values
            if (fields.professionalInfo != null) {
                setProfessionalCenters(fields.professionalInfo);

                arrProfessional = fields.professionalInfo.filter(item => item.centerCompanyId != null).map(function (obj) {
                    return { centerName: obj.centerName, companyName: obj.centerCompanyName, pcCode: obj.pcCode };
                });

                setProfessionalValues(arrProfessional);
            }

            //Assign Initial Vehicle Values
            if (fields.vehicles != undefined && fields.vehicles != null &&
                fields.vehicles != "") {
                setVehicleData(fields.vehicles);
            }

            //Assign Initial Interests Values
            if (fields.interests !== null && fields.interests !== undefined && fields.interests !== "") {
                setInterestsData(fields.interests)
            }
        }
        BirthDateEventsValidation();

    }, []);

    useEffect(() => {
        if (fields !== undefined && fields !== null && fields !== "" && fields.professionalInfo !== null) {
            var arrProfessional = fields.professionalInfo.filter(item => item.centerCompanyId != null).map(function (obj) {
                return { centerName: obj.centerName, companyName: obj.centerCompanyName, pcCode: obj.pcCode };
            });

            setProfessionalValues(arrProfessional);
        }

        document.querySelectorAll(".centercompanies").forEach((x) => {
            var companyInputId = x.querySelector('input[type="text"]').id;
            var autoCompleteId = x.querySelector('ul').id;
            var hiddenCompaniesId = x.querySelector('input[type="hidden"]').id;
            FormAutocompleteLoad(companyInputId, autoCompleteId, hiddenCompaniesId);
        });

        if (fields !== null && fields !== undefined && fields?.showSuccessPopin !== null && fields?.showSuccessPopin !== "" &&
            fields.showSuccessPopin === "manage-vehicles" && manageVehicle) {
            VehicleSectionEditClick();
            // setManageVehicle(false);
        }


    }, [fields, manageVehicle]);

    useEffect(() => {
        if (document.getElementById("vehicleErrorText") && document.getElementById("vehicleErrorText").classList.contains("d-none")
            && vehicledata.length === 0) {
            document.getElementById("vehicleErrorText").classList.remove("d-none");
        }
        if (vehicledata.length === 0 && parkingClubActiveUser) {
            dispatch(setParkingClubActiveUser(false));
        }
        else if (vehicledata.length > 0 && !parkingClubActiveUser) {
            dispatch(setParkingClubActiveUser(true));
        }

    }, [vehicledata]);

    function putVistorInterests(arrInterests) {
        if (isTokenExpired()) {
            var args = [arrInterests];
            GetRenewedAccessToken(VisitorInterestsUpdate, args);
        } else {
            VisitorInterestsUpdate(arrInterests, visitorId, accessToken, refreshToken);
        }
    }

    function VisitorInterestsUpdate(arrInterests, visitorId, accessToken, refreshToken) {
        if (arrInterests) {
            axios({
                method: "put",
                url: "/api/sitecore/visitorapi/VisitorInterestsUpdate",
                async: false,
                data: {
                    arrInterests: arrInterests,
                    visitorId: visitorId,
                    accessToken: accessToken,
                    refreshToken: refreshToken
                }
            })
                .then(function (response) {
                    if (response.data != null && response.data != "") {
                        setBtnInterestSubmit(true);
                        // document.getElementById("interestsSuccessMsg").classList.remove("d-none");
                        // window.scrollTo({
                        //     top: document.getElementById('interestsSuccessMsg').offsetTop - document.getElementById('interestsSuccessMsg').offsetHeight - 100,
                        //     behavior: "smooth",
                        // });
                    }
                    else {
                        document.getElementById("interestsErrorMsg").classList.remove("d-none");
                        window.scrollTo({
                            top: document.getElementById('interestsErrorMsg').offsetTop - document.getElementById('interestsErrorMsg').offsetHeight - 100,
                            behavior: "smooth",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    function SaveProfileInfo() {
        if (isTokenExpired()) {
            GetRenewedAccessToken(SaveProfile);
        } else {
            SaveProfile(visitorId, accessToken, refreshToken);
        }
    }

    function SaveProfile(visitorId, accessToken, refreshToken) {

        var isValidForm = FormValidation();

        if (isValidForm) {
            if (document.getElementById("dateDOB"))
                document.getElementById("dateDOB").classList.remove("error");
            if (document.getElementById("monthDOB"))
                document.getElementById("monthDOB").classList.remove("error");
            if (document.getElementById("yearDOB"))
                document.getElementById("yearDOB").classList.remove("error");
            if (document.getElementById("errorHolderLabel"))
                document.getElementById("errorHolderLabel").innerHTML = "";
            var form = document.getElementById("profileInformation");
            var serializedForm = new FormData(form);
            serializedForm.append("visitorId", visitorId);
            serializedForm.append("accessToken", accessToken);
            serializedForm.append("refreshToken", refreshToken);

            var url = "/api/sitecore/VisitorApi/VisitorUpdate"

            axios({
                method: "post",
                url: url,
                data: serializedForm,
            })
                .then(function (response) {
                    if (response.data == true) {
                        var profile = document.getElementById("profileInformation");
                        if (profile) {

                            var gender = profile.querySelector("#gender").value;
                            var title = gender == 'm' ? profile.querySelector("#lblmr").textContent :
                                (gender == 'f' ? profile.querySelector("#lblmrs").textContent : profile.querySelector("#lblnotspecified").textContent);
                            var date = profile.querySelector("#dateDOB").value;
                            var month = profile.querySelector("#monthDOB").value;
                            var year = profile.querySelector("#yearDOB").value;
                            var firstName = profile.querySelector("#firstname").value;
                            var lastName = profile.querySelector("#lastname").value;
                            var zipCode = profile.querySelector("#zipcode").value;
                            var birthDateValue = (date !== "" && month !== "" && year !== "") ? date + '/' + month + '/' + year : "";
                            //var phoneNumber = profile.querySelector("#phonenumber").value;
                            dispatch(setFirstName(firstName));
                            dispatch(setLastName(lastName));
                            dispatch(setInitials(firstName[0] + lastName[0]));
                            setBtnProfileSubmit(true);
                            if (date === "" || month === "" || year === "" || date === "0" || month === "0" || year === "0" || date === null || month === null || year === null)
                                dispatch(setBirthday(''));
                            setProfileValues(({ newProfileValues }) => ({
                                ...profileValues,
                                title: title,
                                firstName: firstName,
                                lastName: lastName,
                                zipCode: zipCode,
                                birthDate: birthDateValue
                                //phoneNumber: phoneNumber
                            }));
                        }
                        document.getElementById("profileSuccessMsg").classList.remove("d-none");
                        window.scrollTo({
                            top: document.getElementById('profileSuccessMsg').offsetTop - document.getElementById('profileSuccessMsg').offsetHeight - 100,
                            behavior: "smooth",
                        });
                        profileUpdateSuccessDataPush(fields.preferredCenterName, fields.preferredCenterCode);
                    } else {
                        document.getElementById("profileErrorMsg").classList.remove("d-none");
                        window.scrollTo({
                            top: document.getElementById('profileErrorMsg').offsetTop - document.getElementById('profileErrorMsg').offsetHeight - 100,
                            behavior: "smooth",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

            return true;
        } else {
            return false;
        }
    }

    function removeVehicleData(numberplate) {
        setVehicleData(vehicledata.filter(c => c.licensePlate !== numberplate));


    }

    function addVehicleData(licensePlate, name, editvehicle) {
        if (editvehicle) {
            const newVehicleData = vehicledata.map((item, index) => {
                if (item.licensePlate === licensePlate) {
                    const updatedItem =
                    {
                        ...item,
                        licensePlate: licensePlate,
                        name: name
                    }
                    return updatedItem;
                }
                return item;
            });
            setVehicleData(newVehicleData);
        }
        else {
            setVehicleData([
                ...vehicledata,
                { licensePlate: licensePlate, name: name }
            ]);
        }
    }

    function fnInterestSelection(interestId) {
        var ele = document.getElementById(interestId);
        if (ele) {
            if (ele.classList.contains("interest-selected")) {
                document.getElementById(interestId).classList.remove("interest-selected")
                document.getElementById(interestId).classList.remove("category-selected")
            }
            else {
                document.getElementById(interestId).classList.add("interest-selected")
                document.getElementById(interestId).classList.add("category-selected")
            }
        }
        //setBtnInterestSubmit(false);
    }

    function fnUpdateInterestsData() {
        if (document.querySelectorAll(".category-selected")) {
            document.querySelectorAll(".category-selected").forEach(function (x) {
                var categoryId = x.getAttribute("data-interestId");
                var categoryName = x.getAttribute("data-interestName");

                if (categoryId !== null && categoryId !== "") {
                    arrInterests.push({ externalId: categoryId, label: categoryName });
                }
            });
            setInterestsData(arrInterests);
            putVistorInterests(arrInterests);
        }
    }

    function fnUpdateProfessionalInfo() {
        if (isTokenExpired()) {
            GetRenewedAccessToken(UpdateProfessionalInfo);
        } else {
            UpdateProfessionalInfo(visitorId, accessToken, refreshToken);
        }
    }

    function UpdateProfessionalInfo(visitorId, accessToken, refreshToken) {
        professionalInfo = [];
        arrProfessional = [];
        var formValid = true;
        var validationFlag = true;

        document.querySelectorAll(".centerselctioncheckbox label").forEach((x) => {

            var pcCode = x.getAttribute("data-pccode").valueOf();
            var centerName = x.getAttribute("data-centername").valueOf();

            if (x.classList.contains("active")) {
                var nextElem = x.nextElementSibling;
                if (nextElem) {
                    var txt = nextElem.querySelector('input[type="text"]');
                    if (txt) {
                        validationFlag = CompanyNameValidation(txt.id, validationFlag);
                        if (validationFlag) {
                            var companyName = txt.value;
                            var companyId = txt.getAttribute("data-company").valueOf();

                            if (companyId != null && companyId != "" && pcCode != null && pcCode != "") {
                                professionalInfo.push({ CenterCompanyId: companyId, PcCode: pcCode });
                                arrProfessional.push({ companyName: companyName, centerName: centerName, pcCode: pcCode });
                            }
                        } else {
                            formValid = false;
                        }
                    }
                }
            } else {
                professionalInfo.push({ CenterCompanyId: null, PcCode: pcCode });
            }
        });

        if (formValid && professionalInfo.length != 0) {
            axios({
                method: "post",
                url: "/api/sitecore/visitorapi/VisitorCenterCompanyUpdate",
                async: false,
                data: {
                    professionalInfo: professionalInfo,
                    visitorId: visitorId,
                    accessToken: accessToken,
                    refreshToken: refreshToken
                }
            })
                .then(function (response) {
                    if (response.data != null && response.data != "" && response.data == "Success") {
                        setProfessionalValues(arrProfessional);
                        var updatedWorker = arrProfessional.map(x => x.pcCode);
                        dispatch(setWorkerCenters(updatedWorker.toString()));
                        setBtnCompanySubmit(true);
                        document.getElementById("professionalSuccessMsg").classList.remove("d-none");
                        window.scrollTo({
                            top: document.getElementById('professionalSuccessMsg').offsetTop - document.getElementById('professionalSuccessMsg').offsetHeight - 100,
                            behavior: "smooth",
                        });
                        profileUpdateSuccessDataPush(fields.preferredCenterName, fields.preferredCenterCode);
                    } else {
                        document.getElementById("professionalErrorMsg").classList.remove("d-none");
                        window.scrollTo({
                            top: document.getElementById('professionalErrorMsg').offsetTop - document.getElementById('professionalErrorMsg').offsetHeight - 100,
                            behavior: "smooth",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            scrollToError(formValid);
        }
    }

    function fnCenterCheckBoxClick(e) {

        var $currentLabel = e.currentTarget;
        var nextElem = $currentLabel.nextElementSibling;

        if ($currentLabel.classList.contains("active")) {
            $currentLabel.classList.remove("active");
            if (nextElem) {
                nextElem.classList.add("d-none");
            }
        } else {
            $currentLabel.classList.add("active");
            if (nextElem) {
                nextElem.classList.remove("d-none");
            }
        }
    }

    function ProfileSectionEditClick() {
        document.getElementById("personalInfoContainer").classList.add("d-none");
        if (isMobile.any()) {
            document.getElementById("personalInfoHeader").classList.add("d-none");
        }

        document.getElementById("profileInfoContainer").classList.remove("d-none");
        if (isMobile.any()) {
            document.getElementById("profileInfoHeader").classList.remove("d-none");
        }

        // Disable the submit button
        setBtnProfileSubmit(true);

        let profile = { ...profileValues };
        let birthDate = profile.birthDate.split('/');

        if (profile.title === document.getElementById("lblmr").textContent) {
            document.getElementById("lblmr").classList.add("active");
            document.getElementById("lblmrs").classList.remove("active");
            document.getElementById("lblnotspecified").classList.remove("active");
        }
        else if (profile.title === document.getElementById("lblmrs").textContent) {
            document.getElementById("lblmrs").classList.add("active");
            document.getElementById("lblmr").classList.remove("active");
            document.getElementById("lblnotspecified").classList.remove("active");
        }
        else if (profile.title === document.getElementById("lblnotspecified").textContent) {
            document.getElementById("lblnotspecified").classList.add("active");
            document.getElementById("lblmr").classList.remove("active");
            document.getElementById("lblmrs").classList.remove("active");
        }

        document.getElementById("firstname").value = profile.firstName;
        document.getElementById("lastname").value = profile.lastName;
        document.getElementById("dateDOB").value = birthDate[0];
        document.getElementById("monthDOB").value = birthDate[1];
        document.getElementById("yearDOB").value = birthDate[2];
        document.getElementById("zipcode").value = profile.zipCode;

        // Removes Errors
        document.getElementById("TitleCheck").innerHTML = "";

        document.getElementById("firstname-error").innerHTML = "";
        document.getElementById("firstname").classList.remove("error");

        document.getElementById("lastname-error").innerHTML = "";
        document.getElementById("lastname").classList.remove("error");

        document.getElementById("errorHolderLabel").innerHTML = "";
        document.getElementById("dateDOB").classList.remove("error");
        document.getElementById("monthDOB").classList.remove("error");
        document.getElementById("yearDOB").classList.remove("error");

        document.getElementById("zipcode-error").innerHTML = "";
        document.getElementById("zipcode").classList.remove("error");

        let mobileHeaderHeight = 0;
        if (isMobile.any()) {
            mobileHeaderHeight = document.getElementById("profileInfoHeader").offsetHeight;
        }

        window.scrollTo({
            top: document.getElementById('profileInfoContainer').offsetTop - 85 - mobileHeaderHeight,
            behavior: "smooth",
        });

    }

    function ProfessionalSectionEditClick() {

        document.getElementById("personalInfoContainer").classList.add("d-none");
        if (isMobile.any()) {
            document.getElementById("personalInfoHeader").classList.add("d-none");
        }
        document.getElementById("professionalInfoContainer").classList.remove("d-none");
        if (isMobile.any()) {
            document.getElementById("professionalInfoHeader").classList.remove("d-none");
        }

        // Disable the submit button
        setBtnCompanySubmit(true);

        let companyArr = [...professionalValues];

        document.querySelectorAll(".centerselctioncheckbox label").forEach((x) => {
            var pcCode = x.getAttribute("data-pccode").valueOf();
            var nextElem = x.nextElementSibling;

            var item = companyArr.filter(x => x.pcCode == pcCode);

            if (item.length > 0) {
                if (!x.classList.contains("active")) {
                    x.classList.add("active");
                }

                if (nextElem) {
                    if (nextElem.classList.contains("d-none")) {
                        nextElem.classList.remove("d-none");
                    }

                    var input = nextElem.querySelector('input[type="text"]');
                    if (input) {
                        input.classList.remove("error");
                        if (input.value != item[0].companyName) {
                            input.value = item[0].companyName;
                        }
                    }

                    var errorSpan = nextElem.querySelector('span.error');
                    if (errorSpan) {
                        errorSpan.innerHTML = "";
                    }
                }
            } else {
                if (x.classList.contains("active")) {
                    x.classList.remove("active");
                }

                if (nextElem) {
                    if (!nextElem.classList.contains("d-none")) {
                        nextElem.classList.add("d-none");
                    }

                    var input = nextElem.querySelector('input[type="text"]');
                    if (input) {
                        input.classList.remove("error");
                        if (input.value != "") {
                            input.value = "";
                        }
                    }

                    var errorSpan = nextElem.querySelector('span.error');
                    if (errorSpan) {
                        errorSpan.innerHTML = "";
                    }
                }
            }
        });

        let mobileHeaderHeight = 0;
        if (isMobile.any()) {
            mobileHeaderHeight = document.getElementById("professionalInfoHeader").offsetHeight;
        }

        window.scrollTo({
            top: document.getElementById('professionalInfoContainer').offsetTop - 85 - mobileHeaderHeight,
            behavior: "smooth",
        });
    }

    function InterestSectionEditClick() {
        document.getElementById("personalInfoContainer").classList.add("d-none");
        if (isMobile.any()) {
            document.getElementById("personalInfoHeader").classList.add("d-none");
        }

        document.getElementById("interestSelector").classList.remove("d-none");
        if (isMobile.any()) {
            document.getElementById("interestInfoHeader").classList.remove("d-none");
        }

        let mobileHeaderHeight = 0;
        if (isMobile.any()) {
            mobileHeaderHeight = document.getElementById("interestInfoHeader").offsetHeight;
        }
        window.scrollTo({
            top: document.getElementById('interestSelector').offsetTop - 85 - mobileHeaderHeight,
            behavior: "smooth",
        });
        setBtnInterestSubmit(true);
    }

    function fnUpdateInterestsButton() {
        if ((document.querySelectorAll(".category-selected")?.length).toString() === (interestsdata.length).toString()) {
            setBtnInterestSubmit(true);
            document.querySelectorAll(".category-selected").forEach(function (x) {
                var categoryId = x.getAttribute("data-interestId");
                if (categoryId !== null && categoryId !== "") {
                    if ((interestsdata.filter(x => x.externalId.toString() === categoryId.toString())).length < 1) {
                        setBtnInterestSubmit(false);
                    }
                }
            });
        }
        else {
            setBtnInterestSubmit(false);
        }
        // if (!document.getElementById("interestsSuccessMsg").classList.contains('d-none')) {
        //     document.getElementById("interestsSuccessMsg").classList.add("d-none");
        // }
        // if (!document.getElementById("interestsErrorMsg").classList.contains('d-none')) {
        //     document.getElementById("interestsErrorMsg").classList.add("d-none");
        // }
    }

    if (fields != undefined && fields != null && fields != "") {
        if (fields.showSuccessPopin !== null && fields.showSuccessPopin !== "" &&
            fields.showSuccessPopin === "manage-vehicles" && !manageVehicle) {
            setManageVehicle(true);
        }
        return (
            <>
                <div className="basic-benefits-container d-md-none d-lg-none d-xl-none bg-white" id="personalInfoHeader">
                    <div className="container modalContainer p-0 ">
                        <nav className="benefits-header-height px-lg-1 row m-0   py-2">
                            <div className="col-2 text-center px-0">
                                <a className="picto icon-chevron-bottom  d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                                    href="javascript:void(0);" data-testid="GoBackToMenu" onClick={() => { GoBackToMenu("my-personal-information") }} ></a>
                            </div>
                            <div className="col-8 p-0 text-center">
                                <div className=" text-center benefits-subtitle ">
                                    {fields.myPersonalInformationTitle}
                                </div>
                            </div>
                            <div className="col-2 text-center px-0">
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="mobile-container" id="personalInfoContainer">
                    <div className="personal-info">
                        <div className="row text-black pb-4 d-flex align-items-center d-none d-lg-block">
                            <div className="col-3"></div>
                            <div className="d-flex justify-content-start account-personal-informations">
                                {fields.myPersonalInformationTitle}
                            </div>
                            <div className="account-go-back col-3"></div>
                        </div>
                        <div className="col-md-10 w-100 p-0">
                            <div className="container w-100 mb-2 personal-profile-container px-3">
                                <div className="d-flex">
                                    <div className="account-profile-title-header  flex-grow-1 pb-4">
                                        {fields.profile_Label}
                                    </div>
                                    <a href="javascript:void(0);" data-testid="ProfileSectionEditClick" onClick={() => { ProfileSectionEditClick() }} className="account-edit">
                                        {fields.edit_CTA}
                                    </a>
                                </div>
                                <div className="row">
                                    <div className="d-flex personal-information-profile">
                                        <div className="account-profile-edit-text px-0 pb-4">
                                            <div className="account-profile-title">{fields.title_Label}</div>
                                            <div className="col-md-6 account-profile-sub-title px-0 w-100">{profileValues.title}</div>
                                        </div>
                                        <div className="account-profile-edit-text px-0 pb-4">
                                            <div className="account-profile-title">{fields.firstName_Label}</div>
                                            <div className="col-md-6 account-profile-sub-title px-0 w-100">{profileValues.firstName}</div>
                                        </div>
                                        <div className="account-profile-edit-text px-0 pb-4">
                                            <div className="account-profile-title">{fields.lastName_Label}</div>
                                            <div className="col-md-6 account-profile-sub-title px-0 w-100">{profileValues.lastName}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex personal-information-profile">
                                        <div className="account-profile-edit-text px-0 pb-4">
                                            <div className="account-profile-title">{fields.birthDate_Label}</div>
                                            <div className="col-md-6 account-profile-sub-title px-0 w-100">{profileValues.birthDate}</div>
                                        </div>
                                        <div className="account-profile-edit-text px-0 pb-4">
                                            <div className="account-profile-title">{fields.zipCode_Label}</div>
                                            <div className="col-md-6 account-profile-sub-title px-0 w-100">{profileValues.zipCode}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="p-0">
                                <div className="personal-container w-100 mb-2">
                                    <div className="mx-0 col-md-6 col-sm-12 personal-email-container">
                                        <div className="d-flex col-12 px-0">
                                            <div className="flex-grow-1 px-0">
                                                <div className="account-profile-title-header  pb-4 pe-1">
                                                    {fields.email_Label}
                                                </div>
                                            </div>
                                            <a href="javascript:void(0);" className="account-edit" data-testid="fnRedirectToConnectLogin1" onClick={() => fnRedirectToConnectLogin(fields.changeEmailRedirectUrl)}>
                                                {fields.edit_CTA}
                                            </a>
                                        </div>
                                        <div className="col-12 px-0 ">
                                            <div className="account-profile-sub-title px-0 pb-2">
                                                {fields.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mx-0 col-md-6 col-sm-12  personal-email-container">
                                        <div className="d-flex col-12 px-0">
                                            <div className="col-md-6 flex-grow-1 px-0">
                                                <div className="account-profile-title-header  pb-4 pe-1">
                                                    {fields.password_Label}
                                                </div>
                                            </div>
                                            <a href="javascript:void(0);" className="account-edit" data-testid="fnRedirectToConnectLogin2" onClick={() => fnRedirectToConnectLogin(fields.changepwdRedirectUrl)}>
                                                {fields.edit_CTA}
                                            </a>
                                        </div>
                                        <div className=" col-12 px-0 ">
                                            <div className="col-md-6 account-profile-sub-title px-0 pb-2">
                                                ************
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {fields.professionalInfo != null && fields.professionalInfo.length > 0 &&
                                <div className="container w-100 mb-2 personal-professional-container px-3">
                                    <div className="row mx-0">
                                        <div className="d-flex col-12 px-0 ">
                                            <div className="col-md-6 flex-grow-1 px-0">
                                                <div className="account-profile-title-header  pb-4 pe-1">
                                                    {fields.workingCompany_Label}
                                                </div>
                                            </div>
                                            <a className="account-edit" href="javascript:void(0);" data-testid="ProfessionalSectionEditClick" onClick={() => { ProfessionalSectionEditClick() }}>
                                                {fields.edit_CTA}
                                            </a>
                                        </div>
                                        <div className="col-12 px-0">
                                            <div>
                                                <div className="account-profile-professional px-0 pb-3">
                                                    {fields.professionalBenefits_Label}
                                                </div>
                                                {(professionalValues.length == 0) ? (
                                                    <div className="account-profile-professional px-0 pb-3">
                                                        {fields.companyNotRegistered_Label}
                                                    </div>) : (
                                                    <div className="account-profile-professional px-0 pb-3">
                                                        {fields.nearCenter_Label}
                                                    </div>
                                                )}
                                            </div>
                                            {professionalValues.map((item, index) => (
                                                <div key={index} className="account-profile-professional-border">
                                                    <div className="account-profile-professional-center px-0 mb-1">{item.centerName}</div>
                                                    <div className="account-profile-professional px-0 mb-1">{fields.inCompany_Label}</div>
                                                    <div className="account-profile-professional-center px-0 mb-3">{item.companyName}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                            {fields.showVisitorInterests &&
                                <>
                                    <div className="container w-100  vehicle-interest-container vehicle-border px-3">
                                        <div className="row mx-0">
                                            <div className="d-flex col-12 px-0 ">
                                                <div className="col-md-6 flex-grow-1 px-0">
                                                    <div
                                                        className="account-profile-title-header  pb-4 pe-1">
                                                        {fields.interestsTitle} </div>
                                                </div>
                                                <a href="javascript:void(0);" className="account-edit text-decoration-underline" data-testid="InterestSectionEditClick" onClick={() => { InterestSectionEditClick() }}>
                                                    {fields.edit_CTA}
                                                </a>
                                            </div>
                                            {(interestsdata !== null && interestsdata.length === 0) &&
                                                <div className=" col-12 px-0 ">
                                                    <div>
                                                        <div className="vehicle-information-content px-0 pb-3">{fields.interestsDescription}</div>
                                                    </div>

                                                </div>
                                            }
                                        </div>

                                        {(interestsdata !== null && interestsdata.length > 0) &&
                                            <>
                                                <div className="row mx-0">
                                                    <div className="d-flex col-12 px-0 ">
                                                        <div className="d-flex flex-start flex-wrap">
                                                            {interestsdata.map((item, index) => (
                                                                <span key={index} type="button" className="interest-selected vehicle-interest-button cursor-default rounded-pill">{item.label}</span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                            }
                            {fields.showLicensePlate && fields.vehicleInfo != undefined && fields.vehicleInfo != null && fields.vehicleInfo != "" &&
                                <div className="container w-100 vehicle-border vehicle-information-container px-3">
                                    <div className="row mx-0">
                                        <div className="d-flex col-12 px-0 ">
                                            <div className="col-md-6 flex-grow-1 px-0">
                                                <div
                                                    className="account-profile-title-header  pb-4 pe-1">
                                                    {fields.vehicleInfo.vehicleInformationTitle}</div>
                                            </div>
                                            <a className="account-edit text-decoration-underline" href="javascript:void(0);" data-testid="VehicleSectionEditClick" onClick={() => { VehicleSectionEditClick() }}>
                                                {fields.edit_CTA}
                                            </a>
                                        </div>
                                        <div className=" col-12 px-0 ">
                                            {vehicledata.length === 0 &&
                                                <div>
                                                    <div className="vehicle-information-content px-0 pb-3">{fields.vehicleInfo.vehicleNotRegisteredText}</div>
                                                </div>}

                                            {vehicledata.map((item, index) => (
                                                <>
                                                    <div className=" vehicle-information-content-border">
                                                        <div className="vehicle-information-content px-0 mb-1">{item.name ? item.name : (fields.vehicleInfo.vehicleText + " " + (index + 1))}
                                                        </div>
                                                        <div className="vehicle-information-center px-0 mb-3">
                                                            {item.licensePlate}</div>
                                                    </div>
                                                </>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            }
                            {fields.showCommunityBlock && <>
                                <div className="container w-100  my-community-container px-3">
                                    <div className="row mx-0">
                                        <div className="d-flex col-12 px-0 ">
                                            <div className="col-md-6 flex-grow-1 px-0">
                                                <div className="account-profile-title-header pb-4 pe-1">
                                                    {fields.myCommunity} </div>
                                            </div>
                                            <a href={fields.changeCommunity.value.url} className="account-edit text-decoration-underline">{fields.changeCommunity.value.title}</a>
                                        </div>
                                        {(fields.communityTitle !== null && fields.communityTitle !== undefined && fields.communityTitle !== ""
                                            && fields.communityImage !== null && fields.communityImage !== undefined && fields.communityImage !== "") ?
                                            <div className=" col-12 px-0 ">
                                                <div className=" vehicle-information-content-border pt-3 d-flex">
                                                    <img className="my-community-image d-flex align-items-start flex-wrap float-start" loading="lazy"
                                                        src={fields.communityImage} alt="" />
                                                    <span className="vehicle-information-content ps-2 d-flex align-items-center"><RichText field={fields.communityTitle} />
                                                    </span>
                                                </div>
                                            </div> :
                                            <div className=" col-12 px-0 ">
                                                <div className="vehicle-information-content-border vehicle-information-content pt-3">
                                                    {fields.noCommunityDesc}
                                                </div>
                                            </div>}
                                    </div>
                                </div>



                            </>
                            }
                            <a href="javascript:void(0);" className="d-flex account-profile-delete text-underline" data-testid="DeleteVisitorConfirmation" onClick={(e) => { return DeleteVisitorConfirmation() }} >
                                {fields.deleteMyAccount_Label}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="basic-benefits-container d-none d-md-none d-lg-none d-xl-none bg-white" id="profileInfoHeader">
                    <div className="container modalContainer p-0 ">
                        <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                            <div className="col-2 text-center px-0">
                                <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                                    href="javascript:void(0);" data-testid="GoBackFromProfileInfo1" onClick={() => { GoBackFromProfileInfo() }}></a>
                            </div>
                            <div className="col-8 p-0 text-center">
                                <div className=" text-center benefits-subtitle ">
                                    {fields.profile_Label}
                                </div>
                            </div>
                            <div className="col-2 text-center px-0">
                            </div>
                        </nav>
                    </div>
                </div>

                <div className="mobile-container d-none" id="profileInfoContainer">
                    <div className="profile-info">
                        <div className="p-0 d-none d-lg-block">
                            <nav className="align-items-center row m-0 pb-4 py-2">
                                <div className="col-2 text-center px-0 d-flex align-items-center">
                                    <a className="account-profile-go-back d-flex align-items-center text-decoration-none" href="javascript:void(0);"
                                        data-testid="GoBackFromProfileInfo2" onClick={() => { GoBackFromProfileInfo() }} >
                                        <span className="picto icon-left-arrow text-black text-decoration-none account-icon-spacing me-2"></span>
                                        <span className="account-go-back text-black text-decoration-none">{fields.goBack_CTA}</span>
                                    </a>
                                </div>
                                <div className="col-8 p-0 text-center">
                                    <div className="account-title-informations  align-items-center justify-content-center">
                                        {fields.profile_Label}
                                    </div>
                                </div>
                                <div className="col-2 text-center px-0">
                                </div>
                            </nav>
                        </div>

                        <div className="profile-success d-flex align-items-center d-none mb-3" id="profileSuccessMsg">
                            <div className="d-flex align-items-center">
                                <span className="icon-checkmark success-icon fs-2 me-2"></span>
                                {fields.successMessage}
                            </div>
                            <div>
                                <a href="javascript:void(0);" data-testid="CloseMessage1" onClick={() => { CloseMessage('profileSuccessMsg') }}
                                    className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none"></a>
                            </div>
                        </div>

                        <div className="profile-warning d-flex align-items-center mb-3 d-none" id="profileErrorMsg">
                            <div className="d-flex align-items-center">
                                <span className="icon-alert-info warning-icon fs-3 me-2"></span>
                                {fields.errorMessage}
                            </div>
                            <div>
                                <a href="javascript:void(0);" data-testid="CloseMessage2" onClick={() => { CloseMessage('profileErrorMsg') }}
                                    className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none"></a>
                            </div>
                        </div>

                        <div className="col-md-12 d-flex max-width-container mx-auto col-12 global-form profile-information-form justify-content-center">
                            <form method="post" id="profileInformation" name="profileInformation" autoComplete="off">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                        <div className="profile-label">{fields.title_Label + " *"}</div>
                                        <div className="form-group d-flex form-check-inline input-radio mt-1">
                                            <label htmlFor="mrs" className={"btn profile-title-name d-flex justify-content-center align-items-center me-2 " + fields.mrs} id="lblmrs">
                                                <input type="radio" name="mrs" id="mrs" value="f" data-testid="setBtnProfileSubmit1" onClick={(e) => { TitleMrsClick(); setBtnProfileSubmit(false); }} />
                                                {fields.title_Mrs}
                                            </label>
                                            <label htmlFor="mr" className={"btn profile-title-name d-flex justify-content-center align-items-center  me-2 " + fields.mr} id="lblmr">
                                                <input type="radio" name="mr" id="mr" value="m" data-testid="setBtnProfileSubmit2" onClick={(e) => { TitleMrClick(); setBtnProfileSubmit(false); }} />
                                                {fields.title_Mr}
                                            </label>
                                            <label htmlFor="notspecified" className={"btn profile-title-name " + fields.notSpecified} id="lblnotspecified">
                                                <input type="radio" name="notspecified" id="notspecified" value="u" data-testid="setBtnProfileSubmit3" onClick={(e) => { TitleNotSpecifiedClick(); setBtnProfileSubmit(false); }} />
                                                {fields.title_Undefined}
                                            </label>
                                        </div>
                                        <input data-cs-mask className="idz_hide" type="hidden" id="gender" name="gender" value={fields.gender} />
                                        <span id="TitleCheck" data-validatormsg={fields.title_Validation_Error} className="error"></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                        <div className="form-group">
                                            <label htmlFor="firstname" className="profile-label">{fields.firstName_Label + " *"}</label>
                                            <input className="form-control  profile-label-text profile-label-name mt-1" type="text"
                                                name="firstname" id="firstname" required defaultValue={fields.firstName}
                                                data-testid="setBtnProfileSubmit4"
                                                onBlur={(e) => { if (typeof FirstNameValidation !== "undefined") { FirstNameValidation(); } }}
                                                onFocus={(e) => { setBtnProfileSubmit(false) }} />

                                            <span id="firstname-error" className="error" data-validatormsg1={fields.name_Validation_Error1}
                                                data-validatormsg2={fields.name_Validation_Error2}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                        <div className="form-group ">
                                            <label htmlFor="lastname" className="profile-label">{fields.lastName_Label + " *"}</label>
                                            <input className="form-control  profile-label-text profile-label-name mt-1" type="text"
                                                name="lastname" id="lastname" required defaultValue={fields.lastName}
                                                data-testid="setBtnProfileSubmit5"
                                                onBlur={(e) => { if (typeof LastNameValidation !== "undefined") { LastNameValidation(); } }}
                                                onFocus={(e) => { setBtnProfileSubmit(false) }} />

                                            <span id="lastname-error" className="error" data-validatormsg1={fields.name_Validation_Error1}
                                                data-validatormsg2={fields.name_Validation_Error2}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                        <div className="form-group birthdate-size">
                                            <label className="profile-label" htmlFor="birthdate">{fields.birthDate_Label}</label>
                                            <div className="flexcenter mt-1">
                                                <div className="birthdate-block">
                                                    <input type="number" data-cs-mask="true"
                                                        className="form-control bdate dd idz_hide rounded-1 profile-input-size"
                                                        id="dateDOB" pattern="\d*" tabIndex="6" name="dd"
                                                        min="1" max="31" maxLength="2"
                                                        defaultValue={fields.dd}
                                                        onFocus={(e) => { setBtnProfileSubmit(false) }}
                                                        data-testid="setBtnProfileSubmit7" />
                                                    <input type="number" data-cs-mask="true"
                                                        className="form-control bdate mm idz_hide rounded-1 profile-input-size"
                                                        id="monthDOB" pattern="\d*" tabIndex="7" name="mm"
                                                        min="1" max="12" maxLength="2"
                                                        defaultValue={fields.mm}
                                                        onFocus={(e) => { setBtnProfileSubmit(false) }}
                                                        data-testid="setBtnProfileSubmit8" />
                                                    <input type="number" data-cs-mask="true"
                                                        className="form-control bdate yyyy idz_hide rounded-1 profile-input-size"
                                                        id="yearDOB" pattern="\d*" tabIndex="8" name="yy"
                                                        min="1900" max={fields.currentYear}
                                                        defaultValue={fields.yyyy}
                                                        onFocus={(e) => { setBtnProfileSubmit(false) }}
                                                        data-testid="setBtnProfileSubmit9" />
                                                </div>
                                            </div>
                                            <div className="profile-subtitle mt-1">{fields.birthDate_Description}</div>
                                            <div id="errorHolder">
                                                <label id="errorHolderLabel" className="error"
                                                    data-validatormsg1={fields.birthDate_Validation_Error1}
                                                    data-validatormsg2={fields.birthDate_Validation_Error2}
                                                    data-validatormsg3={fields.birthDate_Validation_Error3}>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                        <div className="form-group ">
                                            <label htmlFor="zipcode" className="profile-label">{fields.zipCode_Label}</label>
                                            <input className="form-control profile-label-text mt-1" type="text"
                                                name="zipcode" id="zipcode" defaultValue={fields.zipCode}
                                                data-testid="setBtnProfileSubmit6"
                                                onFocus={(e) => { setBtnProfileSubmit(false) }}
                                                onBlur={(e) => { if (typeof ZipCodeValidation !== "undefined") { ZipCodeValidation(); } }}
                                            />

                                            <span id="zipcode-error" className="error" data-validatormsg1={fields.zipCode_Validation_Error1}
                                                data-validatormsg2={fields.zipCode_Validation_Error2}></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                        <div className="profile-required text-start mb-4 pe-2">{"*" + fields.mandatoryFields}</div>
                                        <div className="button-profile-container pb-lg-4">
                                            <button className="profile-cancel  me-4 bg-white" type="button" data-testid="GoBackFromProfileInfo3" onClick={() => { GoBackFromProfileInfo() }}>
                                                {fields.cancel_CTA}</button>
                                            <button className="profile-submit" type="button" disabled={btnProfileSubmit} id="profileUpdate"
                                                data-testid="SaveProfileInfo" onClick={(e) => { if (typeof SaveProfileInfo != "undefined") { return SaveProfileInfo(); } }}>
                                                {fields.submit_CTA}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {fields.professionalInfo != null && fields.professionalInfo.length > 0 &&
                    <>
                        <div className="basic-benefits-container d-none d-md-none d-lg-none d-xl-none bg-white" id="professionalInfoHeader">
                            <div className="container modalContainer p-0 ">
                                <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                                    <div className="col-2 text-center px-0">
                                        <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black fs-3"
                                            href="javascript:void(0);" data-testid="GoBackFromProfessionalInfo1" onClick={() => { GoBackFromProfessionalInfo() }}></a>
                                    </div>
                                    <div className="col-8 p-0 text-center">
                                        <div className=" text-center benefits-subtitle ">
                                            {fields.workingCompany_Label}
                                        </div>
                                    </div>
                                    <div className="col-2 text-center px-0">
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="mobile-container d-none pb-3" id="professionalInfoContainer">
                            <div className="profile-professional">
                                <div className="p-0 d-none d-lg-block">
                                    <nav className="align-items-center row m-0 pb-4  py-2">
                                        <div className="col-2 text-center px-0 d-flex align-items-center">
                                            <a className="account-profile-go-back d-flex align-items-center text-decoration-none" href="javascript:void(0);"
                                                data-testid="GoBackFromProfessionalInfo2" onClick={() => { GoBackFromProfessionalInfo() }}>
                                                <span className="picto icon-left-arrow text-black text-decoration-none account-icon-spacing me-2"></span>
                                                <span className="account-go-back text-black text-decoration-none">{fields.goBack_CTA}</span>
                                            </a>
                                        </div>
                                        <div className="col-8 p-0 text-center">
                                            <div className="account-title-informations  align-items-center justify-content-center">
                                                {fields.workingCompany_Label}
                                            </div>
                                        </div>
                                        <div className="col-2 text-center px-0">
                                        </div>
                                    </nav>
                                </div>

                                <div className="profile-success d-flex align-items-center d-none mb-3" id="professionalSuccessMsg">
                                    <div className="d-flex align-items-center">
                                        <span className="icon-checkmark success-icon fs-2 me-2"></span>
                                        {fields.successMessage}
                                    </div>
                                    <div>
                                        <a href="javascript:void(0);" data-testid="CloseMessage3" onClick={() => { CloseMessage('professionalSuccessMsg') }}
                                            className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none"></a>
                                    </div>
                                </div>
                                <div className="profile-warning d-flex align-items-center d-none mb-3" id="professionalErrorMsg">
                                    <div className="d-flex align-items-center">
                                        <span className="icon-alert-info warning-icon fs-3 me-2"></span>
                                        {fields.errorMessage}
                                    </div>
                                    <div>
                                        <a href="javascript:void(0);" data-testid="CloseMessage4" onClick={() => { CloseMessage('professionalErrorMsg') }}
                                            className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none"></a>
                                    </div>
                                </div>

                                <div className="col-md-12 d-flex max-width-container mx-auto col-12 global-form profile-information-form justify-content-center">
                                    <form id="professionalInformation" name="professionalInformation" data-testid="preventDefault" onSubmit={e => { e.preventDefault(); }} autoComplete="off">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12 p-0">
                                                <div className="form-group ">
                                                    <label className="profile-label-content">{fields.professionalBenefits_Label}</label>
                                                </div>
                                            </div>
                                        </div>
                                        {fields.professionalInfo.map((item, index) => {
                                            if (item.centerCompanies == null || item.centerCompanies.length == 0) {
                                                return <></>
                                            }

                                            var companies = item.centerCompanies.map(({ companyName, centerCompanyId }) => ({ companyName, centerCompanyId }));
                                            var strCompanies = JSON.stringify(companies);

                                            return (
                                                <div key={index} className="row">
                                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                                        <div className="profile-label-content-title my-2">{item.centerName}</div>
                                                        <div className="form-check px-0 centerselctioncheckbox">
                                                            <input className="form-check-input me-1" type="checkbox" id={"checkbox" + index} name={"checkbox" + index} />
                                                            <label className={"form-check-label mb-3" + (item.centerCompanyId != null ? " active" : "")}
                                                                htmlFor={"checkbox" + index} data-pccode={item.pcCode} data-centername={item.centerName}
                                                                data-testid="setBtnCompanySubmit" onClick={(e) => { fnCenterCheckBoxClick(e); setBtnCompanySubmit(false); }}>
                                                                <span className="picto icon-checkmark me-2"></span>
                                                                {fields.workingNearCenter_Label}
                                                            </label>
                                                            <div className={"professional-info-form-select position-relative centercompanies" + (item.centerCompanyId != null ? "" : " d-none")}>
                                                                <div className="profile-label mb-2">{fields.nameOfCompany_Label + " *"}</div>
                                                                <input className="form-control companyname idz_hide" type="text"
                                                                    name={"companyname" + index} id={"companyname" + index}
                                                                    defaultValue={item.centerCompanyName} data-company={item.centerCompanyId != null ? item.centerCompanyId : ""}
                                                                    data-testid="CompanyNameValidation" onBlur={(e) => {
                                                                        CompanyNameValidation(e.currentTarget.id); setBtnCompanySubmit(false);
                                                                    }}
                                                                />
                                                                <ul id={"autocomplete-companyname" + index} className="autocomplete-companyname">
                                                                </ul>
                                                                <input data-cs-mask className="idz_hide" type="hidden" id={"companies" + index} name={"companies" + index}
                                                                    value={strCompanies} />
                                                                <span id={"companyname-error" + index} className="error" data-validatormsg1={fields.company_Validation_Error1}
                                                                    data-validatormsg2={fields.company_Validation_Error2}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12 p-0">
                                                <div className="profile-required text-start mb-4 pe-2">{"*" + fields.mandatoryFields}</div>
                                                <div className="button-profile-container">
                                                    <button className="profile-cancel  me-4 bg-white" type="button" data-testid="GoBackFromProfessionalInfo3" onClick={() => { GoBackFromProfessionalInfo() }}>
                                                        {fields.cancel_CTA}
                                                    </button>
                                                    <button className="profile-submit" type="button" id="companyUpdate" disabled={btnCompanySubmit}
                                                        data-testid="fnUpdateProfessionalInfo" onClick={(e) => { if (typeof fnUpdateProfessionalInfo != "undefined") { return fnUpdateProfessionalInfo(); } }}>
                                                        {fields.submit_CTA}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {fields.vehicleInfo != undefined && fields.vehicleInfo != null && fields.vehicleInfo != "" &&
                    <>
                        <div className="basic-benefits-container d-none d-md-none d-lg-none d-xl-none bg-white" id="vehicleInfoHeader">
                            <div className="container modalContainer p-0 ">
                                <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                                    <div className="col-2 text-center px-0">
                                        <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black fs-3"
                                            href="javascript:void(0);" data-testid="GoBackFromVehicleInfo1" onClick={() => { GoBackFromVehicleInfo() }}></a>
                                    </div>
                                    <div className="col-8 p-0 text-center">
                                        <div className=" text-center benefits-subtitle ">
                                            {fields.vehicleInfo.vehicleInformationTitle}
                                        </div>
                                    </div>
                                    <div className="col-2 text-center px-0">
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div id="vehicleInfoContainer" className="bg-white d-none mobile-container vehicle-informations">
                            <div className="p-0 d-none d-lg-block">
                                <nav className="align-items-center row m-0 pb-4  py-2">
                                    <div className="col-2 text-center px-0 d-flex align-items-center">
                                        <a className="account-profile-go-back d-flex align-items-center text-decoration-none" href="javascript:void(0);"
                                            data-testid="GoBackFromVehicleInfo2" onClick={() => { GoBackFromVehicleInfo() }}>
                                            <span className="picto icon-left-arrow text-black text-decoration-none account-icon-spacing me-2"></span>
                                            <span className="account-go-back text-black text-decoration-none">{fields.goBack_CTA}</span>
                                        </a>
                                    </div>
                                    <div className="col-8 p-0 text-center">
                                        <div className="account-title-informations  align-items-center justify-content-center">
                                            {fields.vehicleInfo.vehicleInformationTitle}
                                        </div>
                                    </div>
                                    <div className="col-2 text-center px-0">
                                    </div>
                                </nav>
                            </div>

                            <div className="vehicle-informations-container">
                                <div className="text-black vehicle-informations-subtitle">{fields.vehicleInfo.vehicleDescriptionText.value}</div>
                                {vehicledata.map((item, index) => (<><div className="rounded-0  vehicle-informations-box active">
                                    <span className="vehicle-informations-item justify-content-between d-flex">
                                        <div className="vehicle-informations-text d-flex">
                                            <div className="vehicle-informations-title">{item.name ? item.name : (fields.vehicleInfo.vehicleText + " " + (index + 1))}
                                            </div>
                                            <div className="vehicle-informations-type">{item.licensePlate}</div>
                                        </div>
                                        <span className="d-flex justify-content-center shopping-advantages">
                                            {/* <a className="d-lg-block d-none d-xl-block shopping-redirect-text">See my advantages and services</a> */}
                                            <a href="javascript:void(0);" className="picto ms-3 no-underline icon-go-right-arrow shopping-icon-right-arrow text-black" data-testid={"addVehicleClick" + index} onClick={(e) => { addVehicleClick(fields, item, false, true, addVehicleData, removeVehicleData, false) }}></a>
                                        </span>

                                    </span>
                                </div></>))}

                                <div className="align-items-center d-flex justify-content-center pb-lg-3">
                                    <a href="javascript:void(0);" className="btn btn-vehicle-information btn-dark align-items-center" data-testid="addVehicleClick3" onClick={(e) => { addVehicleClick(fields, null, true, false, addVehicleData, removeVehicleData, false) }}>{fields.vehicleInfo.addVehicleCTA}</a>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {fields.showVisitorInterests &&
                    <>
                        <div className="basic-benefits-container d-none d-md-none d-lg-none d-xl-none bg-white" id="interestInfoHeader">
                            <div className="container modalContainer p-0 ">
                                <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                                    <div className="col-2 text-center px-0">
                                        <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black fs-3"
                                            href="javascript:void(0);" data-testid="GoBackFromInterestsInfo1" onClick={() => { GoBackFromInterestsInfo() }}></a>
                                    </div>
                                    <div className="col-8 p-0 text-center">
                                        <div className=" text-center benefits-subtitle ">
                                            {fields.interestsTitle}
                                        </div>
                                    </div>
                                    <div className="col-2 text-center px-0">
                                    </div>
                                </nav>
                            </div>
                        </div>
                        {
                            <div className="mobile-container pb-3 d-none" id="interestSelector">
                                <div className="vehicle-interest-content-container">
                                    <div className="p-0 d-none d-lg-block">
                                        <nav className="align-items-center   row m-0 pb-4  py-2">

                                            <div className="col-2 text-center px-0 d-flex align-items-center">
                                                <a href="javascript:void(0);" data-testid="GoBackFromInterestsInfo2" onClick={(e) => { GoBackFromInterestsInfo(interestsdata) }}
                                                    className="account-profile-go-back d-flex align-items-center text-decoration-none">
                                                    <span
                                                        className="picto icon-left-arrow text-black text-decoration-none account-icon-spacing me-2"></span>
                                                    <span className="account-go-back text-black text-decoration-none">{fields.goBack_CTA}</span>
                                                </a>
                                            </div>


                                            <div className="col-8 p-0 text-center">

                                                <div
                                                    className="account-title-informations  align-items-center justify-content-center">
                                                    {fields.interestsTitle}
                                                </div>
                                            </div>

                                            <div className="col-2 text-center px-0">

                                            </div>


                                        </nav>
                                    </div>

                                    {/* <div className="profile-success d-flex align-items-center d-none mb-3" id="interestsSuccessMsg">
                                        <div className="d-flex align-items-center">
                                            <span className="icon-checkmark success-icon fs-2 me-2"></span>
                                            {fields.successMessage}
                                        </div>
                                        <div>
                                            <a href="javascript:void(0);" data-testid="CloseMessage5" onClick={() => { CloseMessage('interestsSuccessMsg') }}
                                                className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none"></a>
                                        </div>
                                    </div>

                                    <div className="profile-warning d-flex align-items-center mb-3 d-none" id="interestsErrorMsg">
                                        <div className="d-flex align-items-center">
                                            <span className="icon-alert-info warning-icon fs-3 me-2"></span>
                                            {fields.errorMessage}
                                        </div>
                                        <div>
                                            <a href="javascript:void(0);" data-testid="CloseMessage6" onClick={() => { CloseMessage('interestsErrorMsg') }}
                                                className="icon-close-search-btn close-icon fs-4 text-black text-decoration-none"></a>
                                        </div>
                                    </div> */}

                                    <div
                                        className="col-md-12 max-width-container mx-auto col-12 global-form profile-information-form">
                                        <form action="javascript:void(0);" method="post" autoComplete="off">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                                    <div className="form-group ">
                                                        <label className="profile-label-content">{fields.interestsDescription}</label>
                                                    </div>
                                                </div>

                                            </div>
                                            {(fields.interestsCategories !== null && fields.interestsCategories.length > 0) &&
                                                <div className="row">
                                                    <div className="d-flex col-12 px-0 ">
                                                        <div className="d-flex flex-start flex-wrap" id="interestList">

                                                            {fields.interestsCategories.map((item, index) => (
                                                                <>

                                                                    {interestsdata !== null && interestsdata.length > 0 && interestsdata.filter((x, index1) => item.categoryId.toString() === x.externalId.toString()).length > 0 ?
                                                                        (<>
                                                                            <span key={index} id={"interest-list-" + index} data-interestId={item.categoryId} data-interestName={item.name} type="button"
                                                                                className="interest-categories vehicle-interest-button rounded-pill interest-selected category-selected"
                                                                                data-testid="fnInterestSelection1" onClick={(e) => { fnInterestSelection("interest-list-" + index); fnUpdateInterestsButton(); }} >{item.name}</span>
                                                                        </>) :
                                                                        (<>
                                                                            <span key={index} id={"interest-list-" + index} data-interestId={item.categoryId} data-interestName={item.name} type="button"
                                                                                className="interest-categories vehicle-interest-button rounded-pill"
                                                                                data-testid="fnInterestSelection2" onClick={(e) => { fnInterestSelection("interest-list-" + index); fnUpdateInterestsButton(); }} >{item.name}</span>
                                                                        </>)
                                                                    }
                                                                </>
                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                                    <div className="button-profile-container mt-4">
                                                        <button
                                                            className="profile-cancel me-4 bg-white" data-testid="GoBackFromInterestsInfo3" onClick={(e) => { GoBackFromInterestsInfo(interestsdata); }}>{fields.cancel_CTA}
                                                        </button>
                                                        <button
                                                            className="profile-submit" data-testid="fnUpdateInterestsData" onClick={(e) => { fnUpdateInterestsData() }} disabled={btnInterestSubmit}>{fields.submit_CTA}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </>
        )
    }
    else {
        return <></>
    }
}



export function VehicleSectionEditClick() {
    if (document.getElementById("personalInfoContainer")) {
        document.getElementById("personalInfoContainer").classList.add("d-none");
        if (isMobile.any()) {
            document.getElementById("personalInfoHeader").classList.add("d-none");
        }
    }

    if (document.getElementById("vehicleInfoContainer")) {
        document.getElementById("vehicleInfoContainer").classList.remove("d-none");
        if (isMobile.any()) {
            document.getElementById("vehicleInfoHeader").classList.remove("d-none");
        }
    }
    let mobileHeaderHeight = 0;
    if (isMobile.any()) {
        mobileHeaderHeight = document.getElementById("vehicleInfoHeader").offsetHeight;
    }

    window.scrollTo({
        top: document.getElementById('vehicleInfoContainer').offsetTop - 85 - mobileHeaderHeight,
        behavior: "smooth",
    });

}