import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { baseModel } from '../../RouteHandler';

function ShopLandingAnchorsNonExpEditorCond(fields) {
  if (fields.shoplandingAnchors.headeritem !== "" && fields.shoplandingAnchors.headeritem !== null) {
    return (
      <>

        {
          (!fields.shoplandingAnchors.customFilter) &&
          <>
            {(baseModel?.isDetailPageRedirect && baseModel.headerName !== null && baseModel.headerName !== "" && baseModel.headerName.title !== null && baseModel.headerName.title !== "") ? (
              <>
                {fields.shoplandingAnchors.headeritem.title?.value?.replace("{BRAND}", baseModel.headerName)}
                {(fields.shoplandingAnchors.shopCount > 0) &&
                  < span className="number">{" (" + fields.shoplandingAnchors.shopCount + ")"}</span>
                }
              </>
            ) : (
              <>
                {fields.shoplandingAnchors.headeritem.title?.value?.replace("{BRAND}", baseModel.headerName)}
                {(fields.shoplandingAnchors.shopCount > 0) &&
                  < span className="number">{" (" + fields.shoplandingAnchors.shopCount + ")"}</span>
                }
              </>)
            }

          </>
        }

      </>
    )
  }
}


function ShopLandingAnchorsNonRebrandedCond(fields) {
  if (fields.shoplandingAnchors.headeritem !== "" && fields.shoplandingAnchors.headeritem !== null) {
    return (
      <>
        {(!fields.shoplandingAnchors.customFilter) &&
          fields.shoplandingAnchors.headeritem.title?.value?.replace("{BRAND}", baseModel.headerName) +
          (fields.shoplandingAnchors.shopCount > 0 ? " (" + fields.shoplandingAnchors.shopCount + ")" : "")
        }

      </>
    )
  }
}

export default function ShopLandingAnchors({ fields }) {
  let hrefAnchor = "";
  const handleClick = (e) => {
    e.preventDefault();
  };
  if (fields !== null && fields !== undefined && fields !== "" && fields.shoplandingAnchors !== undefined && fields.shoplandingAnchors !== null && fields.shoplandingAnchors !== "")
    if (fields.shoplandingAnchors.isNewBrandedsite) {
      return (

        <div className={"anchor-navigation no-logo" + (fields.shoplandingAnchors.isServicesPage ? " taupe-bg" : "") + (fields.shoplandingAnchors.isloyaltyprogrampage ? " anchor-navigation-loyalty-bg" : "")} >
          <div className="container">
            <h1 className={"page-title " + ((fields.shoplandingAnchors.isPersonalInfoPage || fields.shoplandingAnchors.isDestinationPage) ? " " : (fields.shoplandingAnchors.isloyaltyprogrampage ? " " : ""))}>
              {(fields.shoplandingAnchors.displayName !== "" && fields.shoplandingAnchors.displayName !== null) ?
                ((fields.shoplandingAnchors.isExperienceEditor) ?
                  (
                    (fields.shoplandingAnchors.content.Title.value !== "" && fields.shoplandingAnchors.content.Title.value !== null) ?
                      (
                        <RichText field={fields.shoplandingAnchors.content.Title} />
                      ) :
                      (fields.shoplandingAnchors.displayName
                      )

                  ) :
                  <>
                    {(!fields.shoplandingAnchors.customFilter) &&
                      fields.shoplandingAnchors.displayName
                    }
                    {(fields.shoplandingAnchors.shopLandingTitle !== "" && fields.shoplandingAnchors.shopLandingTitle !== null) &&
                      <>
                        {fields.shoplandingAnchors.shopLandingHyphenTitle + fields.shoplandingAnchors.shopLandingTitle}
                        {(fields.shoplandingAnchors.shopCount > 0) &&
                          <span className="number">{" (" + fields.shoplandingAnchors.shopCount + ")"}</span>
                        }
                      </>
                    }
                  </>
                ) :
                (
                  ShopLandingAnchorsNonExpEditorCond(fields)
                )
              }
            </h1>
            {(fields.shoplandingAnchors.isloyaltyprogrampage && fields.shoplandingAnchors.headeritem != null && (fields.shoplandingAnchors.headeritem.description.value !== "" && fields.shoplandingAnchors.headeritem.description.value !== null) && fields.shoplandingAnchors.displayDescription) &&
              <div className="welcome text-center pt-3 pb-3" id="anchorsWelcome" style={{ overflow: "hidden" }}>
                <div className="container"><RichText field={fields.shoplandingAnchors.headeritem.description} /></div>
              </div>
            }
          </div>
        </div>
      );
    } else {
      return (
        <div className="anchor-navigation no-logo">
          <div className="container">
            <h1 className="page-title text-start">
              {(fields.shoplandingAnchors.displayName !== "" && fields.shoplandingAnchors.displayName !== null) ?
                ((fields.shoplandingAnchors.isExperienceEditor) ?
                  (
                    (fields.shoplandingAnchors.content.Title.value !== "" && fields.shoplandingAnchors.content.Title.value !== null) ?
                      (
                        <RichText field={fields.shoplandingAnchors.content.Title} />
                      ) :
                      (fields.shoplandingAnchors.displayName
                      )

                  ) :
                  (
                    <>
                      {(!fields.shoplandingAnchors.customFilter) &&
                        fields.shoplandingAnchors.displayName
                      }
                      {(fields.shoplandingAnchors.shopLandingTitle !== "" && fields.shoplandingAnchors.shopLandingTitle !== null) &&
                        fields.shoplandingAnchors.shopLandingHyphenTitle + fields.shoplandingAnchors.shopLandingTitle
                      }
                    </>
                  )
                ) :
                (
                  ShopLandingAnchorsNonRebrandedCond(fields)
                )
              }
            </h1>
            {(fields.shoplandingAnchors.shopLandingAnchors != null && fields.shoplandingAnchors.shopLandingAnchors.length > 0) &&

              <ul className="nav d-none d-md-block">
                {
                  fields.shoplandingAnchors.shopLandingAnchors.map((child, index) => {
                    hrefAnchor = child.links.value.url;
                    return (
                      <li key={index}><a href={child.links.value.url}
                        aria-label={child?.links?.value}
                        onClick={handleClick} className="text-uppercase scrollTo subanchors d-none">{child.name.value}</a></li>


                    )
                  })}
              </ul>
            }
            {(fields.shoplandingAnchors.headeritem != null && (fields.shoplandingAnchors.headeritem.description.value !== "" && fields.shoplandingAnchors.headeritem.description.value !== null) && fields.shoplandingAnchors.displayDescription) &&
              <div className="welcome text-center" id="anchorsWelcome" style={{ overflow: "hidden" }}>
                <div className="container"><RichText field={fields.shoplandingAnchors.headeritem.description} /></div>
              </div>
            }
          </div>
        </div>

      )
    }
  else {
    return <></>
  }

}



